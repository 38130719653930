<template>
  <body>
    <Menu :cliente="false" titulo="Prospección" :atras="false" />
    <ModalSesion :mostrar="mostrarModalSesion" />
    <ModalError
      :mostrar="mostrarModalError"
      :descripcion="descripcionError"
      :accion="()=>{}"
    />
    <div class="container">
      <div class="row">
        <div class="col-md-5 mx-auto">
          <div id="third showc">
            <div class="myform form" style="margin-top: 1%">
              <form
                action
                name="registration"
                @submit.prevent="enviarLigaProspecto"
              >
                <div class="row fila">
                  <div class="col-md-auto colorf">
                    Información de prospecto
                  </div>
                </div>
                <br />
                <div class="row">
                  <div class="col-md-auto colorf">Datos</div>
                </div>
                <br />
                <div class="row fila" v-if="primerNombre">
                  <div class="col-md-auto reduct titulo">Nombre completo:</div>
                  <div class="col-md-auto responsepros reduct valor">
                    {{ nombreCompleto }}
                  </div>
                </div>
                <div class="row fila">
                  <div class="col-md-auto reduct titulo" v-if="primerNombre">
                    Núm. de Seguridad Social:
                  </div>
                  <div
                    class="col-md-auto responsepros reduct valor"
                    v-if="primerNombre || duro"
                  >
                    {{ nss }}
                  </div>
                </div>
                <div class="row fila">
                  <div class="col-md-auto reduct titulo">CURP:</div>
                  <div class="col-md-auto responsepros reduct valor">
                    {{ curp }}
                  </div>
                </div>

                <div class="row fila">
                  <div class="col-md-auto reduct titulo">Teléfono celular:</div>
                  <div class="col-md-auto responsepros reduct valor">
                    {{ celular }}
                  </div>
                </div>
                <div class="row fila">
                  <div class="col-md-auto reduct titulo">
                    Correo electrónico:
                  </div>
                  <div class="col-md-auto responsepros reduct valor">
                    {{ email }}
                  </div>
                </div>
                <br />
                <div class="form-group content-icon">
                  <div><Tooltip :texto="'Elige solo una opción.'" :enlace="'tooltip-liga'" /></div>
                  <label class="left">Enviar liga por: </label>
                  <img src="@/assets/img/exclamation-circle-solid.svg" id="tooltip-liga" width="35" height="35" alt="tooltip-liga" class="tooltip-input" />
                </div>
                <br/>
                <div class="form-group" id="divInputsSMSEmail">
                  <input
                    class="form-check-input icon-color"
                    type="radio"
                    name="flexRadioDefault"
                    id="inputSMS"
                    value="SMS"
                    v-model="tipoEnvio"
                    @click="changueStatus"
                  />

                  <label class="form-check-label pr-3 reduct" for="inputSMS">
                    &nbsp;SMS
                  </label>

                  <input
                    class="form-check-input pl-3 icon-color marginl"
                    type="radio"
                    name="flexRadioDefault"
                    id="inputEmail"
                    value="EMAIL"
                    v-model="tipoEnvio"
                    @click="changueStatus"
                  />
                  <label class="form-check-label reduct" for="inputEmail">
                    &nbsp;Correo electrónico
                  </label>
                </div>

                <div
                  class="col-md-12 text-center mb-3 pt-5"
                  v-if="envioLiga === true"
                >
                  <button
                    type="submit"
                    :disabled="enabledrenvio"
                    id="buttonEnviarLiga"
                    class="btn btn-block mybtn btn-primary"
                  >
                    Enviar liga
                  </button>
                </div>
                <div
                  class="col-md-12 text-center mb-3 pt-5"
                  v-if="envioLiga === false"
                >
                  <button
                    type="submit"
                    :disabled="enabledrenvio"
                    id="buttonEnviarLiga"
                    @click="reenvioPagina"
                    class="btn btn-block mybtn btn-primary"
                  >
                    Finalizar
                  </button>
                </div>
                <p class="text-center">
                  <a
                    href="#"
                    class="unde"
                    id="reenvioLiga"
                    @click.prevent="add"
                    :class="{ disabled: btnDisabled }"
                    >Reenviar liga</a
                  >
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      content-class="modal-container"
      id="modal-1"
      align="center"
      ok-only
      ok-title="Aceptar"
      no-close-on-backdrop
    >
      <b-container fluid align="center">
        <div class="icon-warning">
          <img src="@/assets/img/Exito.svg" alt="" />
        </div>
        <b-row class="mb-1 btncs">
          <b-col
            ><p class="modal-text-color btncs"></p>
            Se envió la liga con éxito
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </body>
</template>

<script>
import { mapGetters } from "vuex";
import Menu from "../genericos/Menu.vue";
import Cookies from "js-cookie";
import ModalSesion from "../genericos/ModalSesion.vue";
import { guardarProspecto } from "../../services/tcs";
import ModalError from "../genericos/ModalError.vue";
import Tooltip from '../genericos/Tooltip.vue'

export default {
  created() {
    this.curp = this.obtenerProspecto.curp;
    this.nss =
      this.obtenerProspecto.nss === undefined
        ? null
        : this.obtenerProspecto.nss;
    this.email = this.obtenerProspecto.correo;
    this.primerNombre =
      this.obtenerProspecto.primerNombre === undefined
        ? null
        : this.obtenerProspecto.primerNombre;
    this.segundoNombre =
      this.obtenerProspecto.segundoNombre === undefined
        ? null
        : this.obtenerProspecto.segundoNombre;
    this.apellidoPaterno =
      this.obtenerProspecto.apellidoPaterno === undefined
        ? null
        : this.obtenerProspecto.apellidoPaterno;
    this.apellidoMaterno =
      this.obtenerProspecto.apellidoMaterno === undefined
        ? null
        : this.obtenerProspecto.apellidoMaterno;
    this.celular =
      this.obtenerProspecto.celular === undefined
        ? null
        : this.obtenerProspecto.celular;
    if (this.obtenerTipoCreditoProspecto === "") {
      this.enabledsend = true;
      this.btnDisabled = true;
    } else {
      this.status = this.obtenerTipoCreditoProspecto;
      this.enabledsend = false;
    }
    if (this.primerNombre == undefined) {
      this.enabledsend = false;
    }
    this.nombreCompleto = `${this.primerNombre} ${this.segundoNombre} ${this.apellidoPaterno} ${this.apellidoMaterno}`;
  },
  data: () => ({
    mostrarModalError: false,
    descripcionError: null,
    mostrarModalSesion: false,
    duro: false,
    primerNombre: "",
    segundoNombre: "",
    apellidoPaterno: "",
    apellidoMaterno: "",
    nombreCompleto: "",
    email: "",
    nss: "",
    celular: "",
    status: "",
    enabledsend: true,
    enabledrenvio: true,
    btnDisabled: true,
    tipoEnvio: "",
    envioLiga: true,
  }),
  components: {
    Menu,
    ModalSesion,
    ModalError,
    Tooltip
  },
  computed: {
    ...mapGetters([
      "obtenerDatosProspecto",
      "obtenerTipoCreditoProspecto",
      "obtenerProspecto",
    ]),
  },
  methods: {
    reenvioPagina() {
      this.$router.replace("/prospeccion/busquedaProspecto");
    },
    showmodal() {
      this.envioLiga = false;
      this.btnDisabled = false;
      this.$root.$emit("bv::show::modal", "modal-1");
    },
    hideModal() {
      this.$root.$emit("bv::hide::modal", "modal-1");
    },
    changueStatus() {
      this.enabledrenvio = false;
    },
    async enviarLigaProspecto() {
      this.$bvModal.show("modalError");
      this.descripcionError = "loadspin";
      const objBody = {
        curp: this.obtenerProspecto.curp,
        phoneNumber: this.obtenerProspecto.celular,
        email: this.obtenerProspecto.correo,
        notificationType: this.tipoEnvio,
        name:
          this.obtenerProspecto.primerNombre === undefined
            ? ""
            : btoa(this.obtenerProspecto.primerNombre),
        secondName:
          this.obtenerProspecto.segundoNombre === undefined
            ? ""
            : btoa(this.obtenerProspecto.segundoNombre),
        paternalSurname:
          this.obtenerProspecto.apellidoPaterno === undefined
            ? ""
            : btoa(this.obtenerProspecto.apellidoPaterno),
        maternalSurname:
          this.obtenerProspecto.apellidoMaterno === undefined
            ? ""
            : btoa(this.obtenerProspecto.apellidoMaterno),
        folio: this.obtenerProspecto.folio,
        executiveUser: Cookies.get("usuario"),
        contract: this.obtenerProspecto.contratosReestructura,
        socialSecurityNumber:
          this.obtenerProspecto.nss === undefined
            ? ""
            : this.obtenerProspecto.nss,
        amount: parseFloat(
          this.obtenerProspecto.monto.replace("$", "").replace(",", "")
        ),
        instalment: this.obtenerProspecto.plazo,
        discountCapacity: parseFloat(this.obtenerProspecto.capacidadDescuento),
        folioImss: this.obtenerProspecto.folioImss,
        rate: this.obtenerProspecto.tasa * 100,
      };
      const respuesta = await guardarProspecto(objBody)
        .then((r) => r)
        .catch((e) => e);
      this.$bvModal.hide("modalError");

      if (respuesta.status === 200) {
        this.mostrarModalError = false;
        this.descripcionError = null;
        this.btnDisabled = false;
        this.envioLiga = false;
        this.$bvModal.show("modal-1");
      } else {
        this.descripcionError = respuesta.descripcion;
        this.$bvModal.show("modalError");
      }
    },
    estatus() {
      this.status = "Nuevo";
      this.$router.replace("/prospeccion/Contratos");
    },
    add() {
      if (!this.btnDisabled) {
        this.enviarLigaProspecto();
      }
    },
    convertirPascalCase(texto) {
      const arragloTexto = texto.split(" ");
      const arregloFormato = arragloTexto.map(
        (t) => `${t.charAt(0).toUpperCase()}${t.slice(1).toLowerCase()}`
      );
      return arregloFormato.join(" ");
    },
  },
  beforeMount() {
    if (!Cookies.get("token")) {
      this.mostrarModalSesion = true;
    }
  },
};
</script>
<style scoped>
.myform1 {
  position: relative;
  display: -ms-flexbox;
  padding: 1rem;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgb(255, 255, 255);
  border-radius: 1.1rem;
  outline: 0;
  max-width: 500px;
}
.reduct {
  font-size: 0.99rem;
}
.btn-primary {
  color: #fff;
  background-color: #004b8d;
  border-color: #007bff;
  border-radius: 50px;
  height: 40px;
  width: 200px;
}
.valor {
  float: right;
}

.titulo {
  float: left;
}

.fila {
  display: flow-root;
}
.showc {
  display: block;
}
.showm {
  display: none;
}
@media (min-width: 360px) and (max-width: 979px) {
  .reduct {
    font-size: 1rem;
  }
  .reducts {
    font-size: 0.9rem;
  }
  .showm {
    display: block;
  }
  .showc {
    display: block;
  }
}

.disabled {
  cursor: not-allowed;
  color: gray !important;
}

/deep/ .modal {
  z-index: 9999;
  overflow-y: hidden;
}
/deep/ .modal-mask {
  position: fixed;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(248, 248, 248, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
/deep/ .modal-backdrop {
  z-index: -1;
  background-color: #fff;
  opacity: 0.9;
  -webkit-filter: blur(8px);
  -moz-filter: blur(8px);
  -o-filter: blur(8px);
  -ms-filter: blur(8px);
  filter: blur(8px);
}
/deep/ .modal-container {
  width: auto;
  max-width: 350px;
  background-color: #ffffff8c;
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  border: none;
}
/deep/ .modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border: none;
}
/deep/ .modal-header .close {
  border: none;
  color: #0181c4;
  background: white;
  font-size: 44px;
}
/deep/ .modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  align-self: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border: none;
  margin-top: 80px;
}
.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}
.modal-body {
  margin: 20px 0 80px 0;
}
.modal-default-button {
  float: right;
}

.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 0;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
/deep/ .modal-body {
  position: relative;
  font-size: 21px;
  color: #0181c4;
  margin: 0 0 62px 0;
}
/deep/ .btn-primary:hover {
  color: #fff;
  background-color: #004b8d;
  border-color: #007bff;
  border-radius: 50px;
}
/deep/ .btn-primary {
  color: #fff;
  background-color: #004b8d;
  border-color: #007bff;
  border-radius: 50px;
}

.btn-primary:hover {
  color: #fff;
  background-color: #004b8d;
  border-color: #007bff;
  border-radius: 50px;
}
.btn-primary {
  color: #fff;
  background-color: #004b8d;
  border-color: #007bff;
  border-radius: 50px;
}

.icon-warning {
  margin: 3rem 0;
}

.tooltip-input{
  display: flex;
  margin-top: -7px;
  margin-left: 5px;
}

.left{
  display: flex;
  float: right;
}

.content-icon{
  display: inline-flex;
}
</style>
