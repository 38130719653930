<template>
  <body>
    <Menu :cliente="true" :atras="false" />
    <ModalError :mostrar="mostrarModalError" :descripcion="descripcionError" />
    <ModalSesion :mostrar="mostrarModalSesion" />
    <div class="container">
      <div class="row">
        <div class="col-md-5 mx-auto">
          <div id="second">
            <div class="myform form" style="margin-top: 1%">
              <form action="#" name="registration">
                <div class="form-group">
                  <div class="logo mb-3 pl-0 pr-0 pt-3 colorb">
                    <br />
                    <img
                      class="img-responsive"
                      src="@/assets/img/icn_info.svg"
                      alt="img-info"
                    />
                    <br />
                    <br />
                    <label
                      >Iniciaremos la toma de tu<br />video para continuar con
                      el<br />proceso</label
                    >
                    <br />
                    <br />
                  </div>
                </div>
                <div class="form-group">
                  <label>Grabación de video</label> <br />
                  <br />
                  <b-form-checkbox
                    @change="aceptar = !aceptar"
                    name="some-radios"
                    value="A"
                    id="radioAceptar"
                  >
                    &nbsp;Acepto ser grabado</b-form-checkbox
                  >
                </div>
                <br />
                <br />
                <br />
                <div class="col-md-12 text-center pt-5">
                  <button
                    id="btnContinuar"
                    type="button"
                    class="btn btn-block mybtn btn-primary"
                    :disabled="!aceptar"
                    @click="continuar"
                  >
                    Continuar
                  </button>
                </div>
                <b-modal
                  content-class="modal-container"
                  id="modal-1"
                  ok-only
                  ok-title="Continuar"
                  align="center"
                  :hide-footer="true"
                  @close="onClose"
                >
                  <b-container fluid>
                    <div class="iconModal">
                      <img src="@/assets/img/icn_info.svg" alt="" />
                    </div>
                    <b-row class="mb-1">
                      <b-col
                        ><p class="modal-text-color"></p>
                        Tu trámite para obtener tu préstamo Profuturo será
                        validado en 24 horas
                      </b-col>
                    </b-row>
                    <br />
                    <p style="font-size: 16px; color: #0181c4;">
                      {{ date | moment }}
                    </p>
                    <br />
                    <p style="font-size: 18px; color: #374047;">
                      Te enviaremos una encuesta de
                    </p>
                    <p style="font-size: 18px; color: #374047;">
                      satisfacción al correo electrónico
                    </p>
                    <p style="font-size: 18px; color: #374047;">
                      registrado
                      <a style="color: #0181c4;">{{ enmascarado }}</a>
                    </p>
                  </b-container>
                </b-modal>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </body>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import Menu from "../genericos/Menu.vue";
import { cancelarLiga } from '../../services/tcs'
import ModalError from '../genericos/ModalError.vue'
import ModalSesion from '../genericos/ModalSesion.vue'
import Cookies from 'js-cookie'

export default {
  async created() {
    this.descripcionError = "loadspin"
    this.mostrarModalError = true
    await cancelarLiga({ curp: this.obtenerPrestaPro.curp.toUpperCase(), id: this.obtenerPrestaPro.uid }).then(r => {
      this.descripcionError = null
      this.mostrarModalError = false
      return r
    }).catch(e => e)
    this.email = this.obtenerPrestaPro.correo;
  },
  data: () => ({
    mostrarModalSesion: false,
    mostrarModalError: false,
    descripcionError: null,
    aceptar: false,
    fecha: "",
    date: [],
    email: "",
    value: "f.ernando@gmail.com",
    partes: "",
    enmascarado: "",
    customWindow: "",
  }),
  components: {
    Menu,
    ModalError,
    ModalSesion
  },
  computed: {
    ...mapGetters([
      "obtenerPrestaPro",
    ]),
  },
  methods: {
    continuar(){
      this.$router.replace("/prestapro/tuTramiteProfuturo");
    },
    showmodal() {
      this.$root.$emit("bv::show::modal", "modal-1");
      this.enmascararEmail();

    },
    onClose() {
      this.$router.replace("/prospeccion/busquedaProspecto");
    },
    hideModal() {
      this.$root.$emit("bv::hide::modal", "modal-1");
    },
    acabarTramite() {
      this.$router.replace("/prospeccion/busquedaProspecto");
    },
    enmascararEmail() {
      if (typeof this.value != "string") {
        throw TypeError("El argumento deben de ser caracteres");
      }
      this.partes = this.email.split("@");
      return (this.enmascarado = this.partes[0].substring(0, 2) + "****" + this.partes[1]);
    },
  },
  filters: {
    moment: function(date) {
      return moment(date).format("DD/MM/YYYY");
    },
  },
  beforeMount(){
    if(!Cookies.get('token')){
      this.mostrarModalSesion = true
    }
  }
};
</script>
<style scoped>
#btnContinuar {
  width: 276px;
  height: 45px;
}

/deep/ .modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(248, 248, 248, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
/deep/ .modal-backdrop {
  z-index: -1;
  background-color: #fff;
  opacity: 0.9;
  -webkit-filter: blur(8px);
  -moz-filter: blur(8px);
  -o-filter: blur(8px);
  -ms-filter: blur(8px);
  filter: blur(8px);
}
/deep/ .modal-container {
  width: 406px;
  height: 647px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #ffffff8c;
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  border: none;
}
/deep/ .modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border: none;
}
/deep/ .modal-header .close {
  border: none;
  color: #0181c4;
  background: white;
  font-size: 44px;
}
/deep/ .modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  align-self: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border: none;
  margin-top: 80px;
}
.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}
.modal-body {
  margin: 20px 0;
}
.modal-default-button {
  float: right;
}

.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 0;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
/deep/ .modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
  font-size: 21px;
  color: #0181c4;
}
/deep/ .btn-primary:hover {
  color: #fff;
  background-color: #004b8d;
  border-color: #007bff;
  border-radius: 50px;
}
/deep/ .btn-primary {
  color: #fff;
  background-color: #004b8d;
  border-color: #007bff;
  border-radius: 50px;
}

.iconModal {
  margin: 0rem 5rem;
}
</style>
