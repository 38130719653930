<template>
  <body>
    <Menu :cliente="false" titulo="Prospección" :pathAnterior="pathAnterior"/>
    <ModalError 
      :mostrar="mostrarModalError"
      :descripcion="descripcionError"
      :accion="cerrarModal"/>
    <ModalSesion :mostrar="mostrarModalSesion" />
    <div class="container">
      <div class="row">
        <div class="col-md-5 mx-auto">
          <div id="second">
            <div class="myform form ">
              <form action="#" name="registration" @submit.prevent="redirect">
                <div class="form-group">
                  <div class="d-flex flex-row">
                    <div class="p-2">
                      <label class="colorb"
                        >Ingresa el monto y plazo de tu préstamo</label>
                      <br />
                    </div>
                  </div>
                  <br />
                  <div class="form-group">
                    <div><Tooltip :texto="'Ingresa el monto mensual que deseas se te descuente.'" :enlace="'tooltip-capacidad-decuento'" /></div>
                    <label for="exampleInputMoney" class="left"
                      >Capacidad de descuento:</label>
                    <div class="input-group has-validation">
                      <span class="input-group-text" id="inputGroupPrepend"
                        >$</span>
                      <input
                        type="text"
                        name="money"
                        class="form-control input-tooltip-space"
                        id="exampleInputMoney"
                        aria-describedby="inputGroupPrepend"
                        required
                        v-model="capacidadDescuento"
                        @blur="obtenerOpciones"
                        @focus="limpiarCampo"
                        @keypress="validarNumeros"
                      />
                      <img src="@/assets/img/exclamation-circle-solid.svg" id="tooltip-capacidad-decuento" width="35" height="35" alt="tooltip-capacidad-decuento" class="tooltip-input-rigth" />
                      
                    </div>
                  </div>
                  <div :class="estiloCapacidad">
                    {{ textoCapacidad }}
                  </div>
                  <br />
                  <div class="form-group">
                    <div><Tooltip :texto="'Ingresa el número folio brindado por el IMSS.'" :enlace="'tooltip-folio-imss'" /></div>
                    <label for="exampleInputName2" class="left">Folio IMSS:</label>
                    <div class="input-group">
                      <input
                        type="text"
                        name="folio"
                        class="form-control input-tooltip-space"
                        id="folio"
                        required
                        maxlength="13"
                        @blur="validarIMSS"
                        @keypress="validarAlfanumericos"
                        v-model="imss"
                      />
                      <img src="@/assets/img/exclamation-circle-solid.svg" id="tooltip-folio-imss" width="35" height="35" alt="tooltip-folio-imss" class="tooltip-input-rigth" />
                    </div>
                  </div>
                  <div :class="estiloIMSS">
                    {{ textoIMSS }}
                  </div>
                  <br />
                  <div class="form-group">
                    <div><Tooltip :texto="'Selecciona el tiempo en que deseas se concluya el préstamo.'" :enlace="'tooltip-plazo'" /></div>
                    <label id="labelPlazo" for="inputPlazo" class="left"
                      >Plazo del préstamo:</label
                    >
                    <div class="input-group">
                      <select 
                        class="form-select input-tooltip-space" 
                        name="plazo" 
                        id="inputPlazo"
                        required
                        v-model="plazo"
                        @blur="validarPlazo"
                        >
                        <option selected disabled value="">Selecciona un plazo</option>
                        <option
                          v-for="opcion in opciones"
                          :value="opcion.Plazo"
                          :key="opcion.Plazo"
                          >{{ opcion.Plazo }}</option
                        >
                      </select>
                      <img src="@/assets/img/exclamation-circle-solid.svg" id="tooltip-plazo" width="35" height="35" alt="tooltip-plazo" class="tooltip-input-rigth" />
                    </div>
                  </div>
                  <div :class="estiloPlazo">
                    {{ textoPlazo }}
                  </div>
                  <br />
                  <br />
                  <div class="form-group" name="monto">
                    <label for="exampleInputName2">Monto:</label>
                    <div class="p-2">
                      <label class="colorb" style="color: #0181c4;"
                        >{{ monto || '' }}</label>
                      <br />
                    </div>
                  </div>
                </div>
                <div class="col-md-12 text-center mb-3 pt-4">
                  <button
                    type="submit"
                    class=" btn btn-block mybtn btn-primary"
                    :disabled="!continuar">
                    Continuar
                  </button>
                </div>
                <div class="col-md-12 "></div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </body>
</template>

<script>

import { obtenerCotizacion } from '../../services/prestapro'
import { guardarCotizacion } from '../../services/prospecto'
import ModalError from '../genericos/ModalError.vue'
import Menu from '../genericos/Menu.vue'
import { mapGetters } from 'vuex'
import { caracteresNoPermitidos, numeroyPunto } from '../../constants/cajaTexto'
import { eliminarCaracteresExpeciales, validarDecimales } from '../../utils/formatoTexto'
import Cookies from "js-cookie"
import ModalSesion from '../genericos/ModalSesion.vue'
import { obtenerParametro } from '../../utils/url'
import Tooltip from '../genericos/Tooltip.vue'

export default {
  name: 'montoPlazoPath',
  components: {
    ModalError,
    Menu,
    ModalSesion,
    Tooltip
  },
  data(){
    return {
      mostrarModalSesion: false,
      mostrarModalError: false,
      descripcionError: null,
      opciones: [],
      capacidadDescuento: "",
      imss: "",
      plazo: "",
      monto: null,
      textoCapacidad: null,
      estiloCapacidad: "",
      estiloIMSS: "",
      textoIMSS: null,
      estiloPlazo: "",
      textoPlazo: null,
      continuar: false,
      bloqueoCapacidad: true,
      bloqueoIMSS: true,
      bloqueoPlazo: true,
      blurModal: true,
      opcion: null,
      pathAnterior: ""
    }
  },
  mounted(){
    const ruta = obtenerParametro('anterior')
    if( ruta !== null ){
      if( ruta === 'contrato' ){
        this.pathAnterior = `/prospeccion/Contratos?anterior=registro`
      }else if( ruta === 'registro' ) {
        this.pathAnterior = `/prospeccion/registroProspecto/${this.obtenerProspecto.curp}?direct=plazo`
      }
    }else{
      this.pathAnterior = `/prospeccion/registroProspecto/${this.obtenerProspecto.curp}?direct=plazo`
    }
  },
  computed: {
    ...mapGetters(['obtenerProspecto'])
  },
  methods: {
    validarNumeros: ($e) => {
      if(!numeroyPunto.includes($e.charCode)){
        $e.preventDefault()
      }
    },
    validarAlfanumericos: ($e) => {
      if(caracteresNoPermitidos.includes($e.charCode)){
        $e.preventDefault()
      }
    },
    limpiarCampo(){
      this.capacidadDescuento = ""
    },
    agregarCerosCapacidadDescuento() {
      if(this.capacidadDescuento.includes(".")){
        this.capacidadDescuento = this.capacidadDescuento + "00"
      }else{
        this.capacidadDescuento = this.capacidadDescuento + ".00"
      }
    },
    async obtenerOpciones() {

      this.agregarCerosCapacidadDescuento()

      this.capacidadDescuento = this.formatearMoneda(this.capacidadDescuento)
      this.opciones = []
      this.opcion = null
      this.plazo = ""
      this.monto = null
      this.bloqueoCapacidad = true
      this.bloqueoPlazo = true
      if(this.capacidadDescuento !== ""){
        const expresionCapcidad = /^[0-9]{1,100}([.][0-9]{2})?/
        if( expresionCapcidad.test( this.capacidadDescuento ) ){
          this.mostrarModalError = true
          this.descripcionError = "loadspin"
          this.textoCapacidad = null
          this.estiloCapacidad = ""
          const { folios, curp, tipo } = this.obtenerProspecto
          let objUrl = {}
          if ( curp === undefined ){
            objUrl = {
              fechaNacimiento: "",
              capacidadDescuento: "",
              reestructura: "",
              folio: ""
            }
          } else {
            objUrl = {
              fechaNacimiento: `${curp.substring(8,10)}/${curp.substring(6,8)}/19${curp.substring(4,6)}`,
              capacidadDescuento: parseFloat(this.capacidadDescuento),
              reestructura: tipo === 'Reestructura' ? 1 : 0,
              folio: folios === undefined ? '' : folios.join("_")
            }
          }
          
          const respuesta = await obtenerCotizacion(objUrl)
            .then(r => {
              if(r.data.Errores[0].Codigo === 1010001){
                this.mostrarModalError = false
                return r
              } else {
                this.mostrarModalError = true
                this.descripcionError = r.data.Errores[0].Mensaje
                this.capacidadDescuento = ""
                return null
              }
            })
            .catch(e => {
              this.mostrarModalError = false
              const {descripcion, mostrarModal } = e;
              this.mostrarModalError = mostrarModal
              this.descripcionError = descripcion
            })
          if(respuesta){
            const { data: { Opciones } } = respuesta
            this.opciones = Opciones
            this.bloqueoCapacidad = false
          }
        } else {
          this.textoCapacidad = "Dato Incorrecto"
          this.estiloCapacidad = "showError"
        }
      }else{
        this.textoCapacidad = "Dato Obligatorio"
        this.estiloCapacidad = "showError"
      }
      this.validarBotonHabilitado()
    },
    formatearDescuento($event){
      if(!($event.charCode >= 48 && $event.charCode <= 57)){
          $event.preventDefault()
      }
    },
    validarBotonHabilitado(){
      if(!this.bloqueoCapacidad && !this.bloqueoIMSS && !this.bloqueoPlazo){
        this.continuar = true
      }else{
        this.continuar = false
      }
    },
    cerrarModal(){
      this.textoCapacidad = null
      this.estiloCapacidad = ""
      this.estiloIMSS = ""
      this.textoIMSS = null
      this.estiloPlazo = ""
      this.textoPlazo = null
      this.opciones = []
      this.capacidadDescuento = ""
      this.plazo = ""
      this.monto = null
      this.mostrarModalError = false
    },
    async redirect(){
      this.$store.commit('agregarValorProspecto', { plazo: this.plazo, monto: this.monto })
      if( this.opcion !== null ){
        this.descripcionError = "loadspin"
        this.mostrarModalError = true
        await guardarCotizacion(
          {
            esReestructura: this.obtenerProspecto.tipo === 'Reestructura' ? 1 : 0,
            contratosReestructura: this.obtenerProspecto.folios !== undefined ? this.obtenerProspecto.folios.join("_") : "",
            Plazo: this.opcion.Plazo,
            MontoPrestamo: this.opcion.MontoPrestamo,
            Seguro: this.opcion.Seguro,
            PorcentajeSeguro: this.opcion.PorcentajeSeguro,
            DescuentoMensual: this.opcion.DescuentoMensual
          }
          ).then(r => {
            const { data: { folio, Errores } } = r
            if(Errores[0].Codigo === 1010001){
              this.mostrarModalError = false
              this.$store.commit('agregarValorProspecto', { 
                folio: folio, 
                tasa:  this.opcion.Tasa,
                contratosReestructura: this.obtenerProspecto.folios !== undefined ? this.obtenerProspecto.folios.join("_") : "",
                folioImss: this.imss
              })
              this.$router.replace("/prospeccion/InformacionProspecto");
            } else {
              this.mostrarModalError = true
              this.descripcionError = r.data.Errores[0].Mensaje
            }
          })
          .catch(e => e)
      }
      
    },
    validarIMSS(){
      if( this.imss === ""){
        if((this.blurModal && this.capacidadDescuento === "") || (!this.blurModal && (this.capacidadDescuento === "" || this.capacidadDescuento !== ""))){
          this.textoIMSS = "Dato Obligatorio"
          this.estiloIMSS = "showError"
          this.bloqueoIMSS = true
        } else if (this.blurModal && this.capacidadDescuento !== ""){
          this.blurModal = false
        }
      } else {
        this.textoIMSS = null
        this.estiloIMSS = ""
        const expresionIMSS = /^\d{11}-\d{1}$/
        if(!expresionIMSS.test(this.imss)){
          this.estiloIMSS = "showError"
          this.textoIMSS = "Dato Incorrecto"
          this.bloqueoIMSS = true
        }else{
          this.estiloIMSS = ""
          this.textoIMSS = null
          this.bloqueoIMSS = false
        }
      }
      this.validarBotonHabilitado()
    },
    validarPlazo(){
      if(this.plazo === ""){
        this.estiloPlazo = "showError"
        this.textoPlazo = "Seleccione un plazo"
      }else{
        this.bloqueoPlazo = false
        this.estiloPlazo = ""
        this.textoPlazo = null
        
      }
      this.validarBotonHabilitado()
    },
    ajustarDecimal(type, value, exp){
      if (typeof exp === 'undefined' || +exp === 0) {
        return Math[type](value);
      }
      value = +value;
      exp = +exp;
      if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
        return NaN;
      }
      value = value.toString().split('e');
      value = Math[type](+(value[0] + 'e' + (value[1] ? (+value[1] - exp) : -exp)));
      value = value.toString().split('e');
      return +(value[0] + 'e' + (value[1] ? (+value[1] + exp) : exp));
    },
    formatearMoneda(numero) {
      if(numero !== ""){
        this.$store.commit('agregarValorProspecto', { capacidadDescuento: this.capacidadDescuento })
      }
      return numero
    }
  },
  watch: {
    capacidadDescuento( value, oldValue ){
      const caracteres = ['0', '1', '2','3', '4', '5','6', '7', '8','9'];
      if(value.length === 1 && !caracteres.includes(value)){
        this.capacidadDescuento = ""
      }else{
        const unPunto = /^\d+([.])?(\d+)?$/
        if(unPunto.test(value)){
          this.capacidadDescuento = validarDecimales( value )
          if(this.capacidadDescuento === "" || this.capacidadDescuento === null){
            this.textoCapacidad = "Dato Obligatorio"
            this.estiloCapacidad = "showError"
          }else{
            this.textoCapacidad = null
            this.estiloCapacidad = ""
          }
        }else{
          this.capacidadDescuento = validarDecimales( value )
          if(this.capacidadDescuento.length > 1){
            this.capacidadDescuento = oldValue
          }
          
        }
      }
      
      
    },
    imss( value ){
      this.imss = eliminarCaracteresExpeciales( value )
      this.validarIMSS()
    },
    plazo( value ){
      if( value !== "" ){
        this.opcion = this.opciones.find(r => r.Plazo === value)
        const { MontoPrestamo } = this.opcion
        const formatearNumero = this.ajustarDecimal('round', MontoPrestamo, -2)
        this.monto = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2,  }).format(formatearNumero)
        this.validarPlazo()
      }
    }
  },
  beforeMount(){
    if(!Cookies.get('token')){
      this.mostrarModalSesion = true
    }
  }
}

</script>
<style scoped>
.btn-primary {
  color: #fff;
  background-color: #004b8d;
  border-color: #007bff;
  border-radius: 50px;
  height: 40px;
  width: 200px;
}
.left{
  display: flex;
  padding-left: 5px;
}
.tooltip-input{
  display: flex;
  float: left;
  margin-top: -7px;
}
.tooltip-input-rigth{
  margin: 1px;
  margin-left: 10px;

}
.input-tooltip-space{
  margin-right: 3px;
}
</style>
