<template>
  <body>
    <Menu :cliente="false" titulo="Prospección" :pathAnterior="'/prospeccion/busquedaProspecto'" />
    <ModalSesion :mostrar="mostrarModalSesion" />
    <div class="container">
      <div class="row">
        <div class="col-md-5 mx-auto">
          <div id="second">
            <div class="myform form" style="margin-top: 1%">
              <form
                action
                name="registration"
                @submit.prevent="registrarProspecto"
              >
                <div class="form-group">
                  <div class="logo mb-3 pl-0 pr-0 pt-3 softbluetittle">
                    <img
                      class="icon-expresion"
                      src="@/assets/img/icn_info.svg"
                      alt="img-expresion"
                    />
                    <br />
                    <br />
                    <label
                      >{{ tituloRegistrar }}</label
                    >
                  </div>
                </div>
                <div class="form-group">
                  <label class="left">CURP:</label>
                  <input
                    type="text"
                    maxlength="18"
                    minlength="18"
                    name="lastName"
                    class="form-control"
                    id="lastName"
                    disabled
                    aria-describedby="emailHelp"
                    placeholder=""
                    v-model="curp"
                    style="text-transform:uppercase;"
                    value=""
                    onkeyup="javascript:this.value=this.value.toUpperCase();"
                  />
                </div>
                <br />
                <div class="form-group">
                  <label class="left">Teléfono celular:</label>
                  <input
                    type="text"
                    maxlength="10"
                    minlength="10"
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    name="cel"
                    class="form-control"
                    id="cel"
                    @keypress="validarNumeros"
                    aria-describedby="emailHelp"
                    :class="userthemec"
                    v-on:blur="checkCel()"
                    v-model="telefonoCelular"
                  />
                </div>
                <div :class="usertheme">
                  {{ text.phone }}
                </div>
                <br />
                <div class="form-group">
                  <label class="left">Correo electrónico:</label>
                  <input
                    type="text"
                    name="email"
                    class="form-control"
                    id="email"
                    aria-describedby="emailHelp"
                    placeholder=""
                    :class="passwordthemec"
                    v-on:blur="checkMail()"
                    @keypress="validarAlfanumerico"
                    v-model="email"
                  />
                </div>
                <div :class="passwordtheme">
                  {{ text.mail }}
                </div>
                <div class="col-md-12 text-center mb-3 pt-4">
                  <button
                    type="submit"
                    :disabled="mailOk"
                    class="btn btn-block mybtn btn-primary"
                  >
                    {{botonRegistrar}}
                  </button>
                </div>
                <div class="col-md-12"></div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </body>
</template>

<script>
import Menu from '../genericos/Menu.vue'
import { caracteresNoPermitidos, numeros } from '../../constants/cajaTexto'
import { validarSoloNumeros, eliminarCaracteresExpeciales } from '../../utils/formatoTexto'
import Cookies from "js-cookie"
import ModalSesion from '../genericos/ModalSesion.vue'
import { obtenerParametro } from '../../utils/url'
import { mapGetters } from 'vuex'

export default {
  components: {
    Menu,
    ModalSesion,
  },
  created() {
    this.curp = this.$route.params.curp;
  },
  data: () => ({
    mostrarModalSesion: false,
    curp: "",
    telefonoCelular: "",
    email: "",
    mailOk: true,
    check1: true,
    check2: true,
    check3: true,
    usertheme: "",
    passwordtheme: "",
    userthemec: "",
    passwordthemec: "",
    text: {
      phone: "",
      mail: "",
    },
    showerror: "",
    directRouter: null,
    botonRegistrar: 'Registrar',
    tituloRegistrar: 'Para continuar el registro del prospecto ingresa los datos:',
  }),
  watch: {
    telefonoCelular(value) {

      this.telefonoCelular = validarSoloNumeros(value)
      this.checkCel()
      
    },
    email( value ){
      this.email = eliminarCaracteresExpeciales( value )
      this.checkMail()
    }
  },
  computed: {
    ...mapGetters(['obtenerProspecto'])
  },
  mounted() {
    const direct = obtenerParametro('direct')
    if( direct !== "" ){
      this.botonRegistrar = 'Continuar'
      this.tituloRegistrar = 'Para continuar válida los datos de contacto:'
      this.directRouter = direct
      this.telefonoCelular = this.obtenerProspecto.celular
      this.email = this.obtenerProspecto.correo
    }
  },
  methods: {
    validarNumeros($e) {
      if (!numeros.includes($e.charCode)) {
        $e.preventDefault();
      }
    },
    validarAlfanumerico: ($e) => {
      if (caracteresNoPermitidos.includes($e.charCode)) {
        $e.preventDefault();
      }
    },
    registrarProspecto() {
      const datosProspectoNuevo = {
        celular: this.telefonoCelular,
        correo: this.email,
      }
      this.$store.commit('agregarValorProspecto', datosProspectoNuevo)
      if(this.directRouter === null){
        this.$router.replace("/prospeccion/montoPlazo")
      }else{
        if(this.directRouter === 'plazo'){
          this.$router.replace({
            path: "/prospeccion/montoPlazo",
            query: {
              anterior: 'registro'
            }
          })
        }else{
          this.$router.replace({
            path: "/prospeccion/Contratos",
            query: {
              anterior: 'registro'
            }
          })
        }
      }
    },
    validate(error, type) {
      if (error) {
        if (type == "user") {
          this.usertheme = "showError";
          this.userthemec = "errorText";
        } else {
          this.passwordtheme = "showError";
          this.passwordthemec = "errorText";
        }
      } else if (type != undefined) {
        if (type == "user") {
          this.usertheme = "hideError";
          this.userthemec = "normalText";
        } else {
          this.passwordtheme = "hideError";
          this.passwordthemec = "normalText";
        }
      }
    },
    checkMail: function() {
      const { email } = this;
      if (email == "") {
        this.check3 = true;
        this.text.mail = "Dato Obligatorio";
        this.validate(true, "pass");
        this.validaFormulario();
      } else if (!email.includes("@")) {
        this.text.mail = "Dato Incorrecto";
        this.check3 = true;
        this.validate(true, "pass");
        this.validaFormulario();
      } else if (email !== "" && email.includes("@")) {
        this.email = this.email.toUpperCase()
        this.check3 = false;
        this.validate(false, "pass");
        this.validaFormulario();
      }
    },
    validaFormulario() {
      if (!this.check2 && !this.check3) {
        this.mailOk = false;
      } else {
        this.mailOk = true;
      }
    },
    checkCel: function() {
      const { telefonoCelular } = this;
      if (telefonoCelular.length > 0 && telefonoCelular.length < 10) {
        this.text.phone = "Dato Incorrecto";
        this.check2 = true;
        this.validate(true, "user");
        this.validaFormulario();
      } else if (telefonoCelular === "") {
        this.text.phone = "Dato Obligatorio";
        this.check2 = true;
        this.validate(true, "user");
        this.validaFormulario();
      } else if (telefonoCelular !== "" && telefonoCelular.length === 10) {
        this.check2 = false;
        this.validate(false, "user");
        this.validaFormulario();
      }
    },
    isNumber: function(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        (charCode > 31 && (charCode < 48 || charCode > 57) && charCode == 46) ||
        charCode == 45 ||
        charCode == 101 ||
        charCode == 43
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
  beforeMount() {
    if (!Cookies.get("token")) {
      this.mostrarModalSesion = true;
    }
  },
};
</script>

<style scoped>
.left {
  display: table-cell;
}
</style>
