<template>
  <body>
   <Menu :cliente="true" :atras="false"></Menu>
   <ModalSesion :mostrar="mostrarModalSesion" />
   <ModalError :mostrar="mostrarModalError" :descripcion="descripcionError"></ModalError>
   <iframe class="body" id="fad-iframe-video" sandbox="allow-scripts allow-same-origin allow-modals allow-forms" allow="geolocation; camera; microphone" title="OCR" />
  </body>
</template>

<script>

import Menu from '../genericos/Menu.vue'
import ModalSesion from '../genericos/ModalSesion.vue'
import ModalError from '../genericos/ModalError.vue'
import Cookies from 'js-cookie';
import { obtenerDocumentos, obtenerDocumentosIn } from '../../services/prestapro'
import { obtenerToken, obtenerTiket } from '../../services/fad'
import { mapGetters } from 'vuex'

export default {
 name: "Video",
 components: {
  Menu,
  ModalSesion,
  ModalError
 },
 data: () => ({
   mostrarModalSesion: false,
   mostrarModalError: false,
   descripcionError: null
 }),
 computed: {
    ...mapGetters(['obtenerPrestaPro'])
 },
 methods: {
   obtenerBlob(base64, type) {
      const bString = atob(base64);
      const bLength = bString.length;
      const bytes = new Uint8Array(bLength);
      for (let i = 0; i < bLength; i++) {
          const ascii = bString.charCodeAt(i);
          bytes[i] = ascii;
      }
      return new Blob([bytes], { type: type });
   },
   async obtenerDocumentosBase64(){
     this.mostrarSpinner()
     const respuesta = await obtenerDocumentos(this.obtenerPrestaPro.folio).then(r => r).catch(e => e)
     this.ocultarSpinner()
     if(respuesta.status === 200){
       const error = respuesta.data.Errores[0]
       if(error.Codigo === 1010001){
         const { DocumentoPdf, DocumentoXML, Hash } = respuesta.data
         return {
           base64PDF: DocumentoPdf,
           base64XML: DocumentoXML,
           hash: Hash
         }
       }else{
         this.mostrarModalError = true
         this.descripcionError = error.Mensaje
         return null;
       }
     }
   },
   async obtenerJWTFad(){
     this.mostrarSpinner()
     const respuestaToken = await obtenerToken().then(r => r).catch(e => e)
     this.ocultarSpinner()
     if(respuestaToken.data.access_token){
       return respuestaToken.data.access_token
     }else{
       this.mostrarModalError = true
       this.descripcionError = 'Hay un error al obtener el acceso al servicio.'
       return null
     }
   },
   async enviarDocumentosFad(xml, pdf, hash, jwt){
     this.mostrarSpinner()
     const respuestaTiket = await obtenerTiket(xml, pdf, hash, jwt).then(r => r).catch(e => e)
     this.ocultarSpinner()
     if(respuestaTiket.status === 200){
       return respuestaTiket.data
     } else {
      return null
     }
   },
   async guardarBitacora(data){
     this.mostrarSpinner()
     const tickets = []
      for (const [key, value] of Object.entries(data.data.allTickets)) {
        tickets.push( {
          Clave: key,
          Valor: value
        })
      }
     const dataBody = {
       Folio: this.obtenerPrestaPro.folio,
       Success: data.success,
       Error: data.error,
       Code: data.code,
       Type: {
         RequisitionId: data.data.requisitionId,
         Url: data.data.url,
         Key: data.data.key,
         Vector: data.data.vector,
         Ticket: data.data.ticket,
         AllTicket: tickets
       }
     }
     const respuesta = await obtenerDocumentosIn(dataBody).then(r => r).catch(e => e)
     this.ocultarSpinner()
     if(respuesta.status === 200){
       const error = respuesta.data.Errores[0]
       if(error.Codigo === 1010001){
         return true
       }else{
         this.mostrarModalError = true
         this.descripcionError = error.Mensaje
         return null
       }
     }
   },
   mostrarSpinner(){
     this.mostrarModalError = true
     this.descripcionError = 'loadspin'
   },
   ocultarSpinner(){
     this.mostrarModalError = false
     this.descripcionError = null
   },
   initIframe(ticket){
     const iframe = document.getElementById('fad-iframe-video')
     const url = `${process.env.VUE_APP_FAD_IFRAME_VIDEO}/main?ticket=${ticket}`
     iframe.src = url
     iframe.onload = () => {
       this.ocultarSpinner()
     }
   },
   crearPostMessage(){
      window.addEventListener("message", async (message) => {
      if (message.origin.includes("firmaautografa.com")) {
        if(message.data.event === "PROCESS_COMPLETED"){
          this.$router.replace("/prestapro/tuTramiteProfuturo");
        }else if(message.data.event === "PROCESS_ERROR"){
          const intento = parseInt(this.obtenerPrestaPro.intento);
          if(intento < 3){
            if(this.obtenerPrestaPro.intento !== undefined){
              this.$store.commit('agregarValorPrestaPro', { intento: (intento + 1) })
            }else{
              this.$store.commit('agregarValorPrestaPro', { intento: 1 })
            }
            this.$router.go(this.$router.currentRoute)
          }
        }
      } else return;
    });
   },
 },
 async mounted() {
    this.crearPostMessage()
    const respuestaDocs = await this.obtenerDocumentosBase64()
    if(respuestaDocs !== null){
      const { base64PDF, base64XML, hash } = respuestaDocs
      const pdf = this.obtenerBlob(base64PDF, "application/pdf")
      const xml = this.obtenerBlob(base64XML, "application/xml")
      const respuestaToken = await this.obtenerJWTFad()
      if(respuestaToken !== null){
        const respuestaTiket = await this.enviarDocumentosFad(xml, pdf, hash, respuestaToken)
        if( respuestaTiket !== null ){
          const respuestaGuardar = await this.guardarBitacora(respuestaTiket)
          if(respuestaGuardar){
            this.initIframe(respuestaTiket.data.ticket)
            this.crearPostMessage()
          }
        }
      }
    }
 },
 beforeMount(){
    if(!Cookies.get('token')){
      this.mostrarModalSesion = true
    }
  }
}
</script>

<style scoped>
.body{
  margin-top: 75px;
  overflow:hidden;
  overflow-x:hidden;
  overflow-y:hidden;
  height:90.4%;
  width:100%;
  position:absolute;
  top:0px;
  left:0px;
  right:0px;
  bottom:0px
}

/deep/ .modal{
  z-index: 9999;
}
/deep/ .modal-mask {
  position: fixed;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(248, 248, 248, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
/deep/ .modal-backdrop {
  z-index: -1;
  background-color: #fff;
  opacity: 0.9;
  -webkit-filter: blur(8px);
  -moz-filter: blur(8px);
  -o-filter: blur(8px);
  -ms-filter: blur(8px);
  filter: blur(8px);
}
/deep/ .modal-container {
  width: auto;
  max-width: 350px;
  background-color: #ffffff8c;
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  border: none;
}
/deep/ .modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border: none;
}
/deep/ .modal-header .close {
  border: none;
  color: #0181c4;
  background: white;
  font-size: 44px;
}
/deep/ .modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  align-self: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border: none;
  margin-top: 120px;
}
.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}
.modal-body {
  margin: 20px 0 80px 0;
}
.modal-default-button {
  float: right;
}
.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 0;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
/deep/ .modal-body {
  position: relative;
  font-size: 21px;
  color: #0181c4;
  margin: 0 0 62px 0;
  margin-top: 80px;
}
/deep/ .btn-primary:hover {
  color: #fff;
  background-color: #004b8d;
  border-color: #007bff;
  border-radius: 50px;
}
/deep/ .btn-primary {
  color: #fff;
  background-color: #004b8d;
  border-color: #007bff;
  border-radius: 50px;
}

.btn-primary:hover {
  color: #fff;
  background-color: #004b8d;
  border-color: #007bff;
  border-radius: 50px;
}
.btn-primary {
  color: #fff;
  background-color: #004b8d;
  border-color: #007bff;
  border-radius: 50px;
}

.icon-warning {
  margin: 3rem 0;
}
</style>