import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import BusquedaProspecto from '../views/prospeccion/BusquedaProspecto.vue'
import InformacionProspecto from '../views/prospeccion/InformacionProspecto.vue'
import RegistroProspecto from '../views/prospeccion/RegistroProspecto.vue'
import Bienvenida from '../views/prestapro/Bienvenida.vue'
import GeneraProceso from '../views/prestapro/GeneraProceso.vue'
import IngresaDireccion from '../views/prestapro/IngresaDireccion.vue'
import DatosContacto from '../views/prestapro/DatosContacto.vue'
import { esAutorizado } from '../services/autorizacion'
import ConfirmaDatosPrest from '../views/prestapro/ConfirmaDatosPrest.vue'
import Contratos from '../views/prospeccion/Contratos.vue'
import VerificaCelular from '../views/prestapro/VerificaCelular.vue'
import _1_casos_alternos from '../views/prestapro/_1_casos_alternos.vue'
import _30_encuesta from '../views/prestapro/_30_encuesta.vue'
import _29_1_comprobante_de_pago from '../views/prestapro/_29_1_comprobante_de_pago.vue'
import _32_1_video from '../views/prestapro/_32_1_video.vue'
import _29_4_comprobante_de_pago from '../views/prestapro/_29_4_comprobante_de_pago'
import _42_documentos_contractuales from '../views/prestapro/_42_documentos_contractuales'
import CapturaIne from '../views/prestapro/CapturaIne.vue'
import _30_casos_alternos from '../views/prestapro/_30_casos_alternos'
import CapturaCartaLibranza from '../views/prestapro/CapturaCartaLibranza'
import ComprobanteCartaLibranza from '../views/prestapro/ComprobanteCartaLibranza'
import montoPlazo from '../views/prospeccion/montoPlazo'
import CapturaDatosPension from '../views/prestapro/CapturaDatosPension'
import MotorAutenticacion from '../views/prestapro/MotorAutenticacion.vue'
import IneOCR from '../views/prestapro/IneOCR.vue'
import IneDeclaracion from '../views/prestapro/IneDeclaracion.vue'
import paginaNoActiva from '../views/genericos/paginaNoActiva.vue'
import tuTramiteProfuturo from '../views/prestapro/tuTramiteProfuturo.vue'
import ResultadoOCR from '../views/prestapro/ResultadoOCR.vue'
import Video from '../views/prestapro/Video.vue'
import Cookies from 'js-cookie'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: { disallowAuthed: true }
    },
    {
        path: '/prestapro/VerificaCelular',
        name: 'VerificaCelularPath',
        component: VerificaCelular
    },
    {
        path: '/prestapro/_32_1_video',
        name: '_32_1_videoPath',
        component: _32_1_video
    },
    {
        path: '/prestapro/_29_4_comprobante_de_pago',
        name: '_29_4_comprobante_de_pagoPath',
        component: _29_4_comprobante_de_pago
    },
    {
        path: '/prestapro/_42_documentos_contractuales',
        name: '_42_documentos_contractualesPath',
        component: _42_documentos_contractuales
    },
    {
        path: '/prestapro/_30_encuesta',
        name: '_30_encuestaPath',
        component: _30_encuesta
    },
    {
        path: '/prestapro/_29_1_comprobante_de_pago',
        name: '_29_1_comprobante_de_pagoPath',
        component: _29_1_comprobante_de_pago
    },
    {
        path: '/prospeccion/Contratos',
        name: 'Contratos',
        component: Contratos
    },
    {
        path: '/prestapro/confirmaDatosPrest',
        name: 'ConfirmaDatosPrest',
        component: ConfirmaDatosPrest
    },
    {
        path: '/prospeccion/busquedaProspecto',
        name: 'busquedaProspectoPath',
        component: BusquedaProspecto,
        meta: { disallowAuthed: true }
    },
    {
        path: '/prestapro/capturaCartaLibranza',
        name: 'capturaCartaLibranzaPath',
        component: CapturaCartaLibranza
    },
    {
        path: '/prestapro/ComprobanteCartaLibranza',
        name: 'capturaComprobanteCartaLibranzaPath',
        component: ComprobanteCartaLibranza
    },
    {
        path: '/prospeccion/informacionProspecto',
        name: 'InformacionProspectoPath',
        component: InformacionProspecto
    },
    {
        path: '/prospeccion/registroProspecto/:curp',
        name: 'RegistroProspectoPath',
        component: RegistroProspecto
    },
    {
        path: '/prestapro',
        name: 'bienvenida',
        component: Bienvenida
    },
    {
        path: '/prestapro/generaProceso',
        name: 'GeneraProcesoPath',
        component: GeneraProceso
    },
    {
        path: '/prestapro/7pasos',
        name: '_1_casos_alternosPath',
        component: _1_casos_alternos
    },
    {
        path: '/prestapro/ingresaDireccion',
        name: 'IngresaDireccionPath',
        component: IngresaDireccion
    },
    {
        path: '/prestapro/datosContacto',
        name: 'DatosContactoPath',
        component: DatosContacto
    },
    {
        path: '/prestapro/capturaIne',
        name: 'CapturaIne',
        component: CapturaIne
    },
    {
        path: '/prestapro/_30_casos_alternos',
        name: '_30_casos_alternosPath',
        component: _30_casos_alternos
    },
    {
        path: '/prospeccion/montoPlazo',
        name: 'montoPlazoPath',
        component: montoPlazo
    },
    {
        path: '/prestapro/capturaDatosPension',
        name: 'CapturaDatosPension',
        component: CapturaDatosPension
    },
    {
        path: '/prestapro/motorIne',
        name: 'MotorAutenticacion',
        component: MotorAutenticacion
    },
    {
        path: '/prestapro/ineFrente',
        name: 'IneOCR',
        component: IneOCR
    },
    {
        path: '/prestapro/ineFoto',
        name: 'IneDeclaracion',
        component: IneDeclaracion
    },
    {
        path: '/paginaExpirada',
        name: 'paginaNoActivaPath',
        component: paginaNoActiva
    },
    {
        path: '/prestapro/tuTramiteProfuturo',
        name: 'tuTramiteProfuturoPath',
        component: tuTramiteProfuturo
    },
    {
        path: '/prestapro/resultadoIne',
        name: 'resultadoIne',
        component: ResultadoOCR
    },
    {
        path: '/prestapro/documentos-contractuales',
        name: 'Video',
        component: Video
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        window.scrollTo(0,0)
    }
})

router.beforeEach((to, from, next) => {
    if(!Cookies.get('usuario')){
        next()
    }else{
        if (to.name !== "Home" && !esAutorizado()) {
            next("/");
        } else if (to.name === "Home" && esAutorizado()) {
            next("/prospeccion/busquedaProspecto");
        } else {
            next();
        }
    }
})

export default router

