<template>
    <nav class="navbar navbar-expand-sm navbar-light bg-light shadow fixed-top">
    <div class="container menu">
        <ul v-if="atras" class="navbar-nav">
            <li class="ml-5 icon-color atras">
                <font-awesome-icon icon="arrow-left" @click="retroceder" />
            </li>
        </ul>
        <ul class="nav navbar-nav mx-auto">
            <li class="nav-item titulo">
                <img v-if="cliente" width="105" src="@/assets/img/logo_horizontal.svg" alt="img-logo" />
                <label v-else class="navbar-brand">{{ titulo }}</label>
            </li>
        </ul>
    </div>
</nav>
</template>

<script>
export default {
    props: {
        cliente: {
            type: Boolean,
            default: false,
        },
        titulo: {
            type: String,
            default: ""
        },
        atras: {
            type: Boolean,
            default: true
        },
        pathAnterior: {
            type: String,
            default: ""
        },

    },
    methods: {
        retroceder(){
            if(this.atras && this.pathAnterior !== ""){
                this.$router.replace(this.pathAnterior)
            }
        }
    }
}
</script>

<style>

.menu{
    height: 7vh;
}

.atras{
    cursor: pointer;
}

</style>