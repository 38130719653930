<template>
  <b-modal
      content-class="modal-container"
      id="modal-sesion-expira"
      ref="modalSesionExpira"
      ok-only
      align="center"
      :hide-footer="true"
      :hide-header-close="true"
      no-close-on-backdrop
    >
      <b-container fluid>
        <b-row class="mb-1">
          <img src="@/assets/img/negado.svg" alt="img-negado" width="60" height="60" />
          <br/>
          <br/>
          <br/>
          <br/>
          <b-col>
            <p class="modal-text-color"></p>
            Sesión caducada por inactividad.
            </b-col>
        </b-row>
      </b-container>
    </b-modal>
</template>

<script>
export default {
  props: {
   mostrar: {
     type: Boolean,
     default: false,
   },
  },
  methods: {
    abrirModal() {
      this.$bvModal.show("modal-sesion-expira");
    },
  },
  mounted() {
    if (this.mostrar) {
      this.abrirModal();
    }
  },
  watch: {
    mostrar(value) {
      if (value) {
        this.abrirModal();
      }
    },
  },
}
</script>

<style scoped>

.modal-error{
  z-index: 9999;
}
/deep/ .modal{
  z-index: 9999;
}
/deep/ .modal-mask {
  position: fixed;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(248, 248, 248, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
/deep/ .modal-backdrop {
  z-index: -1;
  background-color: #fff;
  opacity: 0.9;
  -webkit-filter: blur(8px);
  -moz-filter: blur(8px);
  -o-filter: blur(8px);
  -ms-filter: blur(8px);
  filter: blur(8px);
}
/deep/ .modal-container {
  width: auto;
  max-width: 350px;
  margin-top: 100px;
  background-color: #ffffff8c;
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  border: none;
}
/deep/ .modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border: none;
}
/deep/ .modal-header .close {
  border: none;
  color: #0181c4;
  background: white;
  font-size: 44px;
}
/deep/ .modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  align-self: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border: none;
  margin-top: 80px;
}
.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}
.modal-body {
  margin: 20px 0 80px 0;
}
.modal-default-button {
  float: right;
}
.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 0;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
/deep/ .modal-body {
  position: relative;
  font-size: 21px;
  color: #0181c4;
  margin: 0 0 62px 0;
}
/deep/ .btn-primary:hover {
  color: #fff;
  background-color: #004b8d;
  border-color: #007bff;
  border-radius: 50px;
}
/deep/ .btn-primary {
  color: #fff;
  background-color: #004b8d;
  border-color: #007bff;
  border-radius: 50px;
}

.btn-primary:hover {
  color: #fff;
  background-color: #004b8d;
  border-color: #007bff;
  border-radius: 50px;
}
.btn-primary {
  color: #fff;
  background-color: #004b8d;
  border-color: #007bff;
  border-radius: 50px;
}

.icon-warning {
  margin: 3rem 0;
}
</style>