<template>
  <div id="app">
    <router-view />
    <ModalSesion :mostrar="mostrarModalSesion" />
  </div>
</template>

<script>

import Cookies from "js-cookie"
import ModalSesion from './views/genericos/ModalSesion.vue'
import { obtenerRuta } from './utils/url'

export default {
  name: "App",
  components: {
    ModalSesion,
  },
  data: () => ({
    mostrarModalSesion: false,
  }),
  computed: {
    isIdle() {
      return this.$store.state.idleVue.isIdle;
    },
  },
  watch: {
    isIdle(value) {
      if(value && ( obtenerRuta() !== "/" )){
        Cookies.remove("usuario");
        Cookies.remove("token");
        this.mostrarModalSesion = true
      }
    }
  },
  mounted(){
    const ruta = obtenerRuta()
    if(ruta.includes('-')){
      this.$router.replace({
        path: "/",
        query: {
          d: ruta.replace('/', ''),
        },
      })
    }
  }
}
</script>

<style scoped>

</style>
