<template>
  <body>
    <div class="container">
      <div class="row">
        <div class="col-md-5 mx-auto">
          <div id="first">
            <div class="myform form" style="margin-top: 1%">
              <div class="logo mb-3">
                <div class="col-md-12 text-center pl-0 pr-0 background">
                  <img
                    src="@/assets/img/negado.svg"
                    alt="img-negado"
                    width="60"
                    height="60"
                  />
                </div>
                <br />
                <div class="form-group">
                  <div class="logo mb-3 pl-0 pr-0 pt-3 colorb center">
                    Por tu seguridad esta<br />
                    página ya no está<br />
                    activa.<br /><br />
                    Acércate con tu <br />
                    asesor para obtener<br />
                    un nuevo link
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </body>
</template>
<script>
export default {};
</script>
<style scoped>

.center{
  text-align: center;
}
</style>
