import axios from 'axios'

const URL_SERVER_FAD = process.env.VUE_APP_FAD_API

export const obtenerToken = () => {
 return axios.post(`${URL_SERVER_FAD}/authorization-server/oauth/token`,
  new URLSearchParams({
   grant_type: process.env.VUE_APP_FAD_API_TOKEN_GRANT_TYPE,
   username: process.env.VUE_APP_FAD_API_TOKEN_USERNAME,
   password: process.env.VUE_APP_FAD_API_TOKEN_PASS
  }))
}

export const obtenerTiket = (xml, pdf, hash, token) => {
  const formData = new FormData();
  formData.append('xml', xml)
  formData.append('pdf', pdf)
  formData.append('hash', hash)
  const config = {
    headers: {
        'content-type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
    }
}
  return axios.post(`${URL_SERVER_FAD}/requisitions/createRequisitionB2C`, formData, config )
}