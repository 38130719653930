<template>
  <div>
   <Menu :cliente="true" :atras="false" />
   <ModalSesion :mostrar="mostrarModalSesion" />
   <iframe class="body" id="iframe-validation" title="iframe-result" sandbox="allow-scripts allow-same-origin allow-modals" />
   <b-modal
      content-class="modal-container"
      id="modal-error-ine-validacion"
      ok-only
      ok-title="Intentar nuevamente"
      align="center"
      @close="onClose"
      @ok="onClose"
      :hide-header-close="true"
      no-close-on-backdrop
    >
      <b-container fluid>
        <b-row class="mb-1">
          <img
            src="@/assets/img/icn-70-px-general-alert.svg"
            alt="img-general"
            width="60"
            height="60"
          />
          <br />
          <br />
          <br />
          <b-col>
            <p class="modal-text-color">{{ textError }}</p>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </div>
</template>

<script>

import Menu from '../genericos/Menu.vue'
import { mapGetters } from 'vuex'
import ModalSesion from '../genericos/ModalSesion.vue'
import Cookies from 'js-cookie'
import { cancelarFolio } from '../../services/prestapro'
import { cancelarLiga } from '../../services/tcs'

export default {
  components:{
   Menu,
   ModalSesion
  },
  computed: {
   ...mapGetters(['obtenerPrestaPro'])
  },
  data: function () {
    return {
      mostrarModalSesion: false,
      textError: "",
      store: null,
      indentificationData: {},
      errorTimeout: false
    }
  },
  methods: {
   initIframe(){
    const obj = this.indentificationData
    const iframe = document.getElementById("iframe-validation");
    iframe.src = "https://uatapiweb.firmaautografa.com/";
    iframe.onload = () => {
      iframe.contentWindow.postMessage(new ResponseEvent(EventModule.PROCESS_INIT, {
        user: USER,
        identificationData: obj,
      }
    ), iframe.src);
    };
   },
   generarPostMessage(){
    window.addEventListener("message", async (message) => {
      if (message.origin.includes("firmaautografa.com")) {
        if (message.data.event === EventModule.PROCESS_ERROR) {
          this.textError = message.data.data.error
          this.$bvModal.show("modal-error-ine-validacion")
        } else if(message.data.event === EventModule.SERVICE_RESULT){
          if(!message.data.data){
            await cancelarFolio(this.obtenerPrestaPro.folio, "Identificación oficial no válida.").then(r=>r).catch(e=>e)
            await cancelarLiga({ curp: this.obtenerPrestaPro.curp.toUpperCase(), id: this.obtenerPrestaPro.uid }).then(r => r).catch(e => e)
            Cookies.remove("usuario");
            Cookies.remove("token");
            this.mostrarModalSesion = true
          }
        } else if(message.data.event === EventModule.PROCESS_COMPLETED){
          this.$router.replace('/prestapro/motorIne')
        }
      } else return;
    });
   },
   onClose(){
    this.$router.go(this.$router.currentRoute)
   }
  },
  mounted() {
    this.indentificationData = null
    this.indentificationData = {
      documentNumber: this.obtenerPrestaPro.ocr.documentNumber,
      backNumber: this.obtenerPrestaPro.ocr.backNumber,
      personalNumber: this.obtenerPrestaPro.ocr.personalNumber,
      verificationNumber: this.obtenerPrestaPro.ocr.verificationNumber === undefined ? null : this.obtenerPrestaPro.ocr.verificationNumber,
    }
    window.removeEventListener("message", ()=>{}, true)
    this.initIframe()
    this.generarPostMessage()
    this.store = this.obtenerPrestaPro
  },
  beforeMount(){
    if(!Cookies.get('token')){
      this.mostrarModalSesion = true
    }
  }
}

const USER = {
  username: process.env.VUE_APP_FAD_VALIDACION_USERNAME,
  password: process.env.VUE_APP_FAD_VALIDACION_PASS,
}

const EventModule = {
  PROCESS_INIT: "INIT_MODULE",
  PROCESS_ERROR: "PROCESS_ERROR",
  PROCESS_COMPLETED: "PROCESS_COMPLETED",
  SERVICE_RESULT: "SERVICE_RESULT"
}

class ResponseEvent {
  event;
  data;
  constructor(event, data) {
    this.event = event;
    this.data = data;
  }
}

</script>

<style scoped>
.store{
 position: sticky;
 z-index: 100;
}
.body{
  overflow:hidden;
  overflow-x:hidden;
  overflow-y:hidden;
  height:90%;
  width:100%;
  position:absolute;
  top:0px;
  left:0px;
  right:0px;
  bottom:0px;
  margin-top: 80px;
}

/deep/ .modal{
  z-index: 9999;
}
/deep/ .modal-mask {
  position: fixed;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(248, 248, 248, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
/deep/ .modal-backdrop {
  z-index: -1;
  background-color: #fff;
  opacity: 0.9;
  -webkit-filter: blur(8px);
  -moz-filter: blur(8px);
  -o-filter: blur(8px);
  -ms-filter: blur(8px);
  filter: blur(8px);
}
/deep/ .modal-container {
  width: auto;
  max-width: 350px;
  background-color: #ffffff8c;
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  border: none;
}
/deep/ .modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border: none;
}
/deep/ .modal-header .close {
  border: none;
  color: #0181c4;
  background: white;
  font-size: 44px;
}
/deep/ .modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  align-self: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border: none;
  margin-top: 120px;
}
.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}
.modal-body {
  margin: 20px 0 80px 0;
}
.modal-default-button {
  float: right;
}

.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 0;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
/deep/ .modal-body {
  position: relative;
  font-size: 21px;
  color: #0181c4;
  margin: 0 0 62px 0;
  margin-top: 80px;
}
/deep/ .btn-primary:hover {
  color: #fff;
  background-color: #004b8d;
  border-color: #007bff;
  border-radius: 50px;
}
/deep/ .btn-primary {
  color: #fff;
  background-color: #004b8d;
  border-color: #007bff;
  border-radius: 50px;
}

.btn-primary:hover {
  color: #fff;
  background-color: #004b8d;
  border-color: #007bff;
  border-radius: 50px;
}
.btn-primary {
  color: #fff;
  background-color: #004b8d;
  border-color: #007bff;
  border-radius: 50px;
}

.icon-warning {
  margin: 3rem 0;
}

</style>