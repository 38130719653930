import axios from 'axios'
import { generarToken } from '../services/autorizacion'

axios.interceptors.request.use(
    async (requestConfig) => {
        if(requestConfig.url.includes("firmaautografa")){
            if(requestConfig.url.includes("token")){
                requestConfig.headers['Authorization'] = "Basic ZmFkOmZhZHNlY3JldA==";
                return requestConfig;
            }else{
                return requestConfig;
            }
        }else{
            if (requestConfig.url.includes("oauth2/token")) {
                return requestConfig;
            }
            if( requestConfig.url.includes("notificacion")){
                const TIMEOUT = process.env.VUE_APP_TIMEOUT_TCS;
                requestConfig.timeout = TIMEOUT * 1000
            }
            if( requestConfig.url.includes("api.qa.profuturo.mx") ){
                return requestConfig
            }
            const resulToken = await generarToken().then(r => r).catch(e => e)
            const accessToken = resulToken.data.access_token
            const tokenLocal = `Bearer ${accessToken}`;
            requestConfig.headers['Authorization'] = tokenLocal;
            return requestConfig;
        }
    },
    (errorToken) => {
        return Promise.reject(errorToken);
    }
);

axios.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        let err = {}
        if (error.code === 'ECONNABORTED') {
            err = {
                codigo: 408,
                descripcion: "Error de conexión, inténtelo más tarde",
                mostrarModal: true
            }
        }
        if ( error.response ) {
            const { response : { status, data : { error: { descripcion } } } } = error
            err = {
                codigo: status,
                descripcion: descripcion,
                mostrarModal: true
            }
        }
        return Promise.reject(err)
    },
)

export default axios