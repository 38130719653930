<template>
  <body>
    <Menu :cliente="true" :atras="false" />
    <ModalSesion :mostrar="mostrarModalSesion" />
    <div class="container center">
      <form action="#" name="registration" @submit.prevent="verificar">
        <div class="form-group">
          <div class="logo mb-3 pl-0 pr-0 pt-3 colorb">
            <br />
            <label>Documento a capturar</label>
            <br />
          </div>
        </div>
        <div class="col-md-12 text-center mb-3 pt-4">
          <img class="img-responsive" src="@/assets/img/icn_ineFrente.svg" alt="img-ine-frente" />
          <br /><br /><label class="colorbo">INE/IFE</label>
        </div>
        <br />
        <br />
        <div class="col-md-12 div-img">
          <img class="" src="@/assets/img/icn-45-px-camara.svg" alt="img-captura" @click="Call" />
        </div>
        <br />
        <br />
        <br /><br /><br /><br />
        <div class="form-group"></div>
      </form>
    </div>
  </body>
</template>

<script>

import Menu from '../genericos/Menu.vue'
import Cookies from 'js-cookie';
import ModalSesion from '../genericos/ModalSesion.vue'

export default {
  name: "BusquedaProspecto",
  components:{
    Menu,
    ModalSesion
  },
  data: () => ({
    mostrarModalSesion: false,
  }),
  methods: {
    Call() {
      this.$router.replace('/prestapro/ineFrente')
      // this.$router.replace('/prestapro/_29_1_comprobante_de_pago')
    },
    
  },
  beforeMount(){
    if(!Cookies.get('token')){
      this.mostrarModalSesion = true
    }
  }
};
</script>

<style scoped>

.center{
  text-align: center;
}

.img-camara{
  cursor: pointer;
}

.div-img{
  cursor: default;
}
</style>
