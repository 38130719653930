
import axios from "axios";

export const generarTokenQA = () => {
    return axios.post(`${process.env.VUE_APP_ENDPOINT_API_QA_TOKEN}`, "grant_type=client_credentials", { headers: { "Authorization": `Basic ${process.env.VUE_APP_BASIC_AUTH_TOKEN}`}})
}

export const registrarCurpMau = async (body, curp) => {
    const token = await generarTokenQA().then(r => r)
    const headers = {
        'Authorization': `Bearer ${token.data.access_token}`
    }
    return axios.post(`${process.env.VUE_APP_END_POINT_API_QA}/mau/1/gu/usuarios/${curp}/perfil/curp`, body, {
        headers: headers,
    })
}

export const generarPToken = async () => {
    const token = await generarTokenQA().then(r => r)
    const headers = {
        'Authorization': `Bearer ${token.data.access_token}`
    }
    return axios.post(`${process.env.VUE_APP_END_POINT_API_QA}/mau/1/ga/autenticacion/identificador`, {}, {
        headers: headers
    })
}