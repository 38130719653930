<template>
  <div>
    <Menu :cliente="true" :atras="false" />
    <ModalError :mostrar="mostrarModalError" :descripcion="descripcionError" />
    <ModalSesion />
    <iframe
      title="Autenticacion"
      class="body"
      id="iframe-mau"
      width="100%"
      allow="camera *"
      :src='url'
    ></iframe>
  </div>
</template>

<script>
import Menu from "../genericos/Menu.vue";
import { mapGetters } from "vuex";
import ModalError from "../genericos/ModalError";
import ModalSesion from '../genericos/ModalSesion.vue'
import { generarPToken, registrarCurpMau } from "../../services/mau";
import Cookies from "js-cookie";

export default {
  components: {
    Menu,
    ModalError,
    ModalSesion
  },
  computed: {
    ...mapGetters(["obtenerPrestaPro"]),
  },
  data: function() {
    return {
      mostrarModalSesion: false,
      respuesta: null,
      curp: "",
      token: "",
      mostrarModalError: false,
      descripcionError: null,
      url: ""
    };
  },
  async mounted() {
    this.curp = this.obtenerPrestaPro.curp
    this.mostrarModalError = true
    this.descripcionError = "loadspin"
    const body = {
      "datosPersona": {
        "usuario": "usuario",
        "idBuc": "0",
        "origen": {
          "id": "83"
        }
      },
      "datosProspecto": {
        "nombre": this.obtenerPrestaPro.primerNombre,
        "apellidoPaterno": this.obtenerPrestaPro.apellidoPaterno,
        "apellidoMaterno": this.obtenerPrestaPro.apellidoMaterno
      }
    }
    const respuesta = await registrarCurpMau( body, this.curp ).then(r=>r)
    console.log("RESPUESTA", respuesta)
    if(respuesta.data.curp === this.curp){
      const respuestaPToken = await generarPToken().then(r=>r)
      this.token = respuestaPToken.data.identificador
      this.initIFrame()
      this.mostrarModalError = false
      this.descripcionError = null
      window.addEventListener("message", () => {
      });
    }
  },
  methods: {
    initIFrame(){
      this.url = `https://motor-autenticacion-universal-ssl-autenticacionuniversal-dev.apps.paasprofuturo-d.r6b1.p1.openshiftapps.com/autenticacion?curp=${this.curp}&env=dev&ptoken=${this.token}&rftoken=&cuenta=`;
    }
  },
  beforeMount(){
    if(!Cookies.get('token')){
      this.mostrarModalSesion = true
    }
  }
};
</script>

<style scoped>
.body {
  margin-top: 50px;
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: hidden;
  height: 93.5%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}
</style>