import axios from "../plugins/axios";
import Cookies from "js-cookie";
import axiosToken from "axios"

const URL_SERVER = process.env.VUE_APP_END_POINT_API;

export const generarToken = () => {
    return axiosToken.post(`${URL_SERVER}/oauth2/token`, "grant_type=client_credentials", { headers: { "Authorization": `Basic ${process.env.VUE_APP_TOKEN_API}` }});
}

export const login = (username, password) => {
    const user = { username: username, password: password };
    return axios.post(`${URL_SERVER}/prestamos/1/gestion-tramites/usuario`, user);
}

export const esAutorizado = () => {
    return !!Cookies.get("token");
}

export const definirUsuario = (usuario) => {
    Cookies.set("usuario", usuario);
}

export const token = () => {
    return Cookies.get("token");
}

export const postalCode = (cp) => {
    return axios.get(`${URL_SERVER}/prestamos/1/gestion-tramites/direccion`, { params: { cp: cp } })
}