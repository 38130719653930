import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import axios from './plugins/axios'
import VueAxios from 'vue-axios'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faChevronRight, faChevronDown, faChevronLeft, faArrowLeft, faSyncAlt } from '@fortawesome/free-solid-svg-icons'
import IdleVue from 'idle-vue'

import store from './store'

library.add(faArrowLeft, faChevronRight, faChevronDown, faChevronLeft, faSyncAlt)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false
const eventsHub = new Vue();
const options = {
  eventEmitter: eventsHub,
  store,
  idleTime: parseInt(process.env.VUE_APP_TIME_EXPIRATION) * 60 * 1000, // 900 seconds,
  startAtIdle: false
}
Vue.use( BootstrapVue, IconsPlugin, VueAxios, axios )
Vue.use( IdleVue, options )

import "bootstrap/dist/css/bootstrap.css"
import "bootstrap-vue/dist/bootstrap-vue.css"
import "../src/assets/css/profuturo-styles.css"

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')