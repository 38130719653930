<template>
  <body>
    <Menu :cliente="true" :pathAnterior="'/prestapro/ingresaDireccion'"/>
    <ModalSesion :mostrar="mostrarModalSesion" />
    <div class="container">
      <div class="row">
        <div class="col-md-5 mx-auto">
          <div id="second">
            <div class="myform form ">
              <form
                action="#"
                name="registration"
                @submit.prevent="DatosContactos"
              >
                <div class="form-group">
                  <div class="flex-row">
                    <div class="p-2">
                      <label class="colorb"
                        >Ingresa tus datos de contacto</label
                      >
                      <br />
                    </div>
                  </div>
                  <br/>
                  <div class="form-group">
                      <label class="left">Celular:</label>
                       <input
                        type="text"
                        name="cel"
                        class="form-control"
                        id="celular"
                        maxlength="10"
                        minlength="10"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        @blur="validarCelular"
                        v-model="celular"
                        @keypress="validarNumero"
                      />
                  </div>
                </div>
                <div :class="estiloCelular" align="right">
                  {{ textoCelular }}
                </div>
                <br/>
                <div class="form-group">
                    <div><Tooltip :texto="'Tienes opción de agregar tu Extensión teléfonica.'" :enlace="'tooltip-telefono-fijo'" /></div>
                    <label class="left">Teléfono fijo:</label>
                    <div class="input-group">
                      <input
                        type="text"
                        name="telefonoFijo"
                        class="form-control input-tooltip-space"
                        id="telefonoFijo"
                        maxlength="20"
                        @blur="validarTelefonoFijo"
                        v-model="telefonoFijo"
                        @keypress="validarCaracteresEspeciales"
                      />
                      <img src="@/assets/img/exclamation-circle-solid.svg" id="tooltip-telefono-fijo" width="35" height="35" alt="tooltip-telefono-fijo" class="tooltip-input-rigth" />
                    </div>
                </div>
                  
                <div :class="estiloTelefonoFijo + ' margen-error'" align="right">
                  {{ textoTelefonoFijo }}
                </div>
                <br />
                <div class="form-group">
                    <label class="left">Correo electrónico:</label>
                    <input
                      type="email"
                      name="correo"
                      class="form-control"
                      id="correo"
                      maxlength="100"
                      @blur="validarCorreo"
                      @keypress="validarCaracteresEspeciales"
                      v-model="correo"

                    />
                </div>
                <div :class="estiloCorreo" align="right">
                  {{ textoCorreo }}
                </div>
                <br />
                <label class="colord" align="left"
                  >Utiliza tu correo electrónico actual, recibirás documentación
                  de tu préstamo</label
                >
                <br />
                <br />
                <div
                  class="form-group"
                  style="margin-right: 38px; margin-bottom: 8px;"
                >
                  <label class="label" align="left"
                    >Medio para el envío del estado de cuenta:</label
                  >
                  <br /><br />
                  <div align="left">
                    <b-form-group >
                      <b-form-radio id="envio-documento" v-model="envio" name="envio-documento" value="correoElectronico">&nbsp; Correo electrónico</b-form-radio>
                      <b-form-radio id="envio-documento" v-model="envio" name="senvio-documento" value="domicilio">&nbsp;&nbsp;Envío a domicilio</b-form-radio>
                    </b-form-group>
                  </div>

                </div>
                <br />
                <div class="col-md-12 text-center mb-3 pt-4">
                    <button
                        id="btnContinuar"
                        type="submit"
                        @click="DatosContactos"
                        class="btn btn-block mybtn btn-primary"
                        :disabled="!continuar">
                        Continuar
                    </button>
                </div>
                <div class="col-md-12 "></div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </body>
</template>

<script>

import Menu from '../genericos/Menu.vue'
import { mapGetters } from 'vuex'
import { caracteresNoPermitidos, numeros } from '../../constants/cajaTexto'
import { eliminarCaracteresExpeciales, validarSoloNumeros } from '../../utils/formatoTexto'
import Cookies from 'js-cookie';
import ModalSesion from '../genericos/ModalSesion.vue'
import Tooltip from '../genericos/Tooltip.vue'

export default {
  components: {
    Menu,
    ModalSesion,
    Tooltip
  },
  computed: {
    ...mapGetters(['obtenerPrestaPro'])
  },
  data: () => ({
    mostrarModalSesion: false,
    celular: null,
    telefonoFijo: null,
    correo: null,
    estiloCelular: "",
    estiloTelefonoFijo: "",
    estiloCorreo: "",
    textoCelular: null,
    textoTelefonoFijo: null,
    textoCorreo: null,
    habilitarCelular: false,
    habilitarTelefonoFijo: false,
    habilitarCorreo: false,
    continuar: false,
    envio: "correoElectronico"
  }),
  watch: {
    celular( value ){
      this.celular = validarSoloNumeros(value)
      this.validarCelular()
    },
    telefonoFijo( value ){
      this.telefonoFijo = eliminarCaracteresExpeciales(value)
      this.validarTelefonoFijo()
    },
    correo( value ){
      this.correo = eliminarCaracteresExpeciales(value)
      this.validarCorreo()
    }
  },
  methods: {
    DatosContactos() {
      const datosContacto = { 
        celular: this.celular,
        telefono: this.telefonoFijo,
        tipoEnvio: this.envio === "correoElectronico" ? "CORREO" : "DOMICILIO"
      }
      this.$store.commit('agregarValorPrestaPro', datosContacto)
      this.$router.replace("/prestapro/_30_encuesta");

    },
    validarNumero($e){
      if(!numeros.includes($e.charCode)){
        $e.preventDefault()
      }
    },
    validarCaracteresEspeciales($e){
        if(caracteresNoPermitidos.includes($e.charCode)){
          $e.preventDefault()
        }
    },
    validarContinuar() {
      if(this.habilitarCelular && this.habilitarTelefonoFijo && this.habilitarCorreo){
        this.continuar = true
      } else {
        this.continuar = false
      }
    },
    validarCelular(){
      if( this.celular === null || this.celular === "" ){
        this.estiloCelular = "showError"
        this.textoCelular = "Dato Obligatorio"
        this.habilitarCelular = false
      } else {
        const expresionCelular = /^\d{10}$/
        if( expresionCelular.test( this.celular ) ){
          this.estiloCelular = ""
          this.textoCelular = null
          this.habilitarCelular = true
        } else {
          this.estiloCelular = "showError"
          this.textoCelular = "Dato Incorrecto"
          this.habilitarCelular = false
        }
      }
      this.validarContinuar()
    },
    validarTelefonoFijo() {
      if( this.telefonoFijo === null || this.telefonoFijo === "" ){
        this.estiloTelefonoFijo = "showError"
        this.textoTelefonoFijo = "Dato Obligatorio"
        this.habilitarTelefonoFijo = false
      } else {
        this.telefonoFijo = this.telefonoFijo.toUpperCase()
        this.estiloTelefonoFijo = ""
        this.textoTelefonoFijo = null
        this.habilitarTelefonoFijo = true
      }
      this.validarContinuar()
    },
    validarCorreo() {
      if( this.correo === null || this.correo === "" ){
        this.estiloCorreo = "showError"
        this.textoCorreo = "Dato Obligatorio"
        this.habilitarCorreo = false
      } else {
        if( this.correo.includes("@") ){
          this.estiloCorreo = ""
          this.textoCorreo = null
          this.habilitarCorreo = true
        } else {
          this.estiloCorreo = "showError"
          this.textoCorreo = "Dato Incorrecto"
          this.habilitarCorreo = false
        }
      }
      this.validarContinuar()
    }
  },
  mounted() {
    const telefonoStore = this.obtenerPrestaPro.celular
    const correoStore = this.obtenerPrestaPro.correo
    if(telefonoStore !== undefined && telefonoStore !== ""){
      this.celular = telefonoStore
      if(this.celular.length === 10){
        this.habilitarCelular = true
      }else{
        this.habilitarCelular = false
        this.estiloCelular = "showError"
        this.textoCelular = "Dato Incorrecto"
      }
      
    }
    if(correoStore !== undefined && correoStore !== ""){
      this.correo = correoStore
      this.habilitarCorreo = true
    }
  },
  beforeMount(){
    if(!Cookies.get('token')){
      this.mostrarModalSesion = true
    }
  }
};
</script>

<style scoped>
body {
  height: 100%;
  margin: 0;
  padding-top: 4.2rem;
  padding-bottom: 4.2rem;
  background: #fff;
}

img {
  max-width: 100%;

  max-height: 100%;
}
a {
  text-decoration: none !important;
}

.btn-primary {
  color: #fff;
  background-color: #004b8d;
  border-color: #007bff;
}

.bg-light {
  background-color: #ffffff !important;
  box-shadow: -2px -2px 6px black, 2px -2px 6px black;
}
.colorf {
  font-size: 23px;
  color: #004b8d;
}
.colorc {
  font-size: 16px;
  color: #004b8d;
}
.icon-color {
  color: #004b8d;
}
.navbar-light .navbar-brand {
  color: rgb(4 70 131);
}
.myform {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  padding: 1rem;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgb(255, 255, 255);
  border-radius: 1.1rem;
  outline: 0;
  max-width: 500px;
}
.tx-tfm {
  text-transform: uppercase;
}
.mybtn {
  border-radius: 50px;
}

.login-or {
  position: relative;
  color: #aaa;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.span-or {
  display: block;
  position: absolute;
  left: 50%;
  top: -2px;
  margin-left: 25px;
  background-color: #fff;
  width: 50px;
  text-align: center;
}
.hr-or {
  height: 1px;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.google {
  color: #666;
  width: 100%;
  height: 40px;
  text-align: center;
  outline: none;
  border: 1px solid lightgrey;
}
form .error {
  color: #ff0000;
}
.buttonbar {
  visibility: hidden;
}

.responsepros {
  color: #0181c4;
}
.marginl {
  margin-left: 11px;
}

.imagenav {
  width: 20%;

  margin-left: 5%;
}
.colorb {
  color: #004b8d;
  font-size: 23px;
}
.colord {
  color: #004b8d;
  font-size: 18px;
}

.left{
    display: flex;
    padding-left: 5px;
}

.tooltip-input{
  display: flex;
  float: left;
  margin-top: 3px;
}
.tooltip-input-rigth{
  margin: 1px;
  margin-left: 10px;

}
.input-tooltip-space{
  margin-right: 3px;
}

</style>
