<template>
  <body>
    <Menu :cliente="true" :atras="false" />
    <ModalSesion :mostrar="mostrarModalSesion" />
    <div class="container">
      <div class="row">
        <div class="col-md-5 mx-auto">
          <div id="second">
            <div class="myform form">
              <form action="#" name="registration" @submit.prevent="() => {}">
                <div class="form-group">
                  <div class="flex-row">
                    <div class="p-2">
                      <label class="colorb"
                        >Valida los documentos contractuales</label
                      >
                      <br />
                    </div>
                  </div>
                </div>
                <br />
                <ul>
                  <li
                    v-for="(item, index) in documentosArray"
                    :key="index"
                    class="li-documento"
                  >
                    <label class="punto-li">&#8226;</label>
                    <label
                      :class="'label-li'"
                      >{{ item.documento }}</label
                    >
                  </li>
                </ul>
                <br />
                <div class="col-md-12 text-center mb-3 pt-4">
                  <button
                    type="submit"
                    class="btn btn-block mybtn btn-primary"
                    @click="redirect"
                    :disabled="!continuar"
                  >
                    Continuar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </body>
</template>

<script>
import { mapGetters } from "vuex";
import Menu from "../genericos/Menu.vue";
import Cookies from "js-cookie";
import ModalSesion from "../genericos/ModalSesion.vue";

const arrayDocumentos = [
  {
    id: 9,
    documento: "Solicitud de crédito",
  },
  {
    id: 1,
    documento: "Carta de buró de crédito",
  },
  {
    id: 2,
    documento: "Contrato y tabla de amortización",
  },
  {
    id: 3,
    documento: "Pagaré",
  },
  {
    id: 105,
    documento: "Aviso de privacidad",
  },
];

export default {
  name: "DocumentosContractuales",
  computed: {
    ...mapGetters(["obtenerPrestaPro"]),
  },
  components: {
    Menu,
    ModalSesion,
  },
  data: () => ({
    mostrarModalSesion: false,
    documentosArray: [],
    continuar: true,
  }),
  mounted() {
    this.documentosArray = arrayDocumentos.map((d) => {
      return {
        id: d.id,
        documento: d.documento,
      };
    });
  },
  methods: {
    redirect() {
      this.$router.replace("/prestapro/documentos-contractuales")
    },
  },
  beforeMount() {
    if (!Cookies.get("token")) {
      this.mostrarModalSesion = true;
    }
  },
};
</script>

<style scoped>
.disabled {
  pointer-events: none;
}
.punto-li {
  font-size: 25px;
  margin-top: -5px;
  margin-right: 1em;
  float: left;
  color: #0181c4;
}

.check-li {
  float: right;
}

.label-li {
  float: left;
  color: #0181c4;
  cursor: default;
}

.li-documento {
  display: flow-root;
}

.disabled {
  cursor: not-allowed;
  color: gray;
}
</style>
