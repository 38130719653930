<template>
  <div>
   <Menu :cliente="true" :atras="false" />
   <ModalSesion :mostrar="mostrarModalSesion" />
   <iframe class="body" id="fad-iframe-acuant-frente" sandbox="allow-scripts allow-same-origin" allow="camera *" title="OCR" />
   <b-modal
      content-class="modal-container"
      id="modal-error-ine-frente"
      ok-only
      ok-title="Intentar nuevamente"
      align="center"
      @close="onClose"
      @ok="onClose"
      :hide-header-close="true"
      no-close-on-backdrop
    >
      <b-container fluid>
        <b-row class="mb-1">
          <img
            src="@/assets/img/icn-70-px-general-alert.svg"
            alt="img-general"
            width="60"
            height="60"
          />
          <br />
          <br />
          <br />
          <b-col>
            <p class="modal-text-color">{{ textError }}</p>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
    <b-modal
      content-class="modal-container"
      id="modal-info-fad-ine"
      ok-only
      ok-title="Continuar"
      align="center"
      @close="onCloseInfo"
      @ok="onCloseInfo"
      :hide-header-close="true"
      no-close-on-backdrop
    >
      <b-container fluid>
        <b-row class="mb-1">
          <img
            src="@/assets/img/icn_info.svg"
            alt="img-general"
            width="60"
            height="60"
          />
          <br />
          <br />
          <br />
          <b-col>
            <p class="modal-text-color">{{ textoInfo }}</p>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </div>
</template>

<script>

import Menu from '../genericos/Menu.vue'
import { mapGetters } from 'vuex'
import ModalSesion from '../genericos/ModalSesion.vue'
import Cookies from 'js-cookie'

export default {
  components:{
   Menu,
   ModalSesion
  },
  computed: {
   ...mapGetters(['obtenerPrestaPro'])
  },
  data: function () {
    return {
      mostrarModalSesion: false,
      tipoIne: 0,
      textError: "",
      textoInfo: ""
    }
  },
  methods: {
   onClose(){
    this.$router.go(this.$router.currentRoute)
   },
   onCloseInfo(){
    // get iframe
    const iframe = document.getElementById("fad-iframe-acuant");
    // url - https://devapiframe.firmaautografa.com/fad-iframe-acuant
    const url = `https://devapiframe.firmaautografa.com/fad-iframe-acuant?user=${process.env.VUE_APP_FAD_OCR_CREDENTIALS_LOAD_USERNAME}&pwd=${process.env.VUE_APP_FAD_OCR_CREDENTIALS_LOAD_PASSWORD}`;
    // set src to iframe
    iframe.src = url;
   },
   initIframe() {
      const iframe = document.getElementById("fad-iframe-acuant");
      iframe.contentWindow.postMessage(
        new ResponseEvent(EVENT_MODULE.INIT_MODULE, {
          credentials: CREDENTIALS,
          configuration: CONFIGURATION,
          side: this.tipoIne, // 0 - front id, 1 - back id
          idData: true, // true - ocr, false - without this data
          idPhoto: true, // true - get imaghen face of id, false - without this data
          imageQuality: 0.5, // quality of image id, range 0 - 1
        }),
        iframe.src
      );
      // const iframe = document.getElementById("fad-iframe-acuant-frente");
      // const url = "https://apiiduat.firmaautografa.com/";
      // iframe.src = url;
      // iframe.onload = () => {
      //   iframe.contentWindow.postMessage(
      //     new ResponseEvent(EVENT_MODULE.INIT_MODULE, {
      //       credentials: CREDENTIALS,
      //       legends: LEGENDS_FRENTE,
      //       side: this.tipoIne, 
      //       idData: false, 
      //       idPhoto: false, 
      //       imageQuality: 0.5,
      //     }), iframe.src);
      // };
   },
   initIframeReverso() {
      // const iframe = document.getElementById("fad-iframe-acuant-frente");
      // const url = "https://apiiduat.firmaautografa.com/";
      // iframe.src = url;
      // iframe.onload = () => {
      //   iframe.contentWindow.postMessage(
      //     new ResponseEvent(EVENT_MODULE.INIT_MODULE, {
      //       credentials: CREDENTIALS,
      //       legends: LEGENDS_REVERSO,
      //       side: this.tipoIne, 
      //       idData: true, 
      //       idPhoto: false,
      //       imageQuality: 0.5,
      //       documentInstance: sessionStorage.getItem('documentInstance')
      //     }), iframe.src);
      // };
   }
  },

  mounted() {
    this.tipoIne = 0
    this.textoInfo = "Captura el frente de tu identificación"
    this.$bvModal.show("modal-info-fad-ine")
    // subscribe to message event to recive the events from the iframe
    window.addEventListener("message", (message) => {
      // IMPORTANT: check the origin of the data
      if (message.origin.includes("firmaautografa.com")) {
        if (message.data.event === EVENT_MODULE.MODULE_READY) {
          // MODULE_READY
          if(this.tipoIne === 0){
            this.initIframe()  
          }else if(this.tipoIne === 1){
            this.initIframeReverso()
          }
        }
        if (message.data.event === EVENT_MODULE.PROCESS_ERROR) {
          // PRROCESS_ERROR
          console.log(message.data.data);
          if (message.data.data.code === ERROR_CODE.UNSUPPORTED_CAMERA) {
            this.textError = 'Cámara no soportada, intenta en otro dispositivo'
          } else if (message.data.data.code === ERROR_CODE.FAIL_INITIALIZATIO) {
            this.textError = message.data.data.error
          } else {
            this.textError = message.data.data.error
          }
        } else if (message.data.event === EVENT_MODULE.PROCESS_COMPLETED) {
          const result = new Result(message.data.data);
          alert("RESULTADO -> " + result);
          // PROCESS_COMPLETED
          if(this.tipoIne === 0){
            alert("IMAGE -> " + message.data.data.id.image.data);
            this.$store.commit('agregarValorPrestaPro', {imgFrente: message.data.data.id.image.data})
            sessionStorage.setItem('documentInstance', message.data.data.documentInstance)
            this.textoInfo = "Captura el reverso de tu identificación"
            this.$bvModal.show("modal-info-fad-ine")
            this.tipoIne = 1
          } else if(this.tipoIne === 1) {
            this.$store.commit('agregarValorPrestaPro', {ocr: message.data.data.idData.ocr})
            this.$router.replace('/prestapro/ineFoto')
          }
          console.log(message.data.data);
          // show result example

          // save image front
          sessionStorage.setItem("idFront", message.data.data.id.image.data);

          // const containerResult = document.getElementById("container-result");
          // const containerIframe = document.getElementById(
          //   "container-iframe-acuant"
          // );
          // const imageId = document.getElementById("image-id");
          // const imageFace = document.getElementById("image-face");
          // const ocr = document.getElementById("ocr");
          // containerIframe.style.display = "none";
          // containerResult.style.display = "flex";
          // const result = new Result(message.data.data);
          // imageId.src = result.id.image.data;
          // imageFace.src = result.idPhoto;
          // ocr.innerHTML = JSON.stringify(result.idData.ocr);
        }
      } else return;
    });

    // this.tipoIne = 0
    // this.textoInfo = "Captura el frente de tu identificación"
    // this.$bvModal.show("modal-info-fad-ine")
    // window.addEventListener("message", (message) => {
    //   if (message.origin.includes("firmaautografa.com")) {
    //     if (message.data.event === EVENT_MODULE.PROCESS_ERROR) {
    //       if (message.data.data.code === ERROR_CODE.UNSUPPORTED_CAMERA) {
    //         this.textError = 'Cámara no soportada, intenta en otro dispositivo'
    //       } else if (message.data.data.code === ERROR_CODE.FAIL_INITIALIZATIO) {
    //         this.textError = message.data.data.error
    //       } else {
    //         this.textError = message.data.data.error
    //       }
    //       this.$bvModal.show("modal-error-ine-frente")
    //     } else if (message.data.event === EVENT_MODULE.PROCESS_COMPLETED) {
    //       if(this.tipoIne === 0){
    //         this.$store.commit('agregarValorPrestaPro', {imgFrente: message.data.data.id.image.data})
    //         sessionStorage.setItem('documentInstance', message.data.data.documentInstance)
    //         this.textoInfo = "Captura el reverso de tu identificación"
    //         this.$bvModal.show("modal-info-fad-ine")
    //         this.tipoIne = 1
    //       } else if(this.tipoIne === 1) {
    //         this.$store.commit('agregarValorPrestaPro', {ocr: message.data.data.idData.ocr})
    //         this.$router.replace('/prestapro/ineFoto')
    //       }
    //     }
    //   } else return;
    // })
  },
  beforeMount(){
    if(!Cookies.get('token')){
      this.mostrarModalSesion = true
    }
  }
}

// events available
const EVENT_MODULE = {
  INIT_MODULE: "INIT_MODULE",
  PROCESS_INIT: "PROCESS_INIT",
  PROCESS_ERROR: "PROCESS_ERROR",
  PROCESS_COMPLETED: "PROCESS_COMPLETED",
  MODULE_READY: "MODULE_READY",
};

// acuant credentials
const CREDENTIALS = {
  passiveUsername: process.env.VUE_APP_FAD_OCR_CREDENTIALS_PASSIVE_USERNAME,
  passivePassword: process.env.VUE_APP_FAD_OCR_CREDENTIALS_PASSIVE_PASSWORD,
  passiveSubscriptionId: process.env.VUE_APP_FAD_OCR_CREDENTIALS_PASSIVE_SUBSCRIPTION_ID,
  acasEndpoint: process.env.VUE_APP_FAD_OCR_CREDENTIALS_LIVENESS_ENDPOINT,
  livenessEndpoint: process.env.VUE_APP_FAD_OCR_CREDENTIALS_ACAS_ENDPOINT,
  assureidEndpoint: process.env.VUE_APP_FAD_OCR_CREDENTIALS_ASSUREID_ENDPOINT
};

// optional, the app has default legends and colors
const CONFIGURATION = {
  views: {
    instructions: true,
    preview: true
  },
  customization: {
    fadCustomization: {
      colors: {
        primary: "#A70635",
        secondary: "#A70635",
        tertiary: "#363636",
      },
      buttons: {
        primary: {
          backgroundColor: "#A70635",
          backgroundColorDisabled: "#dcdcdc",
          labelColor: "#ffffff",
          labelColorDisabled: "#8e8e8e",
          border: "1px solid #A70635",
        },
      },
      fonts: {
        title: {
          fontSize: '25px',
          fontFamily: 'system-ui'
        },
        subtitle: {
          fontSize: '17px',
          fontFamily: 'system-ui'
        },
        content: {
          fontSize: '15px',
          fontFamily: 'system-ui'
        },
        informative: {
          fontSize: '12px',
          fontFamily: 'system-ui'
        },
        button: {
          fontSize: '17px',
          fontFamily: 'system-ui'
        }
      }
    },

    moduleCustomization: {
      legends: {
        initializing: "iniciando",
        processing: "procesando",
        scan: {
          none: "ENFOCA TU ID SOBRE LA GUÍA",
          smallDocument: "ACERCATE MÁS",
          goodDocument: "",
          capturing: "CAPTURANDO ",
          tapToCapture: "TOCA LA PANTALLA PARA CAPTURAR",
        },
        manualCapture: {
          instruction: "Captura el frente de tu identificación",
          buttonNext: "Continuar",
        },

      },
      legendsInstructions: {
        title: 'Identificación',
        subtitle: 'Captura tu identifcación',
        buttonNext: 'Continuar'
      },
      legendsPreview: {
        title: 'Identificación',
        subtitle: 'Imagen de tu identificación',
        confirmation: '¿Los datos de tu identificación son legibles?',
        buttonNext: 'Sí, continuar',
        buttonRetry: 'Volver a capturar'
      }
    }
  },
  pathDependencies: {
    // imageDirectory: 'ASSETS_URL'
  }
};

// errors
const ERROR_CODE = {
  REQUIRED_CREDENTIAL: -1,
  FAIL_INITIALIZATION: -2,
  UNSUPPORTED_CAMERA: -3,
  FAIL_INITIALIZATION_CAMERA_U: -4,
  FAIL_CREATION_INSTANCE_DOCUMEN: -5,
  FAIL_UPLOAD_IMAG: -6,
  FAIL_GET_OC: -7,
  FAIL_GET_FACE_IMAG: -8,
  FACE_IMAGE_URL_NOT_FOUND: -9,
  FACE_IMAGE_NOT_FOUN: -10,
  RESOURCES_COULD_NOT_BE_LOADED: -11,
  DOMAIN_NOT_ALLOWED: -12,
};

class ResponseEvent {
  event;
  data;
  constructor(event, data) {
    this.event = event;
    this.data = data;
  }
}

class Result {
  id; // image of identification (image.data) and relevant information (sharpness, glare)
  idData; // ocr idData.ocr;
  idPhoto; // image of the face cutout
  constructor(data) {
    this.id = data.id;
    this.idData = data.idData;
    this.idPhoto = data.idPhoto;
  }
}

// const EVENT_MODULE = {
//   INIT_MODULE: "INIT_MODULE",
//   PROCESS_INIT: "PROCESS_INIT",
//   PROCESS_ERROR: "PROCESS_ERROR",
//   PROCESS_COMPLETED: "PROCESS_COMPLETED",
// }

// const CREDENTIALS = {
//   passiveUsername: process.env.VUE_APP_FAD_OCR_CREDENTIALS_PASSIVE_USERNAME,
//   passivePassword: process.env.VUE_APP_FAD_OCR_CREDENTIALS_PASSIVE_PASSWORD,
//   passiveSubscriptionId: process.env.VUE_APP_FAD_OCR_CREDENTIALS_PASSIVE_SUBSCRIPTION_ID,
//   acasEndpoint: process.env.VUE_APP_FAD_OCR_CREDENTIALS_ACAS_ENDPOINT,
//   assureidEndpoint: process.env.VUE_APP_FAD_OCR_CREDENTIALS_ASSUREID_ENDPOINT
// }

// const LEGENDS_FRENTE = {
//   initializing: "iniciando",
//   processing: "procesando",
//   scan: {
//     none: "ENFOCA TU ID SOBRE LA GUÍA",
//     smallDocument: "ACERCATE MÁS",
//     goodDocument: "",
//     capturing: "CAPTURANDO ",
//     tapToCapture: "TOCA LA PANTALLA PARA CAPTURAR",
//   },
//   manualCapture: {
//     instruction: "Captura el frente de tu identificación",
//     buttonNext: "Continuar",
//   },
// }

// const LEGENDS_REVERSO = {
//   initializing: "iniciando",
//   processing: "procesando",
//   scan: {
//     none: "ENFOCA TU ID SOBRE LA GUÍA",
//     smallDocument: "ACERCATE MÁS",
//     goodDocument: "",
//     capturing: "CAPTURANDO ",
//     tapToCapture: "TOCA LA PANTALLA PARA CAPTURAR",
//   },
//   manualCapture: {
//     instruction: "Captura el reverso de tu identificación",
//     buttonNext: "Continuar",
//   },
// }

// const ERROR_CODE = {
//   REQUIRED_CREDENTIAL: -1,
//   FAIL_INITIALIZATION: -2,
//   UNSUPPORTED_CAMERA: -3,
//   FAIL_INITIALIZATION_CAMERA_U: -4,
//   FAIL_CREATION_INSTANCE_DOCUMEN: -5,
//   FAIL_UPLOAD_IMAG: -6,
//   FAIL_GET_OC: -7,
//   FAIL_GET_FACE_IMAG: -8,
//   FACE_IMAGE_URL_NOT_FOUND: -9,
//   FACE_IMAGE_NOT_FOUN: -10,
//   RESOURCES_COULD_NOT_BE_LOADED: -11,
//   DOMAIN_NOT_ALLOWED: -12
// }

// class ResponseEvent {
//   event;
//   data;
//   constructor(event, data) {
//     this.event = event;
//     this.data = data;
//   }
// }
</script>

<style scoped>

.body{
  margin-top: 50px;
  overflow:hidden;
  overflow-x:hidden;
  overflow-y:hidden;
  height:93.4%;
  width:100%;
  position:absolute;
  top:0px;
  left:0px;
  right:0px;
  bottom:0px
}

/deep/ .modal{
  z-index: 9999;
}
/deep/ .modal-mask {
  position: fixed;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(248, 248, 248, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
/deep/ .modal-backdrop {
  z-index: -1;
  background-color: #fff;
  opacity: 0.9;
  -webkit-filter: blur(8px);
  -moz-filter: blur(8px);
  -o-filter: blur(8px);
  -ms-filter: blur(8px);
  filter: blur(8px);
}
/deep/ .modal-container {
  width: auto;
  max-width: 350px;
  background-color: #ffffff8c;
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  border: none;
}
/deep/ .modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border: none;
}
/deep/ .modal-header .close {
  border: none;
  color: #0181c4;
  background: white;
  font-size: 44px;
}
/deep/ .modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  align-self: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border: none;
  margin-top: 120px;
}
.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}
.modal-body {
  margin: 20px 0 80px 0;
}
.modal-default-button {
  float: right;
}
.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 0;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
/deep/ .modal-body {
  position: relative;
  font-size: 21px;
  color: #0181c4;
  margin: 0 0 62px 0;
  margin-top: 80px;
}
/deep/ .btn-primary:hover {
  color: #fff;
  background-color: #004b8d;
  border-color: #007bff;
  border-radius: 50px;
}
/deep/ .btn-primary {
  color: #fff;
  background-color: #004b8d;
  border-color: #007bff;
  border-radius: 50px;
}

.btn-primary:hover {
  color: #fff;
  background-color: #004b8d;
  border-color: #007bff;
  border-radius: 50px;
}
.btn-primary {
  color: #fff;
  background-color: #004b8d;
  border-color: #007bff;
  border-radius: 50px;
}

.icon-warning {
  margin: 3rem 0;
}

</style>