export const caracteresNoPermitidos = [37, 34, 39, 47, 38, 63, 191, 92, 60, 62, 33, 124, 123, 125, 91, 93, 43, 59, 44]
export const numeros = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57]
export const numeroyPunto = [46, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57]
export const estadosMexico = [
{
    estado: "Aguascalientes",
    abreviatura: "AS"
},
{
    estado: "Baja California",
    abreviatura: "BC"
},
{
    estado: "Baja California Sur",
    abreviatura: "BS"
},
{
    estado: "Campeche",
    abreviatura: "CC"
},
{
    estado: "Chiapas",
    abreviatura: "CS"
},
{
    estado: "Chihuahua",
    abreviatura: "CH"
},
{
    estado: "Ciudad de México",
    abreviatura: "DF"
},
{
    estado: "Coahuila",
    abreviatura: "CL"
},
{
    estado: "Colima",
    abreviatura: "CM"
},
{
    estado: "Durango",
    abreviatura: "DG"
},
{
    estado: "Guanajuato",
    abreviatura: "GT"
},
{
    estado: "Guerrero",
    abreviatura: "GR"
},
{
    estado: "Hidalgo",
    abreviatura: "HG"
},
{
    estado: "Jalisco",
    abreviatura: "JC"
},
{
    estado: "México",
    abreviatura: "MC"
},
{
    estado: "Michoacán",
    abreviatura: "MN"
},
{
    estado: "Morelos",
    abreviatura: "MS"
},
{
    estado: "Nayarit",
    abreviatura: "NT"
},
{
    estado: "Nuevo León",
    abreviatura: "NL"
},
{
    estado: "Oaxaca",
    abreviatura: "OC"
},
{
    estado: "Puebla",
    abreviatura: "PL"
},
{
    estado: "Querétaro",
    abreviatura: "QT"
},
{
    estado: "Quintana Roo",
    abreviatura: "QR"
},
{
    estado: "San Luis Potosí",
    abreviatura: "SP"
},
{
    estado: "Sinaloa",
    abreviatura: "SL"
},
{
    estado: "Sonora",
    abreviatura: "SR"
},
{
    estado: "Tabasco",
    abreviatura: "TC"
},
{
    estado: "Tamaulipas",
    abreviatura: "TS"
},
{
    estado: "Tlaxcala",
    abreviatura: "TL"
},
{
    estado: "Veracruz",
    abreviatura: "VZ"
},
{
    estado: "Yucatán",
    abreviatura: "YN"
},
{
    estado: "Zacatecas",
    abreviatura: "ZS"
}
]

export const alfabeto = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","U","V","W","X","Y","Z"];
