<template>
  <body>
    <Menu :cliente="true" :pathAnterior="'/prestapro/datosContacto'"/>
    <ModalError :mostrar="mostrarModalError" :descripcion="descripcionError" />
    <ModalSesion :mostrar="mostrarModalSesion" />
    <div class="container">
      <div class="row">
        <div class="col-md-5 mx-auto">
          <div id="second">
            <div class="myform form" style="margin-top: 1%">
              <form action="#" name="registration" @submit.prevent="redirect">
                <div class="form-group">
                  <div class="logo mb-3 pl-0 pr-0 pt-3 colorb">
                    <label
                      >Completa el cuestionario de prevención de lavado de
                      dinero
                    </label>
                    <br />
                    <br />
                  </div>
                  <label class="btnc"
                    >1. ¿Desempeña o ha desempeñado cargo alguno dentro del
                    gobierno federal o estatal en los últimos 4 años?</label
                  >
                  <b-form-group >
                    <b-form-group >
                      <div class="radio-boton-item">
                        <b-form-radio stacked v-model="pregunta1" name="radio-pregunta1" value="si">&nbsp;Si</b-form-radio>
                      </div>
                      <div class="radio-boton-item">
                        <b-form-radio stacked v-model="pregunta1" name="radio-pregunta1" value="no">&nbsp;No</b-form-radio>
                      </div>
                    </b-form-group>
                    <div class="form-group content-form" v-if="mostrarRespuesta1">
                      <div><Tooltip :texto="'Ingresa el puesto que desempeñaste.'" :enlace="'tooltip-cargo'" /></div>
                      <label class="left">Cargo:</label>
                      <div class="input-group">
                        <b-form-input
                          v-model="cargo"
                          placeholder=""
                          class="form-control input-tooltip-space"
                          maxlength="50"
                          @blur="validarCargo1"
                          @keypress="validarAlfanumericos"
                        ></b-form-input>
                        <img src="@/assets/img/exclamation-circle-solid.svg" id="tooltip-cargo" width="35" height="35" alt="tooltip-cargo" class="tooltip-input-rigth" />
                      </div>
                    </div>
                    <div v-if="mostrarRespuesta1" :class="estiloCargo + ' texto-error'" align="right">
                      {{ textoCargo }}
                    </div>
                    <br />
                    <label class="btnc"
                      >2. ¿Tiene algún pariente que desempeña o ha desempeñado
                      cargo dentro del gobierno federal o estatal en los últimos
                      4 años?</label
                    >
                    <b-form-group >
                      <div class="radio-boton-item">
                        <b-form-radio stacked v-model="pregunta2" name="radio-pregunta2" value="si">&nbsp;Si</b-form-radio>
                      </div>
                      <div class="radio-boton-item">
                        <b-form-radio stacked v-model="pregunta2" name="radio-pregunta2" value="no">&nbsp;No</b-form-radio>
                      </div>
                    </b-form-group>
                    <div class="form-group content-form" v-if="mostratRespuesta2" >
                      <div><Tooltip :texto="'Ingresa, padre, madre, hermano(a), tío(a), primo(a), etc.'" :enlace="'tooltip-vinculo'" /></div>
                      <label Class="left">Vínculo:</label>
                      <div class="input-group">
                        <b-form-input
                          v-model="vinculo"
                          placeholder=""
                          class="form-control input-tooltip-space"
                          maxlength="50"
                          @blur="validarVinculo"
                          @keypress="validarAlfanumericos"
                        ></b-form-input>
                        <img src="@/assets/img/exclamation-circle-solid.svg" id="tooltip-vinculo" width="35" height="35" alt="tooltip-vinculo" class="tooltip-input-rigth" />
                      </div>
                      
                    </div>
                    <div v-if="mostratRespuesta2" :class="estiloVinculo + ' texto-error'" align="right">
                        {{ textoVinculo }}
                      </div>
                      <br />
                  </b-form-group>
                </div>
                <br />
                <div class="col-md-12 text-center mb-3 pt-4">
                  <button
                    type="submit"
                    class="btn btn-block mybtn btn-primary"
                    :disabled="!continuar"
                  >
                    Continuar
                  </button>
                </div>
                <div class="col-md-12"></div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </body>
</template>


<script>

import Menu from '../genericos/Menu.vue'
import ModalError from '../genericos/ModalError'
import { caracteresNoPermitidos } from '../../constants/cajaTexto'
import { mapGetters } from 'vuex'
import { guardarSolicitud, cancelarFolio } from '../../services/prestapro'
import { obtenerMes, obtenerAno } from '../../constants/fecha'
import Cookies from "js-cookie";
import { eliminarCaracteresExpeciales } from '../../utils/formatoTexto'
import { generarToken } from '../../services/autorizacion'
import ModalSesion from '../genericos/ModalSesion.vue'
import { cancelarLiga } from '../../services/tcs'
import Tooltip from '../genericos/Tooltip.vue'

export default {
  name: "EnvioCodigo",

  components: {
    Menu,
    ModalError,
    ModalSesion,
    Tooltip
  },
  computed: {
    ...mapGetters(['obtenerPrestaPro'])
  },
  data: () => ({
    mostrarModalSesion: false,
    celular: true,
    correo: true,
    curp: "",
    select1: null,
    select2: null,
    select3: null,
    cargo: "",
    vinculo: "",
    continuar: true,
    estiloCargo: "",
    estiloVinculo: "",
    textoCargo: null,
    textoVinculo: null,
    habilitarCargo1: false,
    habilitarVinculo: false,
    Puestos: [
      { value: null, text: "Selecciona uno" },
      { value: "Funcionario", text: "Funcionario de Gobierno" },
      { value: "Gobernante", text: "Gobernante" },
    ],
    Cargo: [
      { value: null, text: "Selecciona uno" },
      { value: "Funcionario", text: "Funcionario de Gobierno" },
      { value: "Gobernante", text: "Gobernante" },
    ],
    Vinculo: [
      { value: null, text: "Selecciona uno" },
      { value: "Funcionario", text: "Padre" },
      { value: "Gobernante", text: "Madre" },
    ],
    pregunta1: "no",
    pregunta2: "no",
    mostrarRespuesta1: false,
    mostratRespuesta2: false,
    mostrarModalError: false,
    descripcionError: null,
  }),
  methods: {
    validarAlfanumericos: ($e) => {
      if(caracteresNoPermitidos.includes($e.charCode)){
          $e.preventDefault()
      }
    },
    async redirect(){
      this.$store.commit('agregarValorPrestaPro', {
        preguntaPLD1: this.cargo,
        preguntaPLD2: this.vinculo
      })
      // guardamos la solicitud
      const persona = {
        PrimerNombre: this.obtenerPrestaPro.primerNombre,
        SegundoNombre: this.obtenerPrestaPro.segundoNombre,
        ApellidoPaterno: this.obtenerPrestaPro.apellidoPaterno,
        ApellidoMaterno: this.obtenerPrestaPro.apellidoMaterno,
        Genero: this.obtenerPrestaPro.genero.charAt(0),
        GrupodePago: "01",
        ClaveDelegacionPago: "01",
        EntidadNacimiento: this.obtenerPrestaPro.entidadNacimiento,
        PaisNacimiento: this.obtenerPrestaPro.paisNacimiento,
        Nacionalidad: this.obtenerPrestaPro.nacionalidad,
        FechaNacimiento: this.obtenerPrestaPro.fechaNacimiento,
        CURP: this.obtenerPrestaPro.curp.toUpperCase(),
        RFC: this.obtenerPrestaPro.rfc.toUpperCase(),
        NSS: this.obtenerPrestaPro.nss,
        DependenciaIMSS: "CDMX",
        Ocupacion: this.obtenerPrestaPro.ocupacion,
        Pregunta1PLD: this.cargo,
        Cargo2PLD: this.vinculo,
        Autorizadatos: "1",
        TipoEdoCta: this.obtenerPrestaPro.tipoEnvio,
        Banco: this.obtenerPrestaPro.banco,
        CuentaClabe: this.obtenerPrestaPro.clabe,
        IngresoMensual: 0,
        PensionHijos: 0,
        CapacidadPago: parseInt(this.obtenerPrestaPro.capacidadDescuento)
      }
      const domicilio = {
        Calle: this.obtenerPrestaPro.calle,
        NumExt: this.obtenerPrestaPro.numeroExterior,
        NumInt: this.obtenerPrestaPro.numeroInterior,
        CP: this.obtenerPrestaPro.codigoPostal,
        Estado: this.obtenerPrestaPro.estado,
        Delegacion: this.obtenerPrestaPro.delegacion,
        IdColonia: this.obtenerPrestaPro.idColonia,
        Colonia: this.obtenerPrestaPro.colonia,
        Ciudad: this.obtenerPrestaPro.ciudad,
        Pais: this.obtenerPrestaPro.pais,
        Telefono: this.obtenerPrestaPro.telefono,
        Celular: this.obtenerPrestaPro.celular,
        CiaTelefonica: "TELCEL",
        Correo: this.obtenerPrestaPro.correo
      }
      const solicitud = {
        Folio: this.obtenerPrestaPro.folio,
        NumeroEmpleadoVendedor: this.obtenerPrestaPro.ejecutivo,//'503153'
        Persona: persona,
        Domicilio: domicilio,
        AvisoPrivacidad:true,
        Ubicacion: `${this.obtenerPrestaPro.ubicacion.latitude},${this.obtenerPrestaPro.ubicacion.latitude}`,
        PermisoUsoCamara: this.obtenerPrestaPro.camara,
        PermisoUsoMicrofono: true,
        FechaInforme: `${obtenerMes()} ${obtenerAno()}`,
        ConceptosInforme: "0",
        FolioConfirmacion: "0",
        CodigoError: "0"
      }
      this.mostrarModalError = true
      this.descripcionError = "loadspin"
      const respuesta = await guardarSolicitud(solicitud)
        .then(r => r)
        .catch(e => {
          this.mostrarModalError = true
          this.descripcionError = e.descripcion
          return e
        })
      const codigoError = respuesta.data.Errores[0];
      if(codigoError.Codigo === 1010001){
        this.$router.replace("/prestapro/confirmaDatosPrest")
      }else {
        this.mostrarModalError = true
        this.descripcionError = codigoError.Mensaje
        await cancelarFolio(this.obtenerPrestaPro.folio, "No se guardo la solicitud, por el error: " + codigoError.Mensaje).then(r => r).catch(e => e)
        await cancelarLiga({ curp: this.obtenerPrestaPro.curp.toUpperCase(), id: this.obtenerPrestaPro.uid }).then(r => r).catch(e => e)
      } 
    },
    validarContinuar() {
      if( this.mostrarRespuesta1 && !this.mostratRespuesta2 ){
        if( this.habilitarCargo1 ){
          this.continuar = true
        } else {
          this.continuar = false
        }
      } else if ( !this.mostrarRespuesta1 && this.mostratRespuesta2 ){
        if ( this.habilitarVinculo ){
          this.continuar = true
        } else {
          this.continuar = false
        }
      } else if ( this.mostrarRespuesta1 && this.mostratRespuesta2 ){
        if ( this.habilitarCargo1 && this.habilitarVinculo ){
          this.continuar = true
        } else {
          this.continuar = false
        }
      } else {
        this.continuar = true
      }
    },
    validarCargo1(){
      if ( this.cargo !== null && this.cargo !== "" && this.cargo.trim().length > 0 ){
        this.cargo = this.cargo.toUpperCase()
        this.estiloCargo = ""
        this.textoCargo = null
        this.habilitarCargo1 = true
      } else {
        
        this.estiloCargo = "showError"
        this.textoCargo = "Dato Obligatorio"
        this.habilitarCargo1 = false
      }
      this.validarContinuar()
    },
    validarVinculo(){
      if ( this.vinculo !== null && this.vinculo !== "" && this.vinculo.trim().length > 0){
        this.vinculo = this.vinculo.toUpperCase()
        this.estiloVinculo = ""
        this.textoVinculo = null
        this.habilitarVinculo = true
      } else {
        this.estiloVinculo = "showError"
        this.textoVinculo = "Dato Obligatorio"
        this.habilitarVinculo = false
      }
      this.validarContinuar()
    }
  },
  watch: {
    pregunta1( value ){
      if( value === "si" ){
        this.mostrarRespuesta1 = true
        this.habilitarCargo1 = false
        this.textoCargo = null
        this.estiloCargo = ""
      } else {
        this.mostrarRespuesta1 = false
        this.habilitarCargo1 = false
        this.textoCargo = null
        this.estiloCargo = ""
        this.cargo = null
      }
      this.validarContinuar()
    },
    pregunta2 ( value ){
      if( value === "si" ){
        this.mostratRespuesta2 = true
      } else {
        this.mostratRespuesta2 = false
        this.habilitarCargo2 = false
        this.habilitarVinculo = false
        this.textoCargo2 = null
        this.textoVinculo = null
        this.cargo2 = null
        this.estiloVinculo = ""
        this.vinculo = null
      }
      this.validarContinuar()
    },
    cargo( value ){
      if(value !== null && value !== ""){
        this.cargo = eliminarCaracteresExpeciales(value)
      }
      this.validarCargo1()
    },
    vinculo( value ){
      if(value !== null && value !== ""){
        this.vinculo = eliminarCaracteresExpeciales(value) 
      }
      this.validarVinculo()
    }
  },
  async mounted(){
    const accessToken = await generarToken()
      .then(r => r)
      .catch(e => e)
    Cookies.set('token', accessToken.data.access_token)
    this.continuar = true
  },
  beforeMount(){
    if(!Cookies.get('token')){
      this.mostrarModalSesion = true
    }
  }
};
</script>


<style scoped>

.radio-boton-item {
  display: inline-block;
  margin: 0 6px;
}

.texto-error{
  padding-right: 11%;
}
.pap {
  padding-left: 10%;
}

.flex {
  display: flex;
  margin-left: 43%;
  margin-right: 2px;
}
.btnc {
  width: 80%;
  display: block;
  text-align: justify !important;
  margin-left: 10%;
}
.btncs {
  text-align: center !important;
}

.left{
    display: flex;
    padding-left: 5px;
}

.tooltip-input{
  display: flex;
  float: left;
  margin-top: 3px;
}
.content-form{
  width: 80%;
  margin-left: 10%;
}

.tooltip-input-rigth{
  margin: 1px;
  margin-left: 10px;

}
.input-tooltip-space{
  margin-right: 3px;
}
</style>