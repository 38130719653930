<template>
  <b-modal
    content-class="modal-container"
    ref="modalError"
    id="modalError"
    align="center"
    @close="cerrarConX"
    :hide-header-close="descripcion=='loadspin'"
    :no-close-on-backdrop="true"
    modal-class="modal-error"
  >
    <b-container fluid>
      <div v-if="descripcion !='loadspin'" class="icon-warning">
        <img src="@/assets/img/icn-70-px-general-alert.svg" alt="img-atras" />
      </div>
      <b-row class="mb-1">
        <b-col>
          <img v-if="descripcion=='loadspin'" class="img-responsive" src="@/assets/img/loader_Prof.gif" alt="img-loader" />
          <p class="modal-text-color" v-if="descripcion !='loadspin'">{{ descripcion }}</p>
        </b-col>
      </b-row>
    </b-container>
    <template #modal-footer>
      <div class="col-md-6 text-center mb-3 pt-2"></div>
      <div v-if="descripcion !='loadspin'" class="col-md-12 text-center mb-3 pt-4">
        <button @click="accionarBoton" class="btn btn-block mybtn btn-primary">
          Continuar
        </button>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  data: () => ({
    closeButton: false,
  }),
  props: {
    mostrar: {
      type: Boolean,
      default: false,
    },
    descripcion: String,
    accion: {
      type: Function,
      default: () => {},
    },
  },
  mounted() {
    if (this.mostrar) {
      this.abrirModal();
    } else {
      this.cerrarModal();
    }
  },
  methods: {
    abrirModal() {
      this.$bvModal.show("modalError")
    },
    cerrarModal() {
      this.$bvModal.hide("modalError")
    },
    cerrarConX() {
      this.accion()
      this.cerrarModal()
    },
    accionarBoton() {
      this.accion()
      this.cerrarModal()
    },
  },
  watch: {
    mostrar(value) {
      if (value) {
        this.abrirModal();
      } else {
        this.cerrarModal();
      }
    },
  },
};
</script>

<style scoped>

.modal-error{
  z-index: 9999;
}
/deep/ .modal{
  z-index: 9999;
  overflow-y: hidden;
}
/deep/ .modal-mask {
  position: fixed;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(248, 248, 248, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
/deep/ .modal-backdrop {
  z-index: -1;
  background-color: #fff;
  opacity: 0.9;
  -webkit-filter: blur(8px);
  -moz-filter: blur(8px);
  -o-filter: blur(8px);
  -ms-filter: blur(8px);
  filter: blur(8px);
}
/deep/ .modal-container {
  width: auto;
  max-width: 350px;
  background-color: #ffffff8c;
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  border: none;
}
/deep/ .modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border: none;
}
/deep/ .modal-header .close {
  border: none;
  color: #0181c4;
  background: white;
  font-size: 44px;
}
/deep/ .modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  align-self: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border: none;
  margin-top: 80px;
}
.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}
.modal-body {
  margin: 20px 0 80px 0;
}
.modal-default-button {
  float: right;
}
.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 0;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
/deep/ .modal-body {
  position: relative;
  font-size: 21px;
  color: #0181c4;
  margin: 0 0 62px 0;
}
/deep/ .btn-primary:hover {
  color: #fff;
  background-color: #004b8d;
  border-color: #007bff;
  border-radius: 50px;
}
/deep/ .btn-primary {
  color: #fff;
  background-color: #004b8d;
  border-color: #007bff;
  border-radius: 50px;
}

.btn-primary:hover {
  color: #fff;
  background-color: #004b8d;
  border-color: #007bff;
  border-radius: 50px;
}
.btn-primary {
  color: #fff;
  background-color: #004b8d;
  border-color: #007bff;
  border-radius: 50px;
}

.icon-warning {
  margin: 3rem 0;
}
</style>