<template>
  <body>
    <Menu :cliente="true" :atras="false" />
    <ModalSesion :mostrar="mostrarModalSesion" />
    <div class="container center">
      <div class="row">
        <div class="col-md-6 mx-auto">
          <div id="second">
            <div>
              <div class="logo mb-3 pl-0 pr-0 pt-3 colorb">
                <label>Estás a 7 pasos de solicitar tu<br />préstamo</label>
                <br />
              </div>
              <ul class="ul-items">
                <li class="li-paso">
                  <div class="li-imagen">
                    <img
                      class="img-response"
                      src="@/assets/img/icn-monto.svg"
                      alt="img-monto"
                    />
                  </div>
                  <div class="paso">
                    <label class="colore">Paso 1</label>
                    <br />
                    <label class="colorn paso-label">Captura tus datos</label>
                  </div>
                </li>
                <br />
                <li class="li-paso">
                  <div class="li-imagen">
                    <img
                      class="img-response"
                      src="@/assets/img/Firmar.svg"
                      alt="img-firma"
                    />
                  </div>
                  <div class="paso">
                    <label class="colore paso-label">Paso 2</label>
                    <br />
                    <label class="colorn paso-label"
                      >Completa tu cuestionario de lavado de dinero</label
                    >
                  </div>
                </li>
                <br />
                <li class="li-paso">
                  <div class="li-imagen">
                    <img
                      class="img-response"
                      src="@/assets/img/icono-captura-ine.svg"
                      alt="img-captura-ine"
                    />
                  </div>
                  <div class="paso">
                    <label class="colore paso-label">Paso 3</label>
                    <br />
                    <label class="colorn paso-label"
                      >Captura tu identificación oficial vigente</label
                    >
                  </div>
                </li>
                <br />
                <li class="li-paso">
                  <div class="li-imagen">
                    <img
                      class="img-response"
                      src="@/assets/img/Captura.png"
                      alt="img-captura"
                    />
                  </div>
                  <div class="paso">
                    <label class="colore paso-label">Paso 4</label>
                    <br />
                    <label class="colorn paso-label">Tómate una foto</label>
                  </div>
                </li>
                <br />
                <li class="li-paso">
                  <div class="li-imagen">
                    <img
                      class="img-response"
                      src="@/assets/img/EstadoDeCuenta.svg"
                      alt="img-estado-cuenta"
                    />
                  </div>
                  <div class="paso">
                    <label class="colore paso-label">Paso 5</label>
                    <br />
                    <label class="colorn paso-label"
                      >Captura tu carta de libranza</label
                    >
                  </div>
                </li>
                <br />
                <li class="li-paso">
                  <div class="li-imagen">
                    <img
                      class="img-response"
                      src="@/assets/img/Documento_opt.png"
                      alt="img-documento"
                    />
                  </div>
                  <div class="paso">
                    <label class="colore paso-label">Paso 6</label>
                    <br />
                    <label class="colorn paso-label"
                      >Valida tus documentos contractuales</label
                    >
                  </div>
                </li>
                <br />
                <li class="li-paso">
                  <div class="li-imagen">
                    <img
                      class="img-response"
                      src="@/assets/img/icn-toma-video.svg"
                      alt="img-toma-video"
                    />
                  </div>
                  <div class="paso">
                    <label class="colore paso-label">Paso 7</label>
                    <br />
                    <label class="colorn paso-label">Captura tu video</label>
                  </div>
                </li>
              </ul>
              <br />
              <div class="col-md-12 text-center mb-3 pt-4">
                <button
                  @click="SietePaso"
                  class="btn btn-block mybtn btn-primary"
                >
                  Continuar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </body>
</template>

<script>
import { mapGetters } from "vuex"
import Menu from "../genericos/Menu.vue"
import ModalSesion from '../genericos/ModalSesion.vue'
import Cookies from 'js-cookie'

export default {
  components: {
    Menu,
    ModalSesion
  },
  data: () => ({
    mostrarModalSesion: false,
  }),
  computed: {
    ...mapGetters(["obtenerPrestaPro"]),
  },
  methods: {
    SietePaso() {
      this.$router.replace("/prestapro/CapturaDatosPension");
    },
  },
  beforeMount(){
    if(!Cookies.get('token')){
      this.mostrarModalSesion = true
    }
  }
};
</script>

<style scoped>

.center{
  text-align: center;
}
.btncs {
  display: block;
  text-align: left !important;
  margin-left: 8%;
}

.paso {
  float: left;
  text-align: left;
  margin-left: 1vh;
  margin-top: 5px;
  width: fit-content;
}

.ul-items {
  width: fit-content;
}

.paso-label {
  position: inherit;
  width: 25vh;
}

.li-paso {
  display: flow-root;
  margin-left: 0%;
}

.li-imagen {
  float: left;
  width: 58px;
  height: 58px;
  margin-left: 30px;
}
.btn-primary {
  color: #fff;
  background-color: #004b8d;
  border-color: #007bff;
  border-radius: 50px;
  height: 40px;
  width: 200px;
}
</style>
