<template>
  <body>
    <Menu :cliente="true" :atras="false" />
    <ModalError
      :mostrar="mostrarModalError"
      :descripcion="descripcionError"
    />
    <ModalSesion :mostrar="mostrarModalSesion" />
    <div class="container">
      <div class="row">
        <div class="col-md-5 mx-auto">
          <div id="second">
            <div class="myform form" style="margin-top: 1%">
              <form action="#" name="registration" @submit.prevent="iniciarCamara">
                <div class="form-group">
                  <div class="logo mb-3 pl-0 pr-0 pt-3 colorb">
                    <br />
                    <label
                      >Selecciona un tipo de comprobante de domicilio
                    </label>
                    <br />
                  </div>

                  <b-form-group id="formGroup">
                    <b-form-radio
                      class="pstyle"
                      id="radioReciboLuz"
                      @change="check1 = false"
                      name="some-radios"
                      value="A"
                      ><span>Recibo de luz</span></b-form-radio
                    ><br />
                    <b-form-radio
                      class="pstyle"
                      @change="check1 = false"
                      name="some-radios"
                      value="B"
                    >
                      <span>Recibo de teléfono</span></b-form-radio
                    ><br />
                    <b-form-radio
                      class="pstyle"
                      id="radioReciboAgua"
                      @change="check1 = false"
                      name="some-radios"
                      value="C"
                    >
                      <span>Recibo de agua</span></b-form-radio
                    ><br />
                    <b-form-radio
                      class="pstyle"
                      id="radioReciboGas"
                      @change="check1 = false"
                      name="some-radios"
                      value="D"
                    >
                      <span>Recibo de gas</span></b-form-radio
                    >
                    <br />
                    <b-form-radio
                      class="pstyle"
                      id="radioReciboPredial"
                      @change="check1 = false"
                      name="some-radios"
                      value="E"
                    >
                      <span>Impuesto predial</span></b-form-radio
                    >
                    <br />
                    <b-form-radio
                      class="pstyle"
                      id="radioReciboBanco"
                      @change="check1 = false"
                      name="some-radios"
                      value="F"
                    ><span>Estado de cuenta bancario / Tienda
                      departamental</span>
                      </b-form-radio
                    ><br />
                  </b-form-group>
                </div>
                <br/>
                <br/>
                <div class="col-md-12 text-center mb-3 pt-4">
                  <button
                    type="submit"
                    id="buttonContinuar"
                    :disabled="check1"
                    @click="iniciarCamara"
                    placeholder=""
                    class="btn btn-block mybtn btn-primary"
                  >
                    Continuar
                  </button>
                </div>
                <div class="col-md-12"></div>
              </form>
            </div>
          </div>
          <b-modal
            content-class="modal-container"
            id="modal-captura"
            @ok="capture"
            ok-only
            ok-title="Capturar"
            align="center"
            :hide-header="true"
            :hide-header-close="true"
            hide-footer
            modal-class="modal-captura-s"
            no-close-on-backdrop
          >
            <b-container fluid>
              <b-row class="mb-1">
                <div class="camara" id="camara">
                  <video id="video-comprobante-domicilio" class="feed" autoplay></video>
                  <img src="@/assets/img/flip.png" class="img-invert" @click="changeMode" width="30" height="30" alt="img-camara" />
                  <br/>
                  <button class="btn-primary size-boton" type="button" :disabled="caturaBotonDisabled" @click="capture">Capturar</button>
                </div>
              </b-row>
            </b-container>
          </b-modal>
          <b-modal
            content-class="modal-container"
            id="modal-ine-comprobante"
            @cancel="redirectCancel"
            @ok="redirectOk"
            ok-title="Si"
            cancel-title="No"
          >
            <b-container fluid align="center">
              <div class="icon-warning">
                <img class="img-camara" src="@/assets/img/icn-70-px-general-alert.svg" alt="img-alerta"/>
              </div>
              <b-row class="mb-1">
                <b-col
                  ><p class="modal-text-color"></p>
                  ¿Quieres utilizar tu INE/IFE como comprobante de
                  domicilio?</b-col
                >
              </b-row>
            </b-container>
          </b-modal>
        </div>
      </div>
    </div>
    
  </body>
</template>

<script>
import Menu from '../genericos/Menu.vue'
import ModalError from '../genericos/ModalError.vue'
import Cookies from 'js-cookie';
import ModalSesion from '../genericos/ModalSesion.vue'
export default {
  data() {
    return {
      mostrarModalSesion: false,
      mostrarModalError: false,
      descripcionError: null,
      change: true,
      check1:true,
      isCameraOpen: false,
      isPhotoTaken: false,
      isShotPhoto: false,
      isLoading: false,
      link: "#",
      modeVideo: "environment",
      selfie: false,
      caturaBotonDisabled: false
    };
  },
  components:{
    Menu,
    ModalError,
    ModalSesion
  },
  methods: {
    iniciarCamara(){
      this.init()
    },
    capture(){
      this.caturaBotonDisabled = true
      setTimeout(() => {
        let video = document.getElementById('video-comprobante-domicilio'), canvas
        let context
        let width = (video.offsetWidth * 9), height = (video.offsetHeight * 9)
        canvas = canvas || document.createElement('canvas')
        canvas.width = width
        canvas.height = height
        context = canvas.getContext('2d')
        context.drawImage(video, 0, 0, width, height)
        const img = canvas.toDataURL('image/jpeg')
        this.$store.commit('agregarValorPrestaPro', { imgComprobanteDomicilio: img })
        window.captureComprobante.getVideoTracks()[0].stop()
        this.$router.replace("/prestapro/_29_4_comprobante_de_pago")
      }, 100)
    },
    changeMode(){
      if(this.selfie){
        this.modeVideo = "environment"
        this.selfie = false
        this.init()
      }else {
        this.modeVideo = "user"
        this.selfie = true
        this.init()
      }
    },
    init(){
      this.$bvModal.show("modal-captura")
      if('mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices){
        navigator.mediaDevices.getUserMedia(
          {
            video: {
              width: { min: 524, ideal: 4096, max: 1220 },
              height: { min: 476, ideal: 2160, max: 980 },
              facingMode: this.modeVideo
            }
          }
          ).then(stream => {
          let videoPlayer = document.getElementById("video-comprobante-domicilio")
          videoPlayer.setAttribute('autoplay', '')
          videoPlayer.setAttribute('muted', '')
          videoPlayer.setAttribute('playsinline', '')
          videoPlayer.srcObject = stream
          videoPlayer.play()
          window.captureComprobante = stream
        })
      }
    },
    redirectOk() {
      this.$router.replace("/prestapro/CapturaCartaLibranza");
    },

    redirectCancel() {
      this.$bvModal.hide("modal-ine-comprobante")
    },
  },
  mounted(){
    this.$bvModal.show("modal-ine-comprobante")
  },
  beforeMount(){
    if(!Cookies.get('token')){
      this.mostrarModalSesion = true
    }
  }
};
</script>

<style scoped>

span{
  position: absolute;
  margin-left: 15px;
  width: 70%;
  margin-top: -18px;
}

/deep/ span{
  display: initial  ;
  
}
.in-line{
  display: initial;
}

.img-invert{
  margin: 10px 0;
}
.size-boton{
  font-size: 16px;
}

.modal-captura-s{
  z-index: 2;
}

.camara{
  width: 100vw;
  padding: 0;
  box-sizing: border-box;
  margin-top: 20px;
}

.feed{
  display: block;
  width: 100%;
  max-width: 1280px;
  margin: 0;
  background-color: #fff;
}

/deep/ .modal{
  z-index: 9999;
}
/deep/ .modal-mask {
  position: fixed;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(248, 248, 248, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
/deep/ .modal-backdrop {
  z-index: -1;
  background-color: #fff;
  opacity: 1;
  -webkit-filter: blur(8px);
  -moz-filter: blur(8px);
  -o-filter: blur(8px);
  -ms-filter: blur(8px);
  filter: blur(8px);
}
/deep/ .modal-container {
  width: auto;
  max-width: 420px;
  background-color: #ffffff8c;
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  border: none;
}
/deep/ .modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border: none;
}
/deep/ .modal-header .close {
  border: none;
  color: #0181c4;
  background: white;
  font-size: 44px;
}
/deep/ .modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  align-self: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border: none;
  margin-top: 120px;
}
.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}
.modal-body {
  margin: 20px 0 80px 0;
}
.modal-default-button {
  float: right;
}
.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 0;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
/deep/ .modal-body {
  position: relative;
  font-size: 21px;
  color: #0181c4;
  margin: 0 0 62px 0;
}
/deep/ .modal-dialog{
  justify-content: center;
    display: flex;
}
/deep/ .btn-primary:hover {
  color: #fff;
  background-color: #004b8d;
  border-color: #007bff;
  border-radius: 50px;
}
/deep/ .btn-primary {
  color: #fff;
  background-color: #004b8d;
  border-color: #007bff;
  width: 130px;
  border-radius: 50px;
}

.btn-primary:hover {
  color: #fff;
  background-color: #004b8d;
  border-color: #007bff;
  width: 130px;
  border-radius: 50px;
}
.btn-primary {
  color: #fff;
  background-color: #004b8d;
  border-color: #007bff;
  width: 130px;
  border-radius: 50px;
}

/deep/ .btn-secondary:hover {
  background-color: white;
    border-color: #007bff;
    color: #004b8d;
    font-weight: 650;
    height: 34px;
    width: 130px;
}

/deep/ .btn-secondary {
  background-color: white;
    border-color: #007bff;
    color: #004b8d;
    font-weight: 650;
    height: 34px;
    border-radius: 50px;
    width: 130px;
}

.btn-secondary:hover {
  background-color: white;
    border-color: #007bff;
    color: #004b8d;
    font-weight: 650;
    height: 34px;
    width: 130px;
}

.btn-secondary {
  background-color: white;
    border-color: #007bff;
    color: #004b8d;
    font-weight: 650;
    height: 34px;
    width: 140px;
    margin: 0px 20px 0px -30px;
}
.icon-warning {
  margin: 3rem 0;
}
</style>
