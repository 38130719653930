import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    datosProspecto: {},
    prospecto: {},
    prestapro: {},
    informePago64: '',
    status: '',
    tipoCreditoProspecto: '',
  },
  mutations: {
    modificarDatosProspecto(state, value) {
      state.datosProspecto = value;
    },
    capturaInformePago(state, value) {
      state.informePago64 = value;
    },
    capturaStatus(state, value) {
      state.status = value;
    },
    modificarTipoCreditoProspecto(state, value) {
      state.tipoCreditoProspecto = value;
    },
    agregarValorProspecto(state, value){
      state.prospecto = { ...state.prospecto, ...value }
    },
    limpiarValoresProspecto( state ){
      state.prospecto = {}
    },
    agregarValorPrestaPro(state, value){
      state.prestapro = { ...state.prestapro, ...value }
    },
    limpiarValoresPrestaPro(state){
      state.prestapro = {}
    }
  },
  actions: {
    definirDatosProspecto({ commit }, payload) {
      commit('modificarDatosProspecto', payload)
    },
    defineInformePago({ commit }, payload) {
      commit('capturaInformePago', payload)
    },
    defineStatus({ commit }, payload) {
      commit('capturaStatus', payload)
    },
    defineTipoCreditoProspecto({ commit }, payload) {
      commit('modificarTipoCreditoProspecto', payload)
    }
  },
  modules: {
  }, 
  getters: {
    obtenerDatosProspecto: (state) => {
      return state.datosProspecto
    },
    obtenerInformePago: state => {
      return state.informePago64
    }
    ,
    obtenerStatus: (state) => {
      return state.status
    },
    obtenerTipoCreditoProspecto: (state) => {
      return state.tipoCreditoProspecto
    },
    obtenerProspecto: state => {
      return state.prospecto
    },
    obtenerPrestaPro: state => {
      return state.prestapro
    }
  },
})

