<template>
  <div class="home">
    <body>
    <ModalError
      :mostrar="mostrarModalError"
      :descripcion="descripcionError"
      :accion="reload"
    />
    <div class="container">
      <div class="row">
        <div class="col-md-5 mx-auto">
          <div id="first">
            <div class="myform form" style="margin-top: 1%">
              <div class="logo mb-3">
                <div class="col-md-12 text-center pl-0 pr-0 background" >
                  <img
                      class="img-responsive"
                      src="@/assets/img/profuturocover.jpg"
                      alt="img-profuturo"
                  />

                </div>
              </div>
              <div class="form-group">
                <label for="exampleInputEmail1" class="left">Usuario:</label>
                <input
                    name="email"
                    class="form-control"
                    id="user"
                    maxlength="8"
                    @keydown="validarCaracterEspecial($event)"
                    @blur="verificarCajaTexto('user')"
                    aria-describedby="emailHelp"
                    placeholder=""
                    :class="userthemec"
                    v-model="username"
                />
                <div :class="usertheme">
                  {{ text.user }}
                </div>
              </div>
              <br/>
              <div class="form-group prueba1">
                <label for="exampleInputEmail1" class="left">Contraseña:</label>
                <input
                    type="password"
                    name="password"
                    id="password"
                    @keydown="validarCaracterEspecial($event)"
                    @blur="verificarCajaTexto('pass')"
                    min="12"
                    :class="passwordthemec"
                    maxlength="16"
                    class="form-control"
                    aria-describedby="emailHelp"
                    placeholder=""
                    required
                    v-model="password"
                />
                <div :class="passwordtheme">
                  {{ text.password }}
                </div>
              </div>
              
              <div class="form-group" v-if="mostrarError">
                <br/>
                <b-alert id="idAlert" v-model="mostrarError" variant="danger">{{mensajeErr}}
                </b-alert>
              </div>

              <div class="col-md-12 text-center pt-4">
                <button
                    id="boton-login"
                    @click="login"
                    class="btn btn-block mybtn btn-primary"
                    :disabled="mailOk"
                >
                  Iniciar sesión
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </body>
  </div>
</template>

<script>
import { generarToken, definirUsuario, login } from "../services/autorizacion";
import Cookies from "js-cookie";
import ModalError from "../views/genericos/ModalError.vue";
import { mapActions, mapGetters } from "vuex";
import { obtenerParametro } from '../utils/url'
import { obtenerLlavesAsimetricas, buscarProspecto } from '../services/tcs'
import { decryptObject } from '../utils/rsa'

export default {
  name: "Home",
  components: {
    ModalError,
  },
  data: () => ({
    mostrarModalError: false,
    descripcionError: null,
    username: "",
    password: "",
    mostrarError: false,
    mensajeError: "",
    passwordDisabled: true,
    userDisabled: true,
    showmodal: false,
    mailOk: true,
    usertheme: "",
    passwordtheme: "",
    userthemec: "",
    passwordthemec: "",
    textErrorUser: "",
    text: {
      user: "",
      password: ""
    },
    showerror: "",
    mensajeErr: "",
  }),
  computed: {
    ...mapGetters(["obtenerProspecto"]),
  },
  async mounted(){
    const data = obtenerParametro('d')
    if( data !== null ){
      const array = data.split('-')
      if(array.length === 2){
        this.mostrarModalError = true
        this.descripcionError = "loadspin"
        const curp = atob(array[0])
        const uuid = array[1]
        const responseLlaves = await obtenerLlavesAsimetricas().then(r => r).catch(e => e)
        if(responseLlaves.status === 200){
          const publicKey = responseLlaves.data.data.publicKey
          const response = await buscarProspecto(curp, uuid, publicKey).then(r => r).catch(e => e)
          if( response.status === 200 ){
            const fechaExpira = new Date(response.data.data.expiryDate)
            if(fechaExpira < new Date()){
              this.$router.replace("/paginaExpirada")
            }else{
              const objeto = this.decifrarObjeto(response.data.data, responseLlaves.data.data.privateKey)
              this.agregarObjPropecto(objeto, uuid)
              Cookies.set('token', true)
              this.$router.replace('/prestapro/VerificaCelular')
            }
          } else if(response.codigo === 400){
            this.$router.replace("/paginaExpirada")
          }else{
            this.mostrarModalError = responseLlaves.mostrarModal
            this.descripcionError = responseLlaves.descripcion
          }
        } else {
          this.mostrarModalError = responseLlaves.mostrarModal
          this.descripcionError = responseLlaves.descripcion
        }
      }
    }
  },
  methods: {
    ...mapActions(["agregarValorProspecto"]),
    decifrarObjeto(objeto, llavePrivada){
      const objCifrado = {
        name : objeto.name,
        secondName: objeto.secondName,
        paternalSurname: objeto.paternalSurname,
        maternalSurname: objeto.maternalSurname,
        executiveUser: objeto.executiveUser,
        socialSecurityNumber: objeto.socialSecurityNumber,
        folioImss: objeto.folioImss
      }
      const objDecifrado = decryptObject(objCifrado, llavePrivada)
      return {...objeto, ...objDecifrado}
    },
    reload(){
      location.reload()
    },
    agregarObjPropecto(obj, uuid){
      const curp = obj.curp
      this.$store.commit('agregarValorProspecto', {
        curp: curp,
        fechaNacimiento: `${curp.substring(8,10)}/${curp.substring(6,8)}/19${curp.substring(4,6)}`,
        celular: obj.phoneNumber,
        correo: obj.email,
        capacidadDescuento: obj.discountCapacity,
        plazo: obj.instalment,
        monto: obj.amount,
        folio: obj.folio,
        tasa: parseFloat(obj.rate),
        contratosReestructura: obj.contract,
        folioImss: obj.folioImss,
        primerNombre: atob(obj.name),
        segundoNombre: atob(obj.secondName),
        apellidoPaterno: atob(obj.paternalSurname),
        apellidoMaterno: atob(obj.maternalSurname),
        nss: obj.socialSecurityNumber,
        codigo: obj.token,
        ejecutivo: obj.executiveUser,
        uid: uuid
      })
    },
    validar(error, type) {
      if (error) {
        if (type == "user") {
          this.usertheme = "showError";
          this.userthemec = "errorText";
          this.mailOk = true
        } else {
          this.passwordtheme = "showError";
          this.passwordthemec = "errorText";
          this.mailOk = true
        }
      } else if (type != undefined) {
        if (type == "user") {
          this.usertheme = "hideError";
          this.userthemec = "normalText";
        } else {
          this.passwordtheme = "hideError";
          this.passwordthemec = "normalText";
        }
      }
    },
    verificarCajaTexto(prop) {
      const { username, password } = this;
      if(prop === 'user'){ 
        this.userDisabled = true;
        if (username.length > 0 && username.length < 6) {
          this.text.user = "Dato Incorrecto"
          this.validar(true, prop);
        }else if (username === "") {
          this.text.user = "Dato Obligatorio"
          this.validar(true, prop);
        } else {
          this.userDisabled = false;
          this.validar(false, prop);
        }
      } else if(prop === 'pass'){
        this.passwordDisabled = true;
        if (password.length > 0 && password.length < 9) {
          this.text.password = "Dato Incorrecto"
          this.validar(true, prop);
        } else if (password === "") {
          this.text.password = "Dato Obligatorio"
          this.validar(true, prop);
        } else {
          this.passwordDisabled = false;
          this.validar(false, prop);
        }
      }
      if (!this.userDisabled && !this.passwordDisabled) { 
        this.mailOk = false
      }
    },
    validarCaracterEspecial(e) {
      if (/^\W$/.test(e.key)) {
        e.preventDefault();
      }
    },
    login() {
      if (this.loginValido()) {
        this.descripcionError = "loadspin"
        this.mostrarModalError = true;
        
        generarToken()
        .then((t) => {
          Cookies.set("token", t.data.access_token);
          login(this.username, this.password)
          .then((userResponse) => {
            this.descripcionError = null
            this.mostrarModalError = false;
            if (userResponse.data.acceso) {
              definirUsuario(userResponse.data.descripcion)
              this.$store.commit('agregarValorProspecto', { ejecutivo: this.username })
              this.$router.replace("/prospeccion/busquedaProspecto");
            } else {
              this.mensajeErr = userResponse.data.Errores[0].Mensaje;
              this.mostrarMensajeError(3000, userResponse.data.descripcion);
            }
          })
          .catch(() => {
            Cookies.remove("token");
            this.mostrarMensajeError(3000, "Usuario no válido");
          });
        })
        .catch(() => {
          Cookies.remove("token");
          this.mostrarMensajeError(3000, "Error de acceso");
        });
      } else {
        this.mostrarMensajeError(3000, "Ingresa usuario y contraseña");
      }
    },
    loginValido() {
      return this.username !== "" && this.password !== "";
    },
    mostrarMensajeError(tiempo = 3000, mensaje = "") {
      this.mostrarError = true;
      if (mensaje.includes("SSO3001F")) {
        var msg = mensaje.split("SSO3001F:");
        this.mensajeError = msg;
      } else {
        this.mensajeError = mensaje;
      }

      setTimeout(() => {
        this.mostrarError = false;
        this.mensajeError = "";
      }, tiempo);
    },
  },
  watch: {
    username(){
      this.verificarCajaTexto("user")
    },
    password(){
      this.verificarCajaTexto("pass")
    }
  }
};
</script>

<style scoped>

.text-align{
  text-align: left;
}
.left{
  display: flex;
  float: left;
}

.tooltip-input{
  display: flex;
  float: right;
}

.modal-error{
  z-index: 9999;
}
/deep/ .modal{
  z-index: 9999;
}
/deep/ .modal-mask {
  position: fixed;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(248, 248, 248, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
/deep/ .modal-backdrop {
  z-index: -1;
  background-color: #fff;
  opacity: 0.9;
  -webkit-filter: blur(8px);
  -moz-filter: blur(8px);
  -o-filter: blur(8px);
  -ms-filter: blur(8px);
  filter: blur(8px);
}
/deep/ .modal-container {
  width: auto;
  max-width: 350px;
  background-color: #ffffff8c;
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  border: none;
}
/deep/ .modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border: none;
}
/deep/ .modal-header .close {
  border: none;
  color: #0181c4;
  background: white;
  font-size: 44px;
}
/deep/ .modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  align-self: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border: none;
  margin-top: 80px;
}
.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}
.modal-body {
  margin: 20px 0 80px 0;
}
.modal-default-button {
  float: right;
}
.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 0;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
/deep/ .modal-body {
  position: relative;
  font-size: 21px;
  color: #0181c4;
  margin: 0 0 62px 0;
}
/deep/ .btn-primary:hover {
  color: #fff;
  background-color: #004b8d;
  border-color: #007bff;
  border-radius: 50px;
}
/deep/ .btn-primary {
  color: #fff;
  background-color: #004b8d;
  border-color: #007bff;
  border-radius: 50px;
}

.btn-primary:hover {
  color: #fff;
  background-color: #004b8d;
  border-color: #007bff;
  border-radius: 50px;
}
.btn-primary {
  color: #fff;
  background-color: #004b8d;
  border-color: #007bff;
  border-radius: 50px;
}

.icon-warning {
  margin: 3rem 0;
}
</style>
