<template>
  <div>
    <Menu :cliente="true" :atras="false"/>
    <ModalSesion :mostrar="mostrarModalSesion" />
    <div class="container">
      <div class="row">
        <div class="col-md-5 mx-auto">
          <div id="second">
            <div class="myform form" style="margin-top: 1%">
              
                <div class="form-group">
                  <div class="logo mb-3 pl-0 pr-0 pt-3 colorf">
                    <label>Tu INE/IFE</label>
                  </div>
                </div>

                <div class="form-group">
                  <b-row>
                    <b-col
                      ><label class="responsepros">
                       <br/>
                        <img :src="foto" alt="foto-ine" />
                        <br /></label
                    ></b-col>
                  </b-row>
                </div>

                <div class="form-group">
                  <p class="text-center">
                   <br/>
                    <a href="#" @click="replaceOCR" id="signup"
                      >Reemplazar documento</a
                    >
                    <br/>
                  </p>
                </div>
                <div class="form-group">
                  <b-row>
                    <b-col>
                      Declaro bajo protesta de decir verdad que los datos
                      proporcionados son ciertos y que los documentos enviados
                      durante el proceso son auténticos y tomados del
                      original
                      </b-col>
                    <br/>
                    <br/>
                  </b-row>
                </div>

                <div class="col-md-12 text-center mb-3 pt-4">
                  <button
                    type="button"
                    class="btn btn-block mybtn btn-primary"
                    @click="redirect"
                  >
                    Continuar
                  </button>
                </div>
                <div class="col-md-150"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Menu from '../genericos/Menu.vue'
import { mapGetters } from 'vuex'
import ModalSesion from '../genericos/ModalSesion.vue'
import Cookies from 'js-cookie'

export default {
  computed: {
    ...mapGetters(['obtenerPrestaPro'])
  },
  components: {
    Menu,
    ModalSesion
  },
  data: function () {
    return {
      mostrarModalSesion: false,
      foto: null,
    }
  },
  mounted() {
    this.foto = this.obtenerPrestaPro.imgFrente
  },
  methods: {
    replaceOCR() {
      this.$router.replace('/prestapro/ineFrente')
    },
    redirect(){
      this.$router.replace('/prestapro/resultadoIne')
    },
  },
  beforeMount(){
    if(!Cookies.get('token')){
      this.mostrarModalSesion = true
    }
  }
}

</script>

<style scoped>

</style>