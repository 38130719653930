import axios from '../plugins/axios'

const URL_SERVER = process.env.VUE_APP_END_POINT_API

export const obtenerCotizacion = (urlParametro) => {
    return axios.get(`${URL_SERVER}/prestamos/1/gestion-tramites/cotizador?tipoCotizacion=1&fechaNacimiento=${urlParametro.fechaNacimiento}&ingresosMensuales=0&pensionHijos=0&montoPensionHijos=0&tipoPension=1&capacidadPagoInforme=${urlParametro.capacidadDescuento}&montoPrestamo=0&seguroGastosFunerarios=false&esReestructura=${urlParametro.reestructura}&contratosReestructura=${urlParametro.folio}`)
}

export const guardarSolicitud = ( body ) => {
    return axios.post(`${URL_SERVER}/prestamos/1/gestion-tramites/solicitud2`, body)
}

export const expediente = ( body ) => {
    return axios.post(`${URL_SERVER}/prestamos/1/gestion-tramites/expediente`, body)
}

export const cancelarFolio = ( folio, comentario ) => {
    const date = new Date()
    const dia = date.getDate()
    const mes = date.getMonth() + 1
    const mesString = (mes < 10) ? ("0" + mes) : mes
    const dateString = (dia < 10) ? ("0" + dia) : dia  + "-" + mesString + "-" + date.getFullYear()
    return axios.post(`${URL_SERVER}/prestamos/1/gestion-tramites/cancelacion`, {
        folio: folio,
        fecha: dateString,
        comentario: comentario
    })
}

export const obtenerDocumentos = ( folio ) => {
    return axios.get(`${URL_SERVER}/prestamos/1/gestion-tramites/Documentos?Folio=${folio}`)
}

export const obtenerDocumentosIn = ( body ) => {
    return axios.post(`${URL_SERVER}/prestamos/1/gestion-tramites/Documentos`, body)
}