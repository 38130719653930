<template>
  <body>
    <Menu :cliente="false" titulo="Prospección" :atras="false" />
    <ModalSesion :mostrar="mostrarModalSesion" />
    <div class="container">
      <div class="row">
        <div class="col-md-5 mx-auto">
          <div id="second">
            <div class="myform form" style="margin-top: 1%">
              <form
                action
                name="registration"
                @submit.prevent="buscarProspectoCURP"
              >
                <div class="form-group">
                  <div class="logo mb-3 pl-0 pr-0 pt-3 colorf">
                    <label>Ingresa CURP para localizar al prospecto</label>
                  </div>
                </div>
                <div class="left">CURP:</div>
                <div class="form-group">
                  <input
                    type="text"
                    name="curpName"
                    id="curpId"
                    class="form-control"
                    :class="curptext"
                    maxlength="18"
                    v-model="curp"
                    @keypress="soloNumerosLetras"
                  />
                </div>

                <div :class="curptheme">
                  {{ text }}
                </div>
                <div class="form-group" v-if="edadminima">
                  <br/>
                  <b-alert
                    v-bind:show="edadminima"
                    variant="danger"
                    @dismissed="edadminima = false"
                    >Candidato no alcanza edad mínima</b-alert
                  >
                </div>
                <div class="form-group" v-if="formatoCurpInvalido">
                  <br />
                  <b-alert
                    v-bind:show="formatoCurpInvalido"
                    variant="danger"
                    @dismissed="formatoCurpInvalido = false"
                    >Formato de CURP inváildo</b-alert
                  >
                </div>

                <div class="col-md-12 text-center mb-3 pt-4">
                  <button
                    :disabled="btndisabled"
                    type="submit"
                    class="btn btn-block mybtn btn-primary"
                  >
                    Continuar
                  </button>
                </div>
                <div class="col-md-12"></div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      align="center"
      content-class="modal-container"
      id="modal-prospectoInvalido"
      ok-only
      @ok="redirectOk"
      ok-title="Continuar"
      @close="redirectOk"
      :no-close-on-backdrop="true"
    >
      <b-container fluid>
        <div class="icon-warning">
          <img
            src="@/assets/img/icn-70-px-general-alert.svg"
            style="margin-bottom: 40px"
            alt="img-alerta"
          />
        </div>
        <b-row class="mb-1">
          <b-col
            ><p class="modal-text-color"></p>
            Candidato no sujeto a préstamo</b-col
          >
        </b-row>
      </b-container>
    </b-modal>

    <b-modal
      align="center"
      content-class="modal-container"
      id="modal-menor80"
      ok-only
      @ok="redirectOk"
      ok-title="Continuar"
      @close="redirectOk"
      :no-close-on-backdrop="true"
    >
      <b-container fluid>
        <div class="icon-warning">
          <img
            src="@/assets/img/icn-70-px-general-alert.svg"
            style="margin-bottom: 40px"
            alt="img-alerta"
          />
        </div>
        <b-row class="mb-1">
          <b-col
            ><p class="modal-text-color"></p>
            Candidato no sujeto a préstamo, excede el límite de edad</b-col
          >
        </b-row>
      </b-container>
    </b-modal>

    <b-modal
      content-class="modal-container"
      id="modal-prospectoNuevo"
      ok-only
      ok-title="Aceptar"
      align="center"
      @close="registrarNuevoProspecto"
      :no-close-on-backdrop="true"
    >
      <b-container fluid>
        <b-row class="mb-1">
          <b-col>
            <p class="modal-text-color"></p>
            No se encontró<br />
            prospecto en la base de <br />
            datos de Profuturo. <br /><br />
            Registrarlo para <br />continuar</b-col
          >
        </b-row>
      </b-container>
      <template #modal-footer>
        <div class="col-md-6 text-center mb-3 pt-2"></div>

        <div class="col-md-12 text-center mb-3 pt-4">
          <button
            @click="registrarNuevoProspecto"
            type="button"
            class="btn btn-block mybtn btn-primary"
          >
            Continuar
          </button>
        </div>
      </template>
    </b-modal>
    <ModalError :mostrar="mostrarModalError" :descripcion="descripcionError" />
  </body>
</template>

<script>
import { obtenerInformacionProspectoCurp } from "../../services/prospecto";
import ModalError from "../genericos/ModalError.vue";
import Menu from "../genericos/Menu.vue";
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import { caracteresNoPermitidos, alfabeto } from "../../constants/cajaTexto";
import { eliminarCaracteresExpeciales } from "../../utils/formatoTexto";
import Cookies from "js-cookie";
import ModalSesion from "../genericos/ModalSesion.vue";

const expresionCurp = /[A-Z]{4}\d{6}[HM]{1}[A-Z]{2}[BCDFGHJKLMNPQRSTVWXYZ]{3}([A-Z]{2})?([ABCDEFGHIJKLMNOPQRSUVWXYZ0-9]{1})(\d{1})/;

export default {
  name: "BusquedaProspecto",
  components: {
    ModalError,
    Menu,
    ModalSesion,
  },
  computed: {
    ...mapGetters(["obtenerProspecto"]),
  },
  data: () => ({
    mostrarModalSesion: false,
    mostrarModalError: false,
    descripcionError: null,
    curp: "",
    candidatoInvalido: false,
    campoInvalido: false,
    btndisabled: true,
    edad: "",
    edadminima: false,
    formatoCurpInvalido: false,
    curptheme: "",
    curptext: "",
    text: "",
    bandera: false,
  }),
  methods: {
    ...mapActions(["definirDatosProspecto"]),
    validacionCurp() {
      this.curp = this.curp.toUpperCase();
      const dia = `${this.curp.substring(4, 5)}`;
      const dia1 = `${this.curp.substring(5, 6)}`;
      const penDig = `${this.curp.substring(17, 16)}`;
      this.candidatoInvalido = false;
      this.campoInvalido = false;
      let fechaNacimiento = parseInt(this.curp.charAt(4)) > 0 ? "19" : "20";
      fechaNacimiento = fechaNacimiento + this.curp.substring(4, 10);
      let edad = parseInt(
        moment().diff(moment(fechaNacimiento, "YYYYMMDD"), "years")
      );
      const now = moment().format("YY");
      if (isNaN(parseInt(penDig))) {
        if(alfabeto.includes(penDig)){
          if(edad > 99){
            edad = edad -100
          }
          if(edad >= now && parseInt(dia) !== 0 && parseInt(dia1) !== 0){
            edad = now - edad;
          }
        }else{
          edad = -1
        }
      }
      if (edad >= 80 && this.curp.length === 18) {
        this.curptheme = "";
        this.text = "";
        this.campoInvalido = true;
        this.curp = "";
        this.$bvModal.show("modal-menor80");
      } else if (edad < 18 && edad >= 0) {
        this.$bvModal.show("modal-prospectoInvalido");
        this.curp = ''
        this.btndisabled = true;
        
      }else if(edad < 0){
        this.curptheme = "showError";
        this.text = "Dato Incorrecto";
        this.campoInvalido = true;
        this.btndisabled = true;
      }else{
        this.curptheme = "";
        this.text = "";
        this.campoInvalido = false;
        this.btndisabled = false;
      }
    },
    soloNumerosLetras($e) {
      if (caracteresNoPermitidos.includes($e.charCode)) {
        $e.preventDefault();
      }
    },
    redirectOk() {
      this.mostrarModalError = false;
      this.curptheme = "";
      this.text = "";
    },
    buscarProspectoCURP() {
      this.descripcionError = "loadspin";
      this.$bvModal.show("modalError");
      this.$store.commit("limpiarValoresProspecto");
      this.$store.commit("limpiarValoresPrestaPro");

      obtenerInformacionProspectoCurp(this.curp.toUpperCase())
        .then((respuestaProspecto) => {
          this.mostrarModalError = false;
          this.descripcionError = "";
          const codigoError = respuestaProspecto.data.Errores[0];
          let curpFinal = this.curp.toUpperCase();
          if (codigoError.Codigo === 1010001) {
            const {
              primerNombre,
              segundoNombre,
              apellidoPaterno,
              apellidoMaterno,
              nss,
              celular,
              correo,
              contratos,
            } = respuestaProspecto.data;
            this.$store.commit("agregarValorProspecto", {
              primerNombre: primerNombre,
              segundoNombre: segundoNombre,
              apellidoPaterno: apellidoPaterno,
              apellidoMaterno: apellidoMaterno,
              nss: nss,
              curp: curpFinal.toUpperCase(),
              celular: celular,
              correo: correo,
              contratos: contratos,
              fechaNacimiento: `${curpFinal.substring(
                8,
                10
              )}/${curpFinal.substring(6, 8)}/19${curpFinal.substring(4, 6)}`,
            });
            const contratosSinTerminar = respuestaProspecto.data.contratos.filter(
              (contrato) => contrato.estatusContrato !== "Terminado"
            );
            if (contratosSinTerminar.length === 0) {
              this.registrarNuevoProspecto("plazo");
            } else {
              if (respuestaProspecto.data.contratos.length === 1) {
                if (
                  respuestaProspecto.data.contratos[0].estatusContrato ===
                  "Atraso"
                ) {
                  this.curp = "";
                  this.mostrarModalError = false;
                  this.curptheme = "";
                  this.text = "";
                  this.$bvModal.show("modal-prospectoInvalido");
                  this.$bvModal.hide("modalError");
                } else if (
                  respuestaProspecto.data.contratos[0].estatusContrato ===
                  "Terminado"
                ) {
                  this.registrarNuevoProspecto("plazo");
                } else {
                  this.definirDatosProspecto(respuestaProspecto.data);
                  this.registrarNuevoProspecto("contrato");
                }
              } else if (respuestaProspecto.data.contratos.length > 0) {
                let atraso;
                this.definirDatosProspecto(respuestaProspecto.data);
                respuestaProspecto.data.contratos.map((contrato) => {
                  if (contrato.estatusContrato === "Atraso") {
                    this.curp = "";
                    this.mostrarModalError = false;
                    this.curptheme = "";
                    this.text = "";
                    atraso = true;
                  }
                });
                if (atraso !== true) {
                  this.registrarNuevoProspecto("contrato");
                } else {
                  this.$bvModal.show("modal-prospectoInvalido");
                  this.$bvModal.hide("modalError");
                }
              } else {
                this.registrarNuevoProspecto("plazo");
              }
            }
          } else if (
            codigoError.Codigo === 1080008 ||
            codigoError.Codigo === 1111003
          ) {
            this.$store.commit("agregarValorProspecto", {
              curp: curpFinal,
              fechaNacimiento: `${curpFinal.substring(
                8,
                10
              )}/${curpFinal.substring(6, 8)}/19${curpFinal.substring(4, 6)}`,
            });
            this.registrarNuevoProspecto("");
          } else {
            this.curp = "";
            this.curptheme = "";
            this.text = "";
            this.$bvModal.show("modalError");
            this.bandera = true;
            this.descripcionError = codigoError.Mensaje;
          }
        })
        .catch((e) => {
          const { descripcion, showModal } = e;
          if (showModal) {
            this.$bvModal.show("modalError");
          } else {
            this.$bvModal.hide("modalError");
          }
          this.descripcionError = descripcion;
        });
    },
    registrarNuevoProspecto(ruta) {
      this.$router.replace(
        `/prospeccion/registroProspecto/${this.curp}?direct=${ruta}`
      );
    },
    regresarProspecto() {
      this.$bvModal.hide("modal-prospectoNuevo");
    },
  },
  watch: {
    curp(value) {
      this.curp = eliminarCaracteresExpeciales(value);
      this.btndisabled = true;
      if (value.length === 0) {
        if (this.bandera) {
          this.bandera = false;
        } else {
          this.curptext = "errorText";
          this.text = "Dato Obligatorio";
          this.curptheme = "showError";
        }
      } else if (value.length === 18) {
        this.curp = value.toUpperCase();
        if (expresionCurp.test(this.curp)) {
          this.curp = value.toUpperCase();
          this.curptext = "";
          this.text = "";
          this.curptheme = "";
          this.validacionCurp();
        } else {
          this.curptext = "errorText";
          this.text = "Dato Incorrecto";
          this.curptheme = "showError";
        }
      } else {
        this.curptext = "errorText";
        this.text = "Dato Incorrecto";
        this.curptheme = "showError";
      }
    },
  },
  beforeMount() {
    if (!Cookies.get("token")) {
      this.mostrarModalSesion = true;
    }
  },
};
</script>

<style scoped>
.left {
  display: table-cell;
}
/deep/ .modal {
  z-index: 9999;
}
/deep/ .modal-mask {
  position: fixed;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(248, 248, 248, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
/deep/ .modal-backdrop {
  z-index: -1;
  background-color: #fff;
  opacity: 0.9;
  -webkit-filter: blur(8px);
  -moz-filter: blur(8px);
  -o-filter: blur(8px);
  -ms-filter: blur(8px);
  filter: blur(8px);
}
/deep/ .modal-container {
  width: auto;
  max-width: 350px;
  background-color: #ffffff8c;
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  border: none;
}
/deep/ .modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border: none;
}
/deep/ .modal-header .close {
  border: none;
  color: #0181c4;
  background: white;
  font-size: 44px;
}
/deep/ .modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  align-self: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border: none;
  margin-top: 120px;
}
.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}
.modal-body {
  margin: 20px 0 80px 0;
}
.modal-default-button {
  float: right;
}
.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 0;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
/deep/ .modal-body {
  position: relative;
  font-size: 21px;
  color: #0181c4;
  margin: 0 0 62px 0;
}
/deep/ .btn-primary:hover {
  color: #fff;
  background-color: #004b8d;
  border-color: #007bff;
  border-radius: 50px;
}
/deep/ .btn-primary {
  color: #fff;
  background-color: #004b8d;
  border-color: #007bff;
  border-radius: 50px;
}

.btn-primary:hover {
  color: #fff;
  background-color: #004b8d;
  border-color: #007bff;
  border-radius: 50px;
}
.btn-primary {
  color: #fff;
  background-color: #004b8d;
  border-color: #007bff;
  border-radius: 50px;
}

.icon-warning {
  margin: 1rem 0;
}
</style>
