<template>
  <body>
    <Menu :cliente="true" :atras="false" />
    <ModalSesion :mostrar="mostrarModalSesion" />
    <div class="container">
      <div class="row">
        <div class="col-md-5 mx-auto">
          <div id="second">
            <div class="myform form" style="margin-top: 1%">
              <form action="#" name="registration" @submit.prevent="verificar">
                <div class="form-group">
                  <div class="logo mb-3 pl-0 pr-0 pt-3 colorb">
                    <br />
                    <label
                      >Ingresa el código que se te envió a tu celular
                    </label>
                    <br />
                  </div>

                  <div class="row left">
                    <div class="col-md-auto ">
                      <div><Tooltip :texto="'El número a ingresar es de 6 dígitos.'" :enlace="'tooltip-codigo'" /></div>
                      <label class="label-left">Código:</label>
                    </div>
                  </div>
                  <div class="cred">
                    {{ "00:" + countTime }}
                  </div>
                  <div class="input-group">
                    <input
                      type="text"
                      class="form-control input-tooltip-space"
                      aria-describedby="emailHelp"
                      placeholder=""
                      minlength="6"
                      maxlength="6"
                      :class="curptext"
                      :disabled="disabledcode"
                      id="verifica"
                      pattern="[0-9]+"
                      @keypress="isNumber"
                      @blur="check1"
                      v-model="monto"
                    />
                    <img src="@/assets/img/exclamation-circle-solid.svg" id="tooltip-codigo" width="35" height="35" alt="tooltip-codigo" class="tooltip-input-rigth" />
                  </div>
                </div>
                <div :class="curptheme">
                  {{ text }}
                </div>
                <br />
                <p style="font-color:gray">
                  <a
                    href="#"
                    class="unde"
                    :class="{disabled: btnDisabled}"
                    @click="codigo"
                    id="signup"
                    >Reenviar Código</a
                  >
                </p>

                <div class="col-md-12 text-center mb-3 pt-4">
                  <button
                    type="submit"
                    class="btn btn-block mybtn btn-primary"
                    :disabled="disabled1"
                    id="button"
                  >
                    Continuar
                  </button>
                </div>
                <div class="col-md-12"></div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      content-class="modal-container"
      id="modal-codigo-invalido"
      ok-only
      ok-title="Continuar"
      align="center"
      @close="onClose"
    >
      <b-container fluid>
        <b-row class="mb-1">
          <img
            src="@/assets/img/icn-70-px-general-alert.svg"
            alt="img-general"
            width="60"
            height="60"
          />
          <br />
          <br />
          <br />
          <b-col>
            <p class="modal-text-color"></p>
            Código Incorrecto,<br />
            favor de verificarlo
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </body>
</template>

<script>
import Menu from "../genericos/Menu.vue"
import ModalSesion from '../genericos/ModalSesion.vue'
import { mapGetters } from "vuex"
import { validarSoloNumeros } from "../../utils/formatoTexto"
import Cookies from "js-cookie"
import { reenviarCodigo } from '../../services/tcs'
import Tooltip from '../genericos/Tooltip.vue'

export default {
  name: "EnvioCodigo",
  components: {
    Menu,
    ModalSesion,
    Tooltip
  },
  data: () => ({
    mostrarModalSesion: false,
    celular: true,
    correo: true,
    monto: "",
    renvio: false,
    clase: "creg",
    disabledcode: false,
    disabled1: true,
    curptheme: "",
    curptext: null,
    text: "",
    countDown: 60,
    countTime: 60,
    clave: "",
    informacionProspecto: true,
    btnDisabled: true,
    esContadorActivo:true,
    inicio: false
  
  }),
  computed: {
    ...mapGetters(["obtenerProspecto"]),
  },
  created() {
    this.countDownTimer();
  },
  async mounted() {
    this.$router.replace("/prestapro/ineFrente");
    if (this.obtenerProspecto.codigo) {
      this.clave = this.obtenerProspecto.codigo;
      this.informacionProspecto = this.obtenerProspecto;
      if(!Cookies.get('token')){
        this.mostrarModalSesion = true
      }
    }
    if(!Cookies.get('token')){
      this.mostrarModalSesion = true
    }
  },
  methods: {
    onClose() {
      this.monto = "";
      this.$bvModal.hide("modal-codigo-invalido");
      this.disabled1 = true;
    },
    verificar() {
      if (this.monto === this.clave) {
        this.$store.commit("agregarValorPrestaPro", this.informacionProspecto);
        this.$router.replace("/prestapro");
      } else {
        this.monto = "";
        this.disabled1 = true;
        this.$bvModal.show("modal-codigo-invalido");
      }
    },
    countDownTimer() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countTime = this.countDown;
          if (this.countDown < 10) {
            
            this.countTime = "0" + this.countDown;
          }
          this.esContadorActivo = true;
          this.countDownTimer();
          
        }, 1000);

        if( this.inicio ){
          this.check1()
        }
      } else {
        this.esContadorActivo = false;
        this.disabledcode = true;
        this.btnDisabled = false;
        this.clase = "cren";
        this.disabled1 = true
      }
    },
    async codigo() {
      if(!this.esContadorActivo){
        this.inicio = true
        this.btnDisabled=true
        this.disabledcode = false
        this.esContadorActivo = true
        this.DisabledRennvio= true
        this.clase = "creg"
        this.countDown = 60
        this.countDownTimer()
        await reenviarCodigo(this.obtenerProspecto.celular, this.clave).then(r => r).catch(e => e)
      }
    },
    add(){
      this.btnDisabled = true;
      this.codigo();
    },
    isNumber($event) {
      if (!($event.charCode >= 48 && $event.charCode <= 57)) {
        $event.preventDefault();
      }
    },
    check1() {
      if(this.monto === ""){
        this.curptext = "errorText"
        this.curptheme = "showError"
        this.text = "Dato Obligatorio"
        this.disabled1 = true;
      } else if (this.monto.length <= "5"){
        this.curptext = "errorText"
        this.curptheme = "showError"
        this.text = "Dato Incorrecto"
        this.disabled1 = true;
      } else {
        this.curptext = "normalText";
        this.curptheme = "hideError";
        this.disabled1 = false;
      }
    },
  },
  watch: {
    monto(value) {
      this.monto = validarSoloNumeros(value);
      this.check1()
    },
  },
};
</script>
<style scoped>
.btn-primary {
  color: #fff;
  background-color: #004b8d;
  border-color: #007bff;
  border-radius: 50px;
  height: 40px;
  width: 200px;
}
.left {
  display: table-cell;
  position: absolute;
}

/deep/ .modal{
  z-index: 9999;
}
/deep/ .modal-mask {
  position: fixed;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(248, 248, 248, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
/deep/ .modal-backdrop {
  z-index: -1;
  background-color: #fff;
  opacity: 0.9;
  -webkit-filter: blur(8px);
  -moz-filter: blur(8px);
  -o-filter: blur(8px);
  -ms-filter: blur(8px);
  filter: blur(8px);
}
/deep/ .modal-container {
  width: auto;
  max-width: 350px;
  background-color: #ffffff8c;
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  border: none;
}
/deep/ .modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border: none;
}
/deep/ .modal-header .close {
  border: none;
  color: #0181c4;
  background: white;
  font-size: 44px;
}
/deep/ .modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  align-self: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border: none;
  margin-top: 120px;
}
.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}
.modal-body {
  margin: 20px 0 80px 0;
}
.modal-default-button {
  float: right;
}

.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 0;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
/deep/ .modal-body {
  position: relative;
  font-size: 21px;
  color: #0181c4;
  margin: 0 0 62px 0;
}
/deep/ .btn-primary:hover {
  color: #fff;
  background-color: #004b8d;
  border-color: #007bff;
  border-radius: 50px;
}
/deep/ .btn-primary {
  color: #fff;
  background-color: #004b8d;
  border-color: #007bff;
  border-radius: 50px;
}

.btn-primary:hover {
  color: #fff;
  background-color: #004b8d;
  border-color: #007bff;
  border-radius: 50px;
}
.btn-primary {
  color: #fff;
  background-color: #004b8d;
  border-color: #007bff;
  border-radius: 50px;
}

.icon-warning {
  margin: 3rem 0;
}

.disabled {
  cursor: not-allowed;
  color: gray !important;
}

.label-left{
  display: flex;
  padding-left: 5px;
}

.tooltip-input{
  display: flex;
  float: left;
  margin-top: 3px;
}
.tooltip-input-rigth{
  margin: 1px;
  margin-left: 10px;

}
.input-tooltip-space{
  margin-right: 3px;
}
</style>
