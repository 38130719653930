<template>
  <body>
    <Menu :cliente="true" :pathAnterior="'/prestapro/capturaCartaLibranza'"/>
    <ModalError :mostrar="mostrarModalError" :descripcion="descripcionError" />
    <ModalSesion :mostrar="mostrarModalSesion" />
    <div class="container">
      <div class="row">
        <div class="col-md-5 mx-auto">
          <div id="second">
            <div class="myform form" style="margin-top: 1%">
              <form>
                <div class="form-group">
                  <div class="logo mb-3 pl-0 pr-0 pt-3 colorf">
                    <label>Carta libranza</label>
                  </div>
                </div>

                <div class="form-group">
                  <b-row>
                    <b-col
                      ><label class="responsepros">
                        <img v-bind:src="base64" alt="img-carta-libranza"/>
                        <br /></label
                    ></b-col>
                  </b-row>
                </div>

                <div class="form-group">
                  <p class="text-center">
                    <a href="#" @click="preview" id="signup"
                      >Reemplazar documento</a
                    >
                  </p>
                </div>
                <div class="form-group">
                  <b-row>
                    <b-col>
                      Declaro bajo protesta de decir verdad que los datos
                      proporcionados son ciertos y que los documentos enviados
                      durante el proceso son auténticos y tomados del
                      original</b-col
                    >
                  </b-row>
                </div>

                <div class="col-md-12 text-center mb-3 pt-4">
                  <button
                    type="button"
                    @click="redirect"
                    class="btn btn-block mybtn btn-primary"
                  >
                    Continuar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </body>
</template>

<script>
import { mapGetters } from "vuex";
import Menu from "../genericos/Menu.vue";
import ModalError from '../genericos/ModalError.vue'
import Cookies from 'js-cookie';
import ModalSesion from '../genericos/ModalSesion.vue'
import { expediente } from '../../services/prestapro'

export default {
  components: {
    Menu,
    ModalError,
    ModalSesion
  },
  mounted() {
    this.base64 = this.obtenerPrestaPro.imgCartaLibranza;
  },
  data: () => ({
    mostrarModalSesion: false,
    base64: "",
    mostrarModalError:false,
    descripcionError: null
  }),
  computed: {
    ...mapGetters(['obtenerPrestaPro'])
  },
  methods: {
    preview() {
      this.$router.replace("/prestapro/CapturaCartaLibranza");
    },
    async redirect() {
      this.descripcionError = "loadspin"
      this.$bvModal.show("modalError")
      const objBodyDomicilio = {
        Folio: this.obtenerPrestaPro.folio,
        IdTipoDocumento: 2,
        Nombre: "COMPROBANTE DE DOMICILIO",
        MIME: "image/jpeg",
        Documento: (this.obtenerPrestaPro.imgComprobanteDomicilio) ? this.obtenerPrestaPro.imgComprobanteDomicilio.replace('data:image/jpeg;base64,', '') : this.obtenerPrestaPro.imgFrente.replace('data:image/jpeg;base64,', '')
      }
      const resComDomicilio = await expediente(objBodyDomicilio).then(r => r).catch(e => e)
      if(resComDomicilio.status === 200 && resComDomicilio.data.Errores[0].Codigo === 1010001){
        const objBodyCarta = {
          Folio: this.obtenerPrestaPro.folio,
          IdTipoDocumento: 4,
          Nombre: "CARTA LIBRANZA",
          MIME: "image/jpeg",
          Documento: this.obtenerPrestaPro.imgCartaLibranza.replace('data:image/jpeg;base64,', '')
        }
        const resCartaLibranza = await expediente(objBodyCarta).then(r => r).catch(e => e)
        if(resCartaLibranza.status === 200 && resCartaLibranza.data.Errores[0].Codigo === 1010001){
          this.descripcionError = null
          this.mostrarModalError = false
          this.$router.replace("/prestapro/_42_documentos_contractuales")
        } else {
          this.descripcionError = resCartaLibranza.data.Errores[0].Mensaje
          this.$bvModal.show("modalError")
        }
      }else{
        this.descripcionError = resComDomicilio.data.Errores[0].Mensaje
        this.$bvModal.show("modalError")
      }
    },
  },
  beforeMount(){
    if(!Cookies.get('token')){
      this.mostrarModalSesion = true
    }
  },
};
</script>
