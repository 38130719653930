export const obtenerParametro = ( key ) => {
 if( typeof key === 'string' ){
  const urlParametros = new URLSearchParams(window.location.search)
  return urlParametros.get( key )
 }
 return null;
}

export const obtenerRuta = () => {
 return window.location.pathname
}

