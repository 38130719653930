<template>
  <b-tooltip :target="enlace" triggers="hover" placement="auto" variant="primary" noninteractive="true">
    <label v-html="texto"></label>
  </b-tooltip>
</template>

<script>
export default {
  props: {
   texto: {
     type: String,
     default: "Probando el tooltip <b>Componente</b> contenido!",
   },
   enlace: {
    type: String,
    require: true
   }
  },
}
</script>

<style scoped>
/deep/.tooltip-inner {
  margin-right: 5px;
  background-color: #004b8d !important;
}
</style>