<template>
  <body>
    <Menu :cliente="true" titulo2="/prestapro/_29_1_comprobante_de_pago"/>
    <ModalSesion :mostrar="mostrarModalSesion" />
    <div class="container">
      <div class="row">
        <div class="col-md-5 mx-auto">
          <div id="second">
            <div class="myform form" style="margin-top: 1%">
              <form>
                <div class="form-group">
                  <div class="logo mb-3 pl-0 pr-0 pt-3 colorf">
                    <label>Comprobante de domicilio</label>
                  </div>
                </div>
                <div class="form-group">
                  <b-row>
                    <b-col
                      ><label class="responsepros">
                        <img v-bind:src="base64" alt="omg-base64"/>
                        <br /></label
                    ></b-col>
                  </b-row>
                </div>
                <div class="form-group">
                  <p class="text-center">
                    <a href="#" @click="preview" id="signup"
                      >Reemplazar documento</a
                    >
                  </p>
                </div>
                <div class="form-group">
                  <b-row>
                    <b-col>
                      Declaro bajo protesta de decir verdad que los datos
                      proporcionados son ciertos y que los documentos enviados
                      durante el proceso son auténticos y tomados del
                      original</b-col
                    >
                  </b-row>
                </div>

                <div class="col-md-12 text-center mb-3 pt-4">
                  <button
                    type="submit"
                    @click="redirect"
                    class="btn btn-block mybtn btn-primary"
                  >
                    Continuar
                  </button>
                </div>
                <div class="col-md-150"></div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </body>
</template>

<script>
import { mapGetters } from "vuex"
import Menu from '../genericos/Menu.vue'
import Cookies from 'js-cookie';
import ModalSesion from '../genericos/ModalSesion.vue'
export default {
  mounted() {
    this.base64 = this.obtenerPrestaPro.imgComprobanteDomicilio;
  },
  data: () => ({
    mostrarModalSesion: false,
    base64: "",
  }),
  components: {
    Menu,
    ModalSesion
  },
  computed: {
    ...mapGetters(['obtenerPrestaPro'])
  },
  methods: {
    preview() {
      this.$router.replace("/prestapro/_29_1_comprobante_de_pago");
    },
    redirect() {
      this.$router.replace("/prestapro/CapturaCartaLibranza");
    },
  },
  beforeMount(){
    if(!Cookies.get('token')){
      this.mostrarModalSesion = true
    }
  }
};
</script>
