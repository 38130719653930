<template>
  <body>
    <Menu :cliente="false" titulo="Prospección" :pathAnterior="pathAnterior"  />
    <ModalSesion :mostrar="mostrarModalSesion" />
    <div class="container" v-if="busquedaActiva">
      <div class="row">
        <div class="col-md-5 mx-auto">
          <div id="second">
            <div class="myform form" style="margin-top: 1%">
              <form
                name="registration"
                @submit.prevent="regresarInformacionProspecto"
              >
                <div class="form-group">
                  <div class="logo mb-3 pl-0 pr-0 pt-3 colorf">
                    <label>Contratos</label>
                  </div>
                </div>

                <div class="form-group">
                  <label>CURP del cliente:&nbsp;</label>
                  <label class="responsepros">{{ curp }}</label>
                </div>

                <div
                  class="accordion"
                  role="tablist"
                  v-for="(item, index) in contratos"
                  :key="index"
                >
                <div v-if="item.estatusContrato !== 'Terminado'">
                  <b-card no-body class="mb-1">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                      <div :style="{ display: 'flex', paddingTop: '10px' }">
                        <b-form-checkbox
                          name="checkbox-1"
                          :value="item.contrato"
                          :class="item.style"
                          :id="'idCheckboxUnique' + (index + 1)"
                          unchecked-value="not_accepted"
                          class="alinea"
                          :disabled="item.habilita"
                          v-model="folios"
                        >
                        </b-form-checkbox>
                        <b-button
                          block
                          v-b-toggle="'accordion-' + index"
                          @click="toggle = !toggle"
                          variant="info"
                          :class="
                            item.habilita
                              ? 'btnc text-left margen'
                              : 'btnc text-left'
                          "
                        >
                          <span class="when-opened">
                            <font-awesome-icon icon="chevron-down" />
                          </span>

                          <span class="when-closed">
                            <font-awesome-icon icon="chevron-right" />
                          </span>

                          <a class="reduct black" :class="item.color">
                            Contrato:</a
                          >
                          <a class="reduct rightalign" :class="item.color">
                            {{ item.contrato + " "
                            }}{{ item.estatusContrato }}</a
                          >
                        </b-button>
                      </div>
                    </b-card-header>
                    <b-collapse
                      :id="'accordion-' + index"
                      accordion="my-accordion"
                      role="tabpanel"
                    >
                      <b-card-body class="text-left">
                        <b-card-text>
                          <b-container class="bv-example-row">
                            <b-row>
                              <b-col>
                                <p class="pstyle reduct">
                                  Plazo del préstamo:
                                </p></b-col
                              >
                              <b-col cols="4 pstyler reduct gray">
                                {{ item.plazo }}</b-col
                              >
                            </b-row>

                            <b-row>
                              <b-col>
                                <p class="pstyle reduct">
                                  Saldo del préstamo:
                                </p></b-col
                              >
                              <b-col cols="4 pstyler reduct gray">
                                {{ item.saldoAlDia }}</b-col
                              >
                            </b-row>
                            <b-row>
                              <b-col>
                                <p class="pstyle reduct">
                                  Mensualidad del préstamo:
                                </p></b-col
                              >
                              <b-col cols="4 pstyler reduct gray">
                                {{ item.descMensual }}</b-col
                              >
                            </b-row>
                          </b-container>
                        </b-card-text>
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                  </div>
                </div>
                <div class="radio-boton">
                  <b-form-group label-align="rigth" v-slot="{ tipoContrato }">
                    <div :style="{ textAlign: 'initial' }">
                      <b-form-radio
                        v-model="selected"
                        :disabled="disabledNuevo"
                        :aria-describedby="tipoContrato"
                        id="inputRadioNuevo"
                        name="tipoContrato"
                        value="Nuevo"
                        >&nbsp;Nuevo</b-form-radio
                      >
                      <b-form-radio
                        v-if="reestructura"
                        :disabled="disabledRadio"
                        v-model="selected"
                        :aria-describedby="tipoContrato"
                        id="inputRadioRestructura"
                        name="tipoContrato"
                        value="Reestructura"
                        >&nbsp;Reestructura</b-form-radio
                      >
                    </div>
                  </b-form-group>
                </div>
                <div
                  class="col-md-12 text-center mb-3 pt-5"
                  id="divButtonAceptar"
                >
                  <button
                    :diabled="enabledbutton"
                    id="buttonAceptar"
                    type="submit"
                    class="btn btn-block mybtn btn-primary"
                  >
                    Continuar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </body>
</template>

<script>
import { obtenerInformacionProspectoCurp } from "../../services/prospecto";
import { mapActions, mapGetters } from "vuex";
import Menu from "../genericos/Menu.vue";
import Cookies from "js-cookie"
import ModalSesion from '../genericos/ModalSesion.vue'
import { obtenerParametro } from '../../utils/url'

export default {
  name: "Contratos",
  components: {
    Menu,
    ModalSesion,
  },
  computed: {
    ...mapGetters(["obtenerDatosProspecto"]),
  },
  data: () => ({
    mostrarModalSesion: false,
    contratos: [],
    contrato: [],
    busquedaActiva: true,
    toggle: false,
    curp: "",
    email: "",
    nombreProspecto: "",
    nssProspecto: "",
    celularProspecto: "",
    emailProspecto: "",
    estatusContrato: "Activo",
    curpInvalida: false,
    campoInvalido: false,
    btndisabled: true,
    edad: "",
    edadminima: false,
    filteredfaqs: [],
    tipoCreditoProspecto: "",
    enabledbutton: true,
    checkeds: true,
    selected: "Nuevo",
    reestructura: true,
    folios: [],
    disabledRadio: true,
    disabledNuevo: false,
    pathAnterior: ""
  }),
  watch: {
    folios(value) {
      if (value.length > 0) {
        this.selected = "Reestructura";
        this.disabledRadio = false;
        this.disabledNuevo = true;
      } else {
        this.selected = "Nuevo";
        this.disabledRadio = true;
        this.disabledNuevo = false;
      }
    },
  },
  mounted(){
    const ruta = obtenerParametro('anterior');
    if(ruta !== null && ruta === 'registro'){
      this.pathAnterior = `/prospeccion/registroProspecto/${this.obtenerDatosProspecto.curp}?direct=contrato`
    }
  },
  created() {
    this.contrato = this.obtenerDatosProspecto.contratos;
    if (
      this.obtenerDatosProspecto.curp == "" ||
      this.obtenerDatosProspecto.curp == undefined
    ) {
      this.curp = "";
    } else {
      this.curp = this.obtenerDatosProspecto.curp.toUpperCase();
    }

    const plazo = ["60", "40", "30"];
    const saldo = ["50,045.24", "50,045.24", "50,045.24"];
    const mensualidad = ["1940.00", "1940.00", "1940.00"];
    const folio = [
      "1312345345214",
      "Tiempo 17123453452",
      "Atraso 151234534524",
    ];

    this.contrato.map((c) => {
      if (c.estatusContrato === "Activo") {
        this.contratos.push({
          contrato: c.contrato,
          plazo: c.plazo,
          saldoAlDia: c.saldoAlDia,
          descMensual: c.descMensual,
          estatusContrato: c.estatusContrato,
          habilita: false,
          color: "green",
          style: "",
        });
      } else if (
        c.estatusContrato === "Por Terminar" ||
        c.estatusContrato === "Reciente"
      ) {
        this.contratos.push({
          contrato: c.contrato,
          plazo: c.plazo,
          saldoAlDia: c.saldoAlDia,
          descMensual: c.descMensual,
          estatusContrato: c.estatusContrato,
          habilita: true,
          color: "red",
          style: "hidecheck",
        });
      } else {
        this.contratos.push({
          contrato: c.contrato,
          plazo: c.plazo,
          saldoAlDia: c.saldoAlDia,
          descMensual: c.descMensual,
          estatusContrato: c.estatusContrato,
          habilita: true,
          color: "yellow",
        });
      }
    });

    for (let i = 0; i < plazo.length; i++) {
      if (folio[i].includes("Tiempo")) {
        const master = {
          folio: folio[i],
          plazo: plazo[i],
          saldo: saldo[i],
          mensualidad: mensualidad[i],
          habilita: false,
          color: "yellow",
        };

        this.filteredfaqs.push(master);
      } else if (folio[i].includes("Atraso")) {
        const master = {
          folio: folio[i],
          plazo: plazo[i],
          saldo: saldo[i],
          mensualidad: mensualidad[i],
          habilita: true,
          color: "red",
        };

        this.filteredfaqs.push(master);
      } else {
        const master = {
          folio: folio[i],
          plazo: plazo[i],
          saldo: saldo[i],
          mensualidad: mensualidad[i],
          habilita: false,
          color: "green",
        };

        this.filteredfaqs.push(master);
      }
    }
    const contratosActivos = this.contratos.filter(
      (con) => con.estatusContrato === "Activo"
    );
    if (contratosActivos.length === 0) {
      this.reestructura = false;
    }
  },
  methods: {
    ...mapActions(["defineTipoCreditoProspecto"]),
    buscarProspectoCURP() {
      this.busquedaActiva = true;
      obtenerInformacionProspectoCurp(this.curp)
        .then((respuestaProspecto) => {
          this.nombreProspecto =
            respuestaProspecto.data.nombre +
            " " +
            respuestaProspecto.data.apellidoPaterno +
            " " +
            respuestaProspecto.data.apellidoMaterno;
          this.nssProspecto = respuestaProspecto.data.nss;
          this.celularProspecto = respuestaProspecto.data.celular;
          this.emailProspecto = respuestaProspecto.data.correo;
          this.busquedaActiva = false;
        })
        .catch(() => {});
    },
    regresarInformacionProspecto() {
      this.$store.commit("agregarValorProspecto", {
        folios: this.folios,
        tipo: this.selected,
      });
      this.defineTipoCreditoProspecto(this.tipoCreditoProspecto);
      this.$router.replace({
        path: "/prospeccion/montoPlazo",
        query: {
          anterior: 'contrato'
        }
      });
    },
  },
  beforeMount(){
    if(!Cookies.get('token')){
      this.mostrarModalSesion = true
    }
  }
};
</script>

<style scoped>
.radio-boton {
  display: inline-block;
}
.margen {
  margin-left: 15px;
}
.pstyle {
  text-align: left;
  margin-left: 7%;
}
.pstyler {
  text-align: right;
}
.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}

.alinea {
  display: inline-block;
  margin-top: 7px;
}

.colorbt {
  background-color: white;
}

/deep/ .btn-info {
  color: #000;
  background-color: #ffffff;
  border-color: #ffffff;
  border: none;
}

/deep/ .btn-info:disabled {
  color: #000;
  background-color: #ffffff;
  border-color: #ffffff;
  border: none;
}
/deep/ .btn-check:focus + .btn-info,
.btn-info:focus {
  color: #000;
  background-color: #ffffff;
  border-color: #ffffff;
  border: none;
}

/deep/ .btn-info:hover {
  color: #000;
  background-color: #ffffff;
  border-color: #ffffff;
  border: none;
}

/deep/ .btn-check:focus + .btn,
.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

/deep/ .btn-check:focus + .btn-info,
.btn-info:focus {
  color: #000;
  background-color: #ffffff;
  border-color: #ffffff;
  outline: none !important;
  box-shadow: none !important;
}

.hidecheck {
  display: none;
}

/deep/ .btn-info:hover {
  color: #000;
  background-color: #ffffff;
  border-color: #ffffff;
  outline: none !important;
  box-shadow: none !important;
}

/deep/ .btn-check:focus + .btn,
.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

.btn-primary {
  color: #fff;
  background-color: #004b8d;
  border-color: #007bff;
  border-radius: 50px;
  height: 40px;
  width: 200px;
}

.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  border: none;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(255, 255, 255, 0.125);
  border-radius: 0.25rem;
}

.btnc {
  width: 100%;
  display: block;
  text-align: left !important;
}

.reduct {
  font-size: 1rem;
}

.pap {
  padding-left: 10%;
}

.yellow {
  color: rgb(255, 255, 0);
}

.red {
  color: red;
}

.green {
  color: rgb(46, 173, 46);
}

.gray {
  color: #aaa;
}

.black {
  color: black;
}

.maringl {
  margin-left: -68%;
}

.marginls {
  margin-left: -57%;
}
@media (min-width: 360px) and (max-width: 979px) {
  .reduct {
    font-size: 0.8rem;
  }
}
</style>
