<template>
  <body>
    <Menu :cliente="true" :pathAnterior="'/prestapro/CapturaDatosPension'"/>
    <ModalSesion :mostrar="mostrarModalSesion" />
    <div class="container">
        <div class="row">
            <div class="col-md-5 mx-auto">
                <div id="second">
                    <div class="myform form">
                      <form
                          action="#"
                          name="registration"
                          @submit.prevent="loadSpin"
                      >
                        <div class="form-group">
                          <div class="flex-row">
                              <div class="p-2">
                              <label class="colorb">Valida tus datos personales</label>
                              <br />
                              </div>
                          </div>
                          <br />
                          <div class="form-group">
                            <label for="name" class="left">Primer nombre:</label>
                            <input
                              type="text"
                              name="name"
                              class="form-control"
                              id="name"
                              maxlength="100"
                              @blur="validarNombre"
                              @keypress="validarAlfanumericos"
                              v-model="nomCliente"
                              required
                            />
                          </div>
                          <div :class="estiloNombre">
                            {{ textoNombre }}
                          </div>
                          <br />
                          <div class="form-group">
                            <label for="exampleInputName2" class="left">Segundo nombre:</label>
                            <input
                              type="text"
                              name="name2"
                              class="form-control"
                              id="name2"
                              v-model="segCliente"
                              @blur="validarSeg"
                              @keypress="validarAlfanumericos"
                              maxlength="100"
                            />
                          </div>
                          <div :class="estiloSegNombre">
                            {{ textoSegNombre }}
                          </div>
                          <br />
                          <div class="form-group">
                            <label for="label-apellido-paterno" class="left">Apellido paterno:</label>
                            <input
                              type="text"
                              name="apellido-paterno"
                              class="form-control"
                              id="apellido-paterno"
                              v-model="apellidoPaterno"
                              @blur="validarApellidoPaterno"
                              @keypress="validarAlfanumericos"
                              maxlength="100"
                            />
                          </div>
                          <div :class="estiloApellidoPaterno">
                            {{ textoApellidoPaterno }}
                          </div>
                          <br />
                          <div class="form-group">
                            <label for="label-apellido-materno" class="left">Apellido materno:</label>
                            <input
                              type="text"
                              name="apellido-materno"
                              class="form-control"
                              id="apellido-materno"
                              v-model="apellidoMaterno"
                              @blur="validarApellidoMaterno"
                              @keypress="validarAlfanumericos"
                              maxlength="100"
                            />
                          </div>
                          <div :class="estiloApellidoMaterno">
                            {{ textoApellidoMaterno }}
                          </div>
                          <br />
                          <div class="form-group">
                            <label for="exampleInputCURP" class="left" >CURP:</label>
                            <input
                              type="text"
                              name="curp"
                              class="form-control"
                              id="curp"
                              maxlength="18"
                              v-model="curp"
                              text-transdorm="uppercase"
                              @blur="validarCurp"
                              disabled
                            />
                          </div>
                          <div :class="estiloCurp">
                            {{ textoCurp }}
                          </div>
                          <br />
                          <div class="form-group">
                            <label for="inputGenre" class="form-label left">Género:</label>
                            <select
                              class="form-select"
                              name="genre"
                              id="inputGenre"
                              v-model="genero"
                              @blur="validarGenero"
                            >
                              <option v-for="opcion in opciones" :key="opcion.value">{{
                                opcion.text
                              }}</option>
                            </select>
                          </div>
                          <div :class="estiloGenero">
                            {{ textoGenero }}
                          </div>
                          <br />
                          <div class="form-group">
                            <label for="inputCountry" class="form-label left"
                              >País de nacimiento:</label
                            >
                            <input
                              class="form-control"
                              type="text"
                              name="country"
                              id="country"
                              v-model="pais"
                              @blur="validarPais"
                              @keypress="validarAlfanumericos"
                            />
                          </div>
                          <div :class="estiloPais">
                            {{ textoPais }}
                          </div>
                          <br />
                          <div class="form-group">
                            <label for="inputNationality" class="form-label left">Nacionalidad:</label>
                            <input
                              class="form-control"
                              id="inputNationality"
                              name="nationality"
                              type="text"
                              v-model="nacionalidad"
                              @blur="validarNac"
                              @keypress="validarAlfanumericos"
                            />
                          </div>
                          <div :class="estiloNac">
                            {{ textoNac }}
                          </div>
                          <br />
                          <div class="form-group">
                            <label for="estadoNacimiento" class="left">Entidad de nacimiento:</label>
                            <select
                              class="form-select"
                              name="estadoNacimiento"
                              id="estadoNacimiento"
                              v-model="entNacimiento"
                              @blur="validarEntNac"
                            >
                              <option value="" disabled >Selecciona estado</option>
                              <option v-for="estadoMex in estadosMexico" :key="estadoMex.abreviatura">{{
                                estadoMex.estado
                              }}</option>
                            </select>
                          </div>
                          <div :class="estiloEntNac">
                            {{ textoEntNac }}
                          </div>
                          <br />
                          <div class="form-group">
                            <label for="example-datepicker" class="left">Fecha de Nacimiento:</label>
                            <b-form-datepicker 
                              id="example-datepicker" 
                              v-model="fechaNac" 
                              class="mb-2" 
                              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" 
                              locale="es"
                              :hide-header="true"
                              :show-decade-nav="false"
                              label-help=""
                              @blur="validarFecha"
                              ></b-form-datepicker>
                          </div>
                          <div :class="estiloFecha">
                            {{ textoFecha }}
                          </div>
                          <br />
                          <div class="form-group">
                            <div><Tooltip :texto="'Ingresa tu RFC con o sin Homoclave.'" :enlace="'tooltip-rfc'" /></div>
                            <label for="exampleInputName2" class="left">RFC:</label>
                            <div class="input-group">
                              <input
                                type="text"
                                name="rfc"
                                class="form-control input-tooltip-space"
                                id="rfc"
                                maxlength="13"
                                v-model="rfc"
                                @blur="validarRfc"
                                @keypress="validarAlfanumericos"
                              />
                              <img src="@/assets/img/exclamation-circle-solid.svg" id="tooltip-rfc" width="35" height="35" alt="tooltip-rfc" class="tooltip-input-rigth" />
                            </div>
                          </div>
                          <div :class="estiloRfc">
                            {{ textoRfc }}
                          </div>
                          <br />
                          <div class="form-group">
                            <label for="exampleOcupacion" class="left">Ocupación:</label>
                            <input
                              type="text"
                              name="ocupation"
                              class="form-control"
                              id="ocupation"
                              maxlength="50"
                              v-model="ocupacion"
                              @blur="validarOcupacion"
                              @keypress="validarAlfanumericos"
                            />
                          </div>
                          <div :class="estiloOcupacion">
                            {{ textoOcupacion }}
                          </div>
                          <br />
                          <div class="col-md-12 text-center mb-3 pt-4">
                              <button
                                  type="submit"
                                  @click="btnActive"
                                  class="btn btn-block mybtn btn-primary"
                                  :disabled="!continuar">
                                  Continuar
                              </button>
                          </div>
                        </div>
                      </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </body>
</template>

<script>
import Menu from '../genericos/Menu.vue'
import { mapGetters } from 'vuex'
import { caracteresNoPermitidos, estadosMexico } from '../../constants/cajaTexto'
import { eliminarCaracteresExpeciales } from '../../utils/formatoTexto'
import Cookies from 'js-cookie';
import ModalSesion from '../genericos/ModalSesion.vue'
import Tooltip from '../genericos/Tooltip.vue'

export default {
  components: {
    Menu,
    ModalSesion,
    Tooltip
  },
  computed: {
    ...mapGetters(['obtenerPrestaPro'])
  },
  data() {
    return {
      mostrarModalSesion: false,
      descripcionError: null,
      nomCliente: "",
      segCliente: "",
      apellidoPaterno: "",
      apellidoMaterno: "",
      curp: "",
      genero: "",
      opciones: [
        { text: "MASCULINO", value: "H" },
        { text: "FEMENINO", value: "M" },
      ],
      estadosMexico: estadosMexico.map( e => { 
        return {
          estado: e.estado.toUpperCase(),
          abreviatura: e.abreviatura
        }
      } ),
      pais: "",
      nacionalidad: "",
      entNacimiento: "",
      fechaNac: "",
      rfc: "",
      ocupacion: "",
      textoNombre: null,
      estiloNombre: "",
      textoSegNombre: null,
      estiloSegNombre: "",
      textoApellidoPaterno: null,
      estiloApellidoPaterno: "",
      textoApellidoMaterno: null,
      estiloApellidoMaterno : "",
      textoCurp: null,
      estiloCurp: "",
      textoGenero: null,
      estiloGenero: "",
      textoPais: null,
      estiloPais: "",
      textoNac: null,
      estiloNac: "",
      textoEntNac: null,
      estiloEntNac: "",
      textoFecha: null,
      estiloFecha: "",
      textoRfc: null,
      estiloRfc: "",
      textoOcupacion: null,
      estiloOcupacion: "",
      bloqueoNombre: true,
      bloqueoSeg: false,
      bloqueoApellidoPaterno : true,
      bloqueoApellidoMaterno: false,
      bloqueoCurp: false,
      bloqueoGenero: true,
      bloqueoPais: true,
      bloqueoNac: true,
      bloqueoEntNac: true,
      bloqueoFecha: true,
      bloqueoRfc: true,
      bloqueoOcupacion: true,
      continuar: false,
    };
  },
  watch: {
    nomCliente( value ){
      this.nomCliente = eliminarCaracteresExpeciales( value )
      this.validarNombre()
    },
    segCliente( value ){
      this.segCliente = eliminarCaracteresExpeciales( value )
      this.validarSeg()
    },
    apellidoPaterno( value ){
      this.apellidoPaterno = eliminarCaracteresExpeciales( value )
      this.validarApellidoPaterno()
    },
    apellidoMaterno( value ){
      this.apellidoMaterno = eliminarCaracteresExpeciales( value )
      this.validarApellidoMaterno()
    },
    pais( value ){
      this.pais = eliminarCaracteresExpeciales( value )
      this.validarPais()
    },
    nacionalidad( value ){
      this.nacionalidad = eliminarCaracteresExpeciales( value )
      this.validarNac()
    },
    genero(){
      this.validarGenero()
    },
    fechaNac(){
      this.validarFecha()
    },
    rfc( value ){
      this.rfc = eliminarCaracteresExpeciales( value )
      this.validarRfc()
    },
    ocupacion( value ){
      this.ocupacion = eliminarCaracteresExpeciales( value )
      this.validarOcupacion()
    }
  },
  mounted() {
    this.curp = this.obtenerPrestaPro.curp.toUpperCase()
    if ( this.curp !== null && this.curp !== "" ){
      if( this.curp.substring(11, 13) === "NE" ){
        this.pais = ""
        this.entNacimiento = ""
        this.textoEntNac = "Coloca el estado de la república en el que radicas"
        this.estiloEntNac = "showError"
      }else{
        const estadoObj = this.estadosMexico.find( i => i.abreviatura === this.curp.substring(11, 13))
        this.pais = "MÉXICO"
        this.entNacimiento = estadoObj !== undefined ? estadoObj.estado : ""
      }
      this.genero = this.curp.substring(10, 11) === 'M' ? "FEMENINO" : "MASCULINO"
      this.bloqueoGenero = false
      this.bloqueoEntNac = (this.entNacimiento === "")
      this.fechaNac = `19${ this.curp.substring( 4,6 ) }-${ this.curp.substring( 6,8 ) }-${ this.curp.substring( 8,10 ) }`
      this.bloqueoFecha = false
      this.rfc = this.curp.substring( 0,10 )
      this.bloqueoRfc = false
      this.bloqueoPais = (this.pais === "" )
      this.nacionalidad = "MEXICANA"
      this.bloqueoNac = false
    }
    const nombreStore = this.obtenerPrestaPro.primerNombre
    const segundoNombreStore = this.obtenerPrestaPro.segundoNombre
    const apellidoPaternoStore = this.obtenerPrestaPro.apellidoPaterno
    const apellidoMaternoStore = this.obtenerPrestaPro.apellidoMaterno
    if(nombreStore !== undefined && nombreStore !== ""){
      this.nomCliente = nombreStore
      this.bloqueoNombre = false
    }
    if(segundoNombreStore !== undefined && segundoNombreStore !== ""){
      this.segCliente = segundoNombreStore
      this.bloqueoSeg = false
    }
    if(apellidoPaternoStore !== undefined && apellidoPaternoStore !== ""){
      this.apellidoPaterno = apellidoPaternoStore
      this.bloqueoApellidoPaterno = false
    }
    if(apellidoMaternoStore !== undefined && apellidoMaternoStore !== ""){
      this.apellidoMaterno = apellidoMaternoStore
      this.bloqueoApellidoMaterno = false
    }
  },
  methods: {
    validarAlfanumericos: ($e) => {
      if(caracteresNoPermitidos.includes($e.charCode)){
        $e.preventDefault()
      }
    },
    loadSpin(){
      this.descripcionError = "loadspin";
    },
    validarBotonHabilitado() {
      if (!this.bloqueoNombre && !this.bloqueoSeg && !this.bloqueoApellidoPaterno && !this.bloqueoApellidoMaterno && !this.bloqueoCurp && !this.bloqueoGenero && !this.bloqueoPais && !this.bloqueoNac && !this.bloqueoEntNac && !this.bloqueoFecha && !this.bloqueoRfc && !this.bloqueoOcupacion) {
        this.continuar = true;
      } else {
        this.continuar = false;
      }
    },
    btnActive() {
      const nombreCompleto = {
        primerNombre: this.nomCliente,
        segundoNombre: this.segCliente,
        apellidoPaterno: this.apellidoPaterno,
        apellidoMaterno: this.apellidoMaterno,
        genero: this.genero,
        entidadNacimiento: this.entNacimiento,
        paisNacimiento: this.pais,
        nacionalidad: this.nacionalidad,
        rfc: this.rfc,
        ocupacion: this.ocupacion
      }
      this.$store.commit('agregarValorPrestaPro', nombreCompleto)
      this.$router.replace("/prestapro/ingresaDireccion");
    },
    validarNombre() {
        if (this.nomCliente === "") {
          this.textoNombre = "Dato Obligatorio";
          this.estiloNombre = "showError";
          this.bloqueoNombre = true;
        } else {
          this.nomCliente = this.nomCliente.toUpperCase()
          this.textoNombre = null;
          this.estiloNombre = "";
          this.bloqueoNombre = false;
        }
        this.validarBotonHabilitado();
    },
    validarSeg() {
        this.segCliente = this.segCliente.toUpperCase()
        this.textoSegNombre = null;
        this.estiloSegNombre = "";
        this.bloqueoSeg = false;
        this.validarBotonHabilitado();
    },
    validarApellidoPaterno(){
      if (this.apellidoPaterno === "") {
          this.textoApellidoPaterno = "Dato Obligatorio";
          this.estiloApellidoPaterno = "showError";
          this.bloqueoApellidoPaterno = true;
        } else {
          this.apellidoPaterno = this.apellidoPaterno.toUpperCase()
          this.textoApellidoPaterno = null;
          this.estiloApellidoPaterno = "";
          this.bloqueoApellidoPaterno = false;
        }
        this.validarBotonHabilitado();
    },
    validarApellidoMaterno() {
      this.apellidoMaterno = this.apellidoMaterno.toUpperCase()
      this.textoApellidoMaterno = null;
      this.estiloApellidoMaterno = "";
      this.bloqueoApellidoMaterno = false;
      this.validarBotonHabilitado();
    },
    validarCurp() {
        if (this.curp === "") {
          this.textoCurp = "Dato Obligatorio";
          this.estiloCurp = "showError";
          this.bloqueoCurp = true
        } else {
          this.textoCurp = null;
          this.estiloCurp = "";
          this.bloqueoCurp = false;
        }
        this.validarBotonHabilitado();
    },
    validarGenero() {
        if (this.genero === "") {
          this.textoGenero = "Dato Obligatorio";
          this.estiloGenero = "showError";
          this.bloqueoGenero = true
        } else {
          this.textoGenero = null;
          this.estiloGenero = "";
          this.bloqueoGenero = false;
        }
        this.validarBotonHabilitado();
    },
    validarPais() {
      this.pais = this.pais.toUpperCase()
      if (this.pais === "") {
        this.textoPais = "Dato Obligatorio";
        this.estiloPais = "showError";
        this.bloqueoPais = true
        this.textoEntNac = "Coloca el estado de la república en el que radicas"
        this.estiloEntNac = "showError"
      } else if(this.pais === "MÉXICO" || this.pais === "MEXICO"){
        this.textoEntNac = null;
        this.estiloEntNac = "";
        this.estiloPais = "";
        this.textoPais = null;
        this.bloqueoPais = false;
      } else  {
        this.pais = this.pais.toUpperCase()
        this.textoPais = null;
        this.estiloPais = "";
        this.bloqueoPais = false;
      }
      this.validarBotonHabilitado();
    },
    validarNac() {
      if (this.nacionalidad === "") {
        this.textoNac = "Dato Obligatorio";
        this.estiloNac = "showError";
        this.bloqueoNac = true
      } else {
        this.nacionalidad = this.nacionalidad.toUpperCase()
        this.textoNac = null;
        this.estiloNac = "";
        this.bloqueoNac = false;
      }
      this.validarBotonHabilitado();
    },
    validarEntNac() {
      if (this.entNacimiento === "") {
        this.textoEntNac = "Dato Obligatorio";
        this.estiloEntNac = "showError";
        this.bloqueoEntNac = true
      } else{
          this.textoEntNac = null;
          this.estiloEntNac = "";
          this.bloqueoEntNac = false;
      }
      this.validarBotonHabilitado();
    },
    validarFecha() {
      if (this.fechaNac === "") {
        this.textoFecha = "Dato Obligatorio";
        this.estiloFecha = "showError";
        this.bloqueoFecha = true
      } else {
        this.textoFecha = null;
        this.estiloFecha = "";
        this.bloqueoFecha = false;
      }
      this.validarBotonHabilitado();
    },
    validarRfc() {
      if (this.rfc === "") {
        this.textoRfc = "Dato Obligatorio";
        this.estiloRfc = "showError";
        this.bloqueoRfc = true
      } else {
        this.rfc = this.rfc.toUpperCase()
        const expresionRFC13 = /^([A-ZÑ&]{3,4}(\d{2})(0[1-9]|1[0-2])(0[1-9]|1\d|2\d|3[0-1])[A-Z|\d]{3})$/
        const expresionRFC10 = /^([A-ZÑ&]{3,4}(\d{2})(0[1-9]|1[0-2])(0[1-9]|1\d|2\d|3[0-1]))$/
        if ( expresionRFC10.test( this.rfc ) || expresionRFC13.test( this.rfc ) ) {
          this.textoRfc = null;
          this.estiloRfc = "";
          this.bloqueoRfc = false;
        } else {
          this.textoRfc = "Dato Incorrecto";
          this.estiloRfc = "showError";
          this.bloqueoRfc = true
        }
      }
        this.validarBotonHabilitado();
    },
    validarOcupacion() {
      if (this.ocupacion === "") {
        this.textoOcupacion = "Dato Obligatorio";
        this.estiloOcupacion = "showError";
        this.bloqueoOcupacion = true
      } else {
        this.ocupacion = this.ocupacion.toUpperCase()
        this.textoOcupacion = null;
        this.estiloOcupacion = "";
        this.bloqueoOcupacion = false;
      }
      this.validarBotonHabilitado();
    },
  },
  beforeMount(){
    if(!Cookies.get('token')){
      this.mostrarModalSesion = true
    }
  }
};
</script>

<style scoped>
.left{
  display: flex;
  padding-left: 5px;
}
.tooltip-input{
  display: flex;
  float: left;
  margin-top: 3px;
}
.tooltip-input-rigth{
  margin: 1px;
  margin-left: 10px;

}
.input-tooltip-space{
  margin-right: 3px;
}
</style>
