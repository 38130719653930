<template>
  <body>
    <ModalSesion :mostrar="mostrarModalSesion" />
    <div class="container">
      <div class="row">
        <div class="col-md-5 mx-auto">
          <div id="first">
            <div class="myform form" style="margin-top: 1%">
              <form action="">
                <div class="logo mb-3">
                  <div class="form-group">
                    <div class="iconModal">
                      <img src="@/assets/img/icn_info.svg" alt="" />
                    </div>
                    <b-row class="mb-1">
                      <b-col
                        style="font-size: 21px;
    color: #0181c4"
                        ><p></p>
                        Tu trámite para obtener tu préstamo Profuturo será
                        validado en 24 horas
                      </b-col>
                    </b-row>
                    <br />
                    <p style="font-size: 16px; color: #0181c4;">
                      {{ date | moment }}
                    </p>
                    <br />
                    <p style="font-size: 18px; color: #374047;">
                      Te enviaremos una encuesta de
                    </p>
                    <p style="font-size: 18px; color: #374047;">
                      satisfacción al correo electrónico
                    </p>
                    <p style="font-size: 18px; color: #374047;">
                      registrado
                      <a style="color: #0181c4;">{{ enmascarado }}</a>
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </body>
</template>
<script>

import { mapGetters } from "vuex";
import moment from "moment";
import Cookies from 'js-cookie'
import { cancelarLiga } from '../../services/tcs'

export default {
  created() {
    this.email = this.obtenerPrestaPro.correo;
  },
  data: () => ({
    date: [],
    email: "",
    enmascarado: "",
    mostrarModalSesion: false,
  }),
  computed: {
    ...mapGetters(["obtenerPrestaPro"]),
  },
  async mounted() {
    this.enmascararEmail();
    await cancelarLiga({ curp: this.obtenerPrestaPro.curp.toUpperCase(), id: this.obtenerPrestaPro.uid }).then(r => r).catch(e => e)
  },
  methods: {
    enmascararEmail() {
      const partes = this.email.split("@");
      this.enmascarado = partes[0].substring(0, 2) + "****" + partes[1];
    },
  },
  filters: {
    moment: function(date) {
      return moment(date).format("DD/MM/YYYY");
    },
  },
  beforeMount(){
    if(!Cookies.get('token')){
      this.mostrarModalSesion = true
    }
  }
};
</script>
<style scoped>

.iconModal {
  margin: 0rem 5rem;
}

/deep/ .modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(248, 248, 248, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
/deep/ .modal-backdrop {
  z-index: -1;
  background-color: #fff;
  opacity: 0.9;
  -webkit-filter: blur(8px);
  -moz-filter: blur(8px);
  -o-filter: blur(8px);
  -ms-filter: blur(8px);
  filter: blur(8px);
}
/deep/ .modal-container {
  width: 406px;
  height: 647px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #ffffff8c;
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  border: none;
}
/deep/ .modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border: none;
}
/deep/ .modal-header .close {
  border: none;
  color: #0181c4;
  background: white;
  font-size: 44px;
}
/deep/ .modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  align-self: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border: none;
  margin-top: 80px;
}
.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}
.modal-body {
  margin: 20px 0;
}
.modal-default-button {
  float: right;
}

.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 0;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
/deep/ .modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
  font-size: 21px;
  color: #0181c4;
}
/deep/ .btn-primary:hover {
  color: #fff;
  background-color: #004b8d;
  border-color: #007bff;
  border-radius: 50px;
}
/deep/ .btn-primary {
  color: #fff;
  background-color: #004b8d;
  border-color: #007bff;
  border-radius: 50px;
}
</style>
