import { JSEncrypt } from 'js-encrypt'

export function decryptObject(object, privateKey){
 var decrypt = new JSEncrypt()
 decrypt.setPrivateKey(privateKey)
 let obj = {}
 for (const property in object) {
  obj[property] = decrypt.decrypt(object[property])
 }
 return obj
}