<template>
  <body>
    <Menu :cliente="true" :pathAnterior="'/prestapro/_30_casos_alternos'"/>
    <ModalError 
      :mostrar="mostrarModalError"
      :descripcion="descripcionError"
      :accion="closeModal"/>
    <ModalSesion :mostrar="mostrarModalSesion" />
    <div class="container">
      <div class="row">
        <div class="col-md-5 mx-auto">
          <div id="second">
            <div class="myform form" style="margin-top: 1%">
              <form
                action="#"
                name="registration"
                @submit.prevent="loadSpin"
              >
                <div class="form-group">
                  <div class=" flex-row">
                    <div class="p-2">
                      <label class="colorb">Ingresa tu dirección</label>
                    </div>
                  </div>
                  <div class="d-flex flex-row">
                    <div class="p-2">
                      <label class="colore"
                        >Tus datos deben coincidir con tu comprobante</label
                      >
                    </div>
                  </div>

                  <div class="d-flex flex-row">
                    <div class="p-2">
                      <label>Calle:</label>
                    </div>
                  </div>

                  <input
                    type="text"
                    name="street"
                    class="form-control"
                    id="street"
                    @keypress="validarCaracteresEspeciales"
                    aria-describedby="emailHelp"
                    placeholder=""
                    maxlength="150"
                    v-on:blur="checkStreet()"
                    v-model="street"
                    :class="styleErrStreet"
                  />
                </div>
                <div :class="displayErrStreet" align="right">
                  {{ text.street }}
                </div>
                <div class="d-flex flex-row">
                  <div class="p-2">
                    <label>Número exterior:</label>
                  </div>
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    name="numEx"
                    class="form-control"
                    id="numExt"
                    aria-describedby="emailHelp"
                    placeholder=""
                    @keypress="validarCaracteresEspeciales"
                    maxlength="20"
                    v-on:blur="checkNumEx()"
                    v-model="numExt"
                    :class="styleErrNumE"
                  />
                </div>
                <div :class="displayErrNumE" align="right">
                  {{ text.numEx }}
                </div>

                <div class="d-flex flex-row">
                  <div class="p-2">
                    <label>Número interior:</label>
                  </div>
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    name="numIn"
                    class="form-control"
                    id="numInt"
                    aria-describedby="emailHelp"
                    placeholder=""
                    maxlength="20"
                    v-on:blur="checkNumInt"
                    @keypress="validarCaracteresEspeciales"
                    v-model="numInt"
                  />
                </div>
                <div class="d-flex flex-row">
                  <div class="p-2">
                    <label>Código postal:</label>
                  </div>
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    name="codPost"
                    class="form-control"
                    id="codPost"
                    @keyup="onKeyUp"
                    maxlength="5"
                    minlength="5"
                    oninput="if(value.length>5)value=value.slice(0,5)"
                    aria-describedby="emailHelp"
                    placeholder=""
                    @keypress="validarNumeros"
                    @blur="checkCp()"
                    v-model="codPost"
                    :class="styleErrCp"
                  />
                </div>

                <div :class="displayErrCp" align="right">
                  {{ text.codPost }}
                </div>
                <div class="d-flex flex-row">
                  <div class="p-2">
                    <label>Colonia:</label>
                  </div>
                </div>
                <div class="form-group">
                  <select
                    class="form-control"
                    v-model="colo"
                    @blur="checkColo"
                  >
                    <option value="" selected disabled
                      >Selecciona una opción</option
                    >
                    <option
                      v-for="colonia in colonias"
                      :value="colonia.idColonia"
                      :key="colonia.idColonia"
                      >{{ colonia.Colonia }}</option
                    >
                  </select>
                </div>
                <div :class="displayErrColo" align="right">
                  {{ text.colo }}
                </div>
                

                <div class="d-flex flex-row">
                  <div class="p-2">
                    <label>Alcaldía o Municipio:</label>
                  </div>
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    name="country"
                    class="form-control"
                    @input="check8()"
                    id="munici"
                    readonly
                    aria-describedby="emailHelp"
                    placeholder=""
                    maxlength="100"
                    :value="municipio"
                  />
                </div>
                <div class="d-flex flex-row">
                  <div class="p-2">
                    <label>Ciudad o Población:</label>
                  </div>
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    name="city"
                    class="form-control"
                    id="city"
                    aria-describedby="emailHelp"
                    placeholder=""
                    maxlength="100"
                    @input="check5()"
                    readonly
                    :value="delegacion"
                  />
                </div>
                <div class="d-flex flex-row">
                  <div class="p-2">
                    <label>Estado:</label>
                  </div>
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    name="state"
                    class="form-control"
                    id="state"
                    aria-describedby="emailHelp"
                    placeholder=""
                    maxlength="100"
                    @input="check6()"
                    readonly
                    :value="estado"
                  />
                </div>
                <div class="d-flex flex-row">
                  <div class="p-2">
                    <label>País:</label>
                  </div>
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    name="country"
                    class="form-control"
                    id="country"
                    readonly
                    @input="check7()"
                    aria-describedby="emailHelp"
                    placeholder=""
                    maxlength="100"
                    :value="paisj"
                  />
                </div>

                <div class="col-md-12 text-center mb-3 pt-4">
                  <button
                    type="submit"
                    class="btn btn-block mybtn btn-primary"
                    :disabled="mailOk"
                  >
                    Continuar
                  </button>
                </div>

                <div class="col-md-12"></div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </body>
</template>

<script>

import { generarToken, postalCode } from "../../services/autorizacion";
import Cookies from "js-cookie";
import ModalError from '../genericos/ModalError.vue'
import Menu from '../genericos/Menu.vue'
import { mapGetters } from 'vuex'
import { caracteresNoPermitidos, numeros } from '../../constants/cajaTexto'
import { eliminarCaracteresExpeciales, validarSoloNumeros } from '../../utils/formatoTexto'
import ModalSesion from '../genericos/ModalSesion.vue'

export default {
  name: "Employeelist",
  components: {
    Menu,
    ModalError,
    ModalSesion
  },
  computed:{
    ...mapGetters(['obtenerPrestaPro'])
  },
  data: () => ({
    mostrarModalSesion: false,
    mostrarModalError: false,
    descripcionError: null,
    disabled1: true,
    disabled2: true,
    disabled3: true,
    disabled4: true,
    disabled5: true,
    disabled6: true,
    disabled7: true,
    disabled8: true,
    displayErrStreet: "hideError",
    styleErrStreet: "",
    displayErrNumE: "hideError",
    styleErrNumE: "",
    displayErrCp: "hideError",
    styleErrCp: "",
    displayErrColo: "hideError",
    styleErrColo: "",
    cpval: "",
    cpvalc: "",
    mailOk: true,
    estado: "",
    paisj: "",
    municipio: "",
    text: {
      street: "",
      numEx: "",
      codPost: "",
      colo: "",
    },
    delegacion: "",
    colonias: [],
    coloniasDetail: [],
    info: null,
    street: "",
    numExt: "",
    numInt: "",
    codPost: "",
    colo: "",
    return: () => ({
      posts: [],
    }),
  }),
  watch: {
    street( value ){
      this.street = eliminarCaracteresExpeciales(value)
      this.checkStreet()
    },
    numExt( value ){
      this.numExt = eliminarCaracteresExpeciales(value)
      this.checkNumEx()
    },
    numInt( value ){
      this.numInt = eliminarCaracteresExpeciales(value)
      this.checkNumInt()
    },
    codPost( value ){
      this.disabled3 = true
      this.codPost = validarSoloNumeros(value)
      if (this.codPost === "") {
        this.colonias = []
        this.colo = ""
        this.text.codPost = "Dato Obligatorio";
        this.validate(true, "cp");
      } else if (this.codPost.length !== 5) {
        this.colonias = []
        this.colo = ""
        this.text.codPost = "Dato Incorrecto";
        this.validate(true, "cp");
      } else {
        this.disabled3 = false;
        this.validate(false, "cp");
      }
      this.validAllInputs()
    },
    colo(){
      this.checkColo()
    }
  },
  methods: {
    validarNumeros($e){
        if(!numeros.includes($e.charCode)){
          $e.preventDefault()
        }
    },
    validarCaracteresEspeciales($e){
        if(caracteresNoPermitidos.includes($e.charCode)){
          $e.preventDefault()
        }
    },
    hidemoda() {
      this.mostrarModalError = false;
    },
    loadSpin(){
      const coloniaSeleccion = this.coloniasDetail.find(c => c.IdColonia === this.colo);
      const direccion = {
        calle: this.street,
        numeroExterior: this.numExt,
        numeroInterior: this.numInt,
        codigoPostal: this.codPost,
        estado: coloniaSeleccion.Estado,
        delegacion: coloniaSeleccion.Delegacion,
        idColonia: coloniaSeleccion.IdColonia,
        colonia: coloniaSeleccion.Colonia,
        ciudad: coloniaSeleccion.Ciudad,
        pais: coloniaSeleccion.Pais,
      }
      this.$store.commit('agregarValorPrestaPro', direccion)
      this.$router.replace("/prestapro/datosContacto")
    },
    validate(error, type) {
      if (error) {
        if (type === "street") {
          this.displayErrStreet = "showError";
          this.styleErrStreet = "errorText";
        } else if (type === "numEx") {
          this.displayErrNumE = "showError";
          this.styleErrNumE = "errorText";
        } else if (type === "cp") {
          this.displayErrCp = "showError";
          this.styleErrCp = "errorText";
        } else if (type === "colo") {
          this.displayErrColo = "showError";
          this.styleErrColo = "errorText";
        }
      } else if (type != undefined) {
        if (type === "street") {
          this.displayErrStreet = "hideError";
          this.styleErrStreet = "normalText";
        } else if (type === "numEx") {
          this.displayErrNumE = "hideError";
          this.styleErrNumE = "normalText";
        } else if (type === "cp") {
          this.displayErrCp = "hideError";
          this.styleErrCp = "normalText";
        } else if (type === "colo") {
          this.displayErrColo = "hideError";
          this.styleErrColo = "normalText";
        }
      }
    },
    validAllInputs() {
      if (
        !this.disabled1 &&
        !this.disabled2 &&
        !this.disabled3 &&
        !this.disabled4 &&
        !this.disabled5 &&
        !this.disabled6 &&
        !this.disabled7
      ) {
        this.mailOk = false;
      } else {
        this.mailOk = true;
      }
    },
    onKeyUp: function() {
      var allParas = document.getElementById("codPost").value;
      var num = allParas.length;
      if (num === 5) {
        this.recoverCp();
      }
    },
    isNumber: function(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        (charCode > 31 && (charCode < 48 || charCode > 57) && charCode == 46) ||
        charCode == 45 ||
        charCode == 101 ||
        charCode == 43
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    closeModal(){
      this.descripcionError = null
      this.mostrarModalError = false
    },
    recoverCp() {
      this.disabled4 = true
      this.colonias = []
      this.paisj = ""
      this.estado = ""
      this.delegacion = ""
      this.municipio = ""
      var cp = document.getElementById("codPost").value;
      this.mostrarModalError = true
      this.descripcionError = "loadspin"
      generarToken().then((t) => {
        Cookies.set("token", t.data.access_token);
        postalCode(cp)
          .then((cpResponse) => {
            this.mostrarModalError = false
            const codigoError = cpResponse.data.Errores[0]
            if(codigoError.Codigo === 1010001){
              this.coloniasDetail = cpResponse.data.Colonias
              if(cpResponse.data.Colonias.length > 0){
                this.colonias = cpResponse.data.Colonias.map( col => { return { idColonia: col.IdColonia, Colonia: col.Colonia.toUpperCase() }})
                this.paisj = cpResponse.data.Colonias[0].Pais.toUpperCase();
                this.estado = cpResponse.data.Colonias[0].Estado.toUpperCase();
                this.delegacion = cpResponse.data.Colonias[0].Ciudad.toUpperCase();
                this.municipio = cpResponse.data.Colonias[0].Delegacion.toUpperCase();
                this.disabled5 = false;
                this.disabled6 = false;
                this.disabled7 = false;
                this.displayErrCp = "hideError";
              } else {
                this.disabled5 = true;
                this.disabled6 = true;
                this.disabled7 = true;
                this.displayErrCp = "showError";
                this.text.codPost = "Dato Incorrecto";
              }
            }else{
              this.codPost = ""
              this.colo = ""
              this.mostrarModalError = true
              this.descripcionError = codigoError.Mensaje
            }
          }).catch((e) => {
            this.descripcionError = e
            this.mostrarModalError = true
            this.descripcionError = e
            this.usertheme = "showError";
            this.disabled4 = false;
            this.disabled5 = false;
            this.disabled6 = false;
            this.disabled7 = false;
            this.disabled8 = false;
          });
      });
    },
    checkStreet() {
      const { street } = this;
      if (street === "") {
        this.disabled1 = true;
        this.text.street = "Dato Obligatorio";
        this.validate(true, "street");
      } else {
        this.street = this.street.toUpperCase()
        this.disabled1 = false;
        this.validate(false, "street");
      }
      this.validAllInputs();
    },
    checkNumEx() {
      const { numExt } = this;
      if (numExt === "") {
        this.disabled2 = true;
        this.text.numEx = "Dato Obligatorio";
        this.validate(true, "numEx");
      } else {
        this.numExt = this.numExt.toUpperCase()
        this.disabled2 = false;
        this.validate(false, "numEx");
      }
      this.validAllInputs();
    },
    checkNumInt(){
      this.numInt = this.numInt.toUpperCase()
    },
    check2() {
      if (document.getElementById("numExt").value == "") {
        this.disabled2 = true;
        this.text = "Dato Obligatorio";
        this.validate(true, "user");
      } else {
        this.disabled2 = false;
        this.validate(false, "user");
      }
    },
    checkCp() {
      const { codPost } = this;
      if (codPost === "") {
        this.colonias = []
        this.colo = ""
        this.disabled3 = true;
        this.text.codPost = "Dato Obligatorio";
        this.validate(true, "cp");
      } else if (codPost.length !== 5) {
        this.colonias = []
        this.colo = ""
        this.disabled3 = true;
        this.text.codPost = "Dato Incorrecto";
        this.validate(true, "cp");
      } else {
        this.disabled3 = false;
        this.validate(false, "cp");
      }
      this.validAllInputs();
    },
    checkColo() {
      const { colo } = this;
      if (colo === "") {
        this.disabled4 = true;
        this.text.colo = "Selecciona tu colonia";
        this.validate(true, "colo");
      } else {
        this.disabled4 = false;
        this.validate(false, "colo");
      }
      this.validAllInputs();
    },
  },
  beforeMount(){
    if(!Cookies.get('token')){
      this.mostrarModalSesion = true
    }
  }
};
</script>

<style scoped>
body {
  height: 100%;
  margin: 0;
  padding-top: 4.2rem;
  padding-bottom: 4.2rem;
  background: #fff;
}

img {
  max-width: 100%;

  max-height: 100%;
}
a {
  text-decoration: none !important;
}

.btn-primary {
  color: #fff;
  background-color: #004b8d;
  border-color: #007bff;
}

.bg-light {
  background-color: #ffffff !important;
  box-shadow: -2px -2px 6px black, 2px -2px 6px black;
}
.colorf {
  font-size: 23px;
  color: #004b8d;
}
.colorc {
  font-size: 16px;
  color: #004b8d;
}
.icon-color {
  color: #004b8d;
}
.navbar-light .navbar-brand {
  color: rgb(4 70 131);
}
.myform {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  padding: 1rem;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgb(255, 255, 255);
  border-radius: 1.1rem;
  outline: 0;
  max-width: 500px;
}
.tx-tfm {
  text-transform: uppercase;
}
.mybtn {
  border-radius: 50px;
}

.login-or {
  position: relative;
  color: #aaa;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.span-or {
  display: block;
  position: absolute;
  left: 50%;
  top: -2px;
  margin-left: -25px;
  background-color: #fff;
  width: 50px;
  text-align: center;
}
.hr-or {
  height: 1px;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.google {
  color: #666;
  width: 100%;
  height: 40px;
  text-align: center;
  outline: none;
  border: 1px solid lightgrey;
}
form .error {
  color: #ff0000;
}
.buttonbar {
  visibility: hidden;
}

.responsepros {
  color: #0181c4;
}
.marginl {
  margin-left: 11px;
}

.imagenav {
  width: 20%;

  margin-left: 5%;
}
.colorb {
  color: #004b8d;
  font-size: 23px;
  height: 24px;
  text-align: center;
  font-weight: bold;
}
.colore {
  color: #004b8d;
  font-size: 18px;
  margin: 16px 35px 30px 31px;
}
</style>
