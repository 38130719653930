<template>
  <body>
    <Menu :atras="false" :cliente="true" />
    <ModalSesion :mostrar="mostrarModalSesion" />
    <div class="container">
      <div class="row">
        <div class="col-md-5 mx-auto">
          <div id="second">
            <div class="myform form" style="margin-top: 1%">
              <form
                action
                name="registration"
                @submit.prevent="capturarIne"
              >
                <div class="form-group">
                  <div class="logo mb-3 pl-0 pr-0 pt-3 colorf">
                    <label >Confirma tus datos </label>
                  </div>
                  <label class="responsepros left-title">Datos del préstamo</label>
                  <br />
                </div>
                <div class="form-group">
                  <label class="left">Monto:</label>
                  <label class="responsepros right">{{ monto }}</label>
                </div>
                <br/>
                <div class="form-group">
                  <label class="left">Plazo: </label>
                  <label class="responsepros right">{{ `${plazo} meses` }}</label>
                </div>
                <br/>
                <div class="form-group">
                  <label class="left">Tasa de interés anual:</label>
                  <label class="responsepros right">{{ tasaInteres }}</label>
                </div>
                <br/>
                <div class="form-group">
                  <label class="left">Descuento mensual: </label>
                  <label class="responsepros right">{{ descuentoMensual }}</label>
                </div>
                <br/>
                <div class="form-group">
                  <label class="left">Banco: </label>
                  <label class="responsepros right">{{ banco }}</label>
                </div>
                <br/>
                <div class="form-group">
                  <label class="left">CLABE: </label>
                  <label class="responsepros right">{{ clabe }}</label>
                </div>
                <br/>
                <br/>
                <div class="col-md-12 text-center mb-3 pt-5">
                  <button type="submit" class="btn btn-block mybtn btn-primary">
                    Confirmar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </body>
</template>

<script>

import Menu from '../genericos/Menu.vue'
import { mapGetters } from 'vuex'
import Cookies from 'js-cookie';
import ModalSesion from '../genericos/ModalSesion.vue'

export default {
  data: () => ({
    mostrarModalSesion: false,
    monto: null,
    plazo: null,
    tasaInteres: null,
    descuentoMensual: null,
    banco: null,
    clabe: null
  }),
  computed: {
    ...mapGetters(["obtenerPrestaPro"]),
  },
  components: {
    Menu,
    ModalSesion
  },
  methods: {
    capturarIne() {
      this.$router.replace("/prestapro/capturaIne")
    },
  },
  mounted(){
    const { monto, plazo, tasa, capacidadDescuento, banco, clabe } = this.obtenerPrestaPro
    this.monto = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2,  }).format(monto)
    this.plazo = plazo
    this.tasaInteres = `${(tasa).toFixed(2)}%`
    this.descuentoMensual = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2,  }).format(capacidadDescuento)
    this.banco = banco
    this.clabe = clabe
  },
  beforeMount(){
    if(!Cookies.get('token')){
      this.mostrarModalSesion = true
    }
  }
};
</script>

<style scoped>
.left-title{
  text-align: left;
  display: block;
}
.left{
  display: flex;
  float: left;
}
.right{
  display: flex;
  float: right;
}
</style>
