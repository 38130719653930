export const arregloMeses = [ "ENERO", "FEBRERO", "MARZO", "ABRIL", "MAYO", "JUNIO", "JULIO", "AGOSTO", "SEPTIEMBRE", "OCTUBRE", "NOVIEMBRE", "DICIEMBRE" ]

export const obtenerMes = () => {
 const fecha = new Date()
 const mesActual = fecha.getMonth()
 return arregloMeses[mesActual]
}

export const obtenerAno = () => {
 const fecha = new Date()
 return fecha.getFullYear()
}