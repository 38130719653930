import axios from '../plugins/axios'

const URL_SERVER = process.env.VUE_APP_END_POINT_API;


export const obtenerInformacionProspectoCurp = (curp) => {
    return axios.get(`${URL_SERVER}/prestamos/1/gestion-tramites/prospecto`, {
        params: { celular: "", curp: curp, nss: "" }
    });
}

export const guardarCotizacion = ( body ) => {
    return axios.post(`${URL_SERVER}/prestamos/1/gestion-tramites/cotizacion`, body)
}

export const enviarSMS = (celular, mensaje) => {
    return axios.post(`${URL_SERVER}/grupo/1/comunicaciones/sms`, {
        celular: celular,
        mensaje: mensaje
    })
}