import axios from '../plugins/axios'

const URL_SERVER = process.env.VUE_APP_END_POINT_API;

export const guardarProspecto = (request) => {
 return axios.post(`${URL_SERVER}/prestamos/1/notificacion/customer`, request)
}

export const reenviarCodigo = (telefono, codigo) => {
 return axios.post(`${URL_SERVER}/prestamos/1/notificacion/forwarding`, { phoneNumber: telefono, code: codigo })
}

export const obtenerLlavesAsimetricas = () => {
 return axios.get(`${URL_SERVER}/prestamos/1/notificacion/llaves`)
}

export const buscarProspecto = (curp, id, llavePublica) => {
 return axios.get(`${URL_SERVER}/prestamos/1/notificacion/customer?id=${id}&curp=${curp}&publicKey=${llavePublica}`)
}

export const cancelarLiga = ( request ) => {
 return axios.post(`${URL_SERVER}/prestamos/1/notificacion/cancel`, request)
}