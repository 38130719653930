<template>
    <body>
        <Menu :cliente="true" :atras="false" />
        <ModalSesion :mostrar="mostrarModalSesion" />
        <div class="container">
            <div class="row">
                <div class="col-md-5 mx-auto">
                    <div id="second">
                        <div class="myform form">
                            <form
                                action="#"
                                name="registration"
                                @submit.prevent="()=>{}"
                            >
                                <div class="form-group">
                                    <div class="flex-row">
                                        <div class="p-2">
                                        <label class="colorb">Captura tus datos</label>
                                        <br />
                                        </div>
                                    </div>
                                    <br />
                                    <div :style="{textAlign: 'left'}" >
                                        <label class="colore">Información de tu pensión</label>
                                    </div>
                                    <br />
                                    <div class="form-group">
                                        <div><Tooltip :texto="'Ingresa tu número de seguridad social, consta de 11 dígitos.'" :enlace="'tooltip-nss'" /></div>
                                        <label class="left">Núm. de seguridad social:</label>
                                        <div class="input-group">
                                            <input
                                                type="text"
                                                name="seguridadSocial"
                                                class="form-control input-tooltip-space"
                                                id="seguridadSocial"
                                                placeholder=""
                                                maxlength="11"
                                                @blur="validarSeguridadSocial"
                                                v-model="seguridadSocial"
                                                @keypress="validarNumeroGuion"
                                            />
                                            <img src="@/assets/img/exclamation-circle-solid.svg" id="tooltip-nss" width="35" height="35" alt="tooltip-nss" class="tooltip-input-rigth" />
                                        </div>
                                    </div>
                                    <div :class="estiloSeguridadSocial">
                                        {{ textoSeguridadSocial }}
                                    </div>
                                    <br />
                                    <div class="form-group">
                                        <div><Tooltip :texto="'Ingresa el Nombre de tu Banco.'" :enlace="'tooltip-banco'" /></div>
                                        <label class="left">Banco:</label>
                                        <div class="input-group">
                                            <input
                                                type="text"
                                                name="banco"
                                                class="form-control input-tooltip-space"
                                                id="banco"
                                                placeholder=""
                                                maxlength="150"
                                                @blur="validarBanco"
                                                v-model="banco"
                                                @keypress="validarCaracteresEspeciales"
                                            />
                                            <img src="@/assets/img/exclamation-circle-solid.svg" id="tooltip-banco" width="35" height="35" alt="tooltip-banco" class="tooltip-input-rigth" />
                                        </div>
                                    </div>
                                    <div :class="estiloBanco">
                                        {{ textoBanco }}
                                    </div>
                                    <br />
                                    <div class="form-group">
                                        <div><Tooltip :texto="'Ingresa tu número de Clabe interbancaria consta de 18 dígitos.'" :enlace="'tooltip-clabe'" /></div>
                                        <label class="left">CLABE Interbancaria:</label>
                                        <div class="input-group">
                                            <input
                                                type="text"
                                                name="clabe"
                                                class="form-control input-tooltip-space"
                                                id="clabe"
                                                placeholder=""
                                                maxlength="18"
                                                @blur="validarClabe"
                                                v-model="clabe"
                                                @keypress="validarNumeros"
                                            />
                                            <img src="@/assets/img/exclamation-circle-solid.svg" id="tooltip-clabe" width="35" height="35" alt="tooltip-clabe" class="tooltip-input-rigth" />
                                        </div>
                                    </div>
                                    <div :class="estiloClabe">
                                        {{ textoClabe }}
                                    </div>
                                    <br />
                                    <div class="col-md-12 text-center mb-3 pt-4">
                                        <button
                                            type="submit"
                                            @click="redirect"
                                            class="btn btn-block mybtn btn-primary"
                                            :disabled="!continuar">
                                            Continuar
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </body>
</template>

<script>

import Menu from '../genericos/Menu.vue'
import { mapGetters } from 'vuex'
import { caracteresNoPermitidos, numeros } from '../../constants/cajaTexto'
import { validarSoloNumeros, eliminarCaracteresExpeciales } from '../../utils/formatoTexto'
import Cookies from 'js-cookie';
import ModalSesion from '../genericos/ModalSesion.vue'
import Tooltip from '../genericos/Tooltip.vue'

export default {
    name: "CapturaDatosPension",
    components:{
        Menu,
        ModalSesion,
        Tooltip
    },
    computed: {
        ...mapGetters(['obtenerPrestaPro'])
    },
    data(){
        return {
            mostrarModalSesion: false,
            seguridadSocial: null,
            banco: null,
            clabe: null,
            estiloSeguridadSocial: '',
            estiloBanco: '',
            estiloClabe: '',
            textoSeguridadSocial: null,
            textoBanco: null,
            textoClabe: null,
            continuar: false,
            banderaSeguridadSocial: false,
            banderaBanco: false,
            banderaClabe: false
        }
    },
    methods: {
        validarNumeros($e){
            if(!numeros.includes($e.charCode)){
                $e.preventDefault()
            }
        },
        validarCaracteresEspeciales($e){
            if(caracteresNoPermitidos.includes($e.charCode)){
                $e.preventDefault()
            }
        },
        validarNumeroGuion($event){
            if(!(($event.charCode >= 48 && $event.charCode <= 57) || $event.charCode === 45)){
                $event.preventDefault()
            }
        },
        habilitarContinuar(){
            if(this.banderaSeguridadSocial && this.banderaBanco && this.banderaClabe){
                this.continuar = true
            } else {
                this.continuar = false
            }
        },
        validarSeguridadSocial(){
            this.banderaSeguridadSocial = false
            if(this.seguridadSocial === null || this.seguridadSocial === ""){
                this.estiloSeguridadSocial = 'showError'
                this.textoSeguridadSocial = 'Dato Obligatorio'
                
            }else{
                const expresionSeguridadSocial = /^\d{11}$/
                if( !expresionSeguridadSocial.test(this.seguridadSocial) ){
                    this.estiloSeguridadSocial = 'showError'
                    this.textoSeguridadSocial = 'Dato Incorrecto'
                }else{
                    this.estiloSeguridadSocial = ''
                    this.textoSeguridadSocial = null
                    this.banderaSeguridadSocial = true
                    
                }
            }
            this.habilitarContinuar()
        },
        redirect(){
            this.$store.commit('agregarValorPrestaPro', {
                nss: this.seguridadSocial,
                banco: this.banco,
                clabe: this.clabe
            })
            this.$router.replace("/prestapro/_30_casos_alternos");
        },
        validarClabe(){
            this.banderaClabe = false
            if(this.clabe === null || this.clabe === ""){
                this.estiloClabe = 'showError'
                this.textoClabe = 'Dato Obligatorio'
            } else {
                const expresionClabe = /^\d{18}$/
                if( !expresionClabe.test(this.clabe) ){
                    this.estiloClabe = 'showError'
                    this.textoClabe = 'Dato Incorrecto'
                } else {
                    this.estiloClabe = ''
                    this.textoClabe = null
                    this.banderaClabe = true
                }
            }
            this.habilitarContinuar()
        },
        validarBanco(){
            this.banderaBanco = false
            if(this.banco === null || this.banco === ""){
                this.estiloBanco = 'showError'
                this.textoBanco = 'Dato Obligatorio'
            } else {
                this.banco = this.banco.toUpperCase()
                this.estiloBanco = ''
                this.textoBanco = null
                this.banderaBanco = true
            }
            this.habilitarContinuar()
        },
    },
    watch: {
        seguridadSocial( value ){
            this.seguridadSocial = validarSoloNumeros(value)
            this.validarSeguridadSocial()
        },
        banco( value ){
            this.banco = eliminarCaracteresExpeciales(value)
            this.validarBanco()
        },
        clabe( value ){
            this.clabe = validarSoloNumeros(value)
            this.validarClabe()
        }
    },
    beforeMount(){
        if(!Cookies.get('token')){
            this.mostrarModalSesion = true
        }
    }
}
</script>

<style>

.left{
    display: flex;
    padding-left: 5px;
}
.btn-primary {
  color: #fff;
  background-color: #004b8d;
  border-color: #007bff;
  border-radius: 50px;
  height: 40px;
  width: 200px;
}

.tooltip-input{
  display: flex;
  float: left;
  margin-top: 3px;
}
.tooltip-input-rigth{
  margin: 1px;
  margin-left: 10px;

}
.input-tooltip-space{
  margin-right: 3px;
}
</style>