<template>
  <body>
    <Menu :cliente="true" :atras="false" />
    <ModalError :mostrar="mostrarModalError" :descripcion="descripcionError" />
    <ModalSesion :mostrar="mostrarModalSesion" />
    <div class="container">
      <div class="row">
        <div class="col-md-5 mx-auto">
          <div id="first">
            <div class="myform form" style="margin-top: 1%">
              <div class="logo mb-3">
                <div class="col-md-12 text-center pl-0 pr-0"></div>
              </div>
              <div class="softbluetittle">
                <img
                  class="icon-expresion"
                  src="@/assets/img/icn_info.svg"
                  alt="img-info"
                />
                <br />
                <label>Generaremos tu proceso de préstamo de ley 73</label>
              </div>
              <form
                action=""
                method="post"
                name="login"
                @submit.prevent="GeneraPro"
              >
                <div class="col-md-12 text-center pt-5">
                  <p class="size">
                    Recuerda que para continuar necesitas tener a la mano:
                  </p>
                  <ul id="puntos">
                    <li align="left">
                      <strong>Celular con conexión WIFI</strong>
                    </li>
                    <li align="left"><strong>Correo electrónico</strong></li>
                    <li align="left">
                      <strong>Identificación oficial vigente</strong>
                    </li>
                    <li align="left"><strong>Carta de libranza</strong></li>
                    <li align="left">
                      <strong>Comprobante de domicilio</strong>
                    </li>
                    <li align="left">
                      <strong
                        >Impresora y papel para impresión de documento en tamaño
                        carta</strong
                      >
                    </li>
                    <li align="left">
                      <strong
                        >Pluma negra y tinta para poner tu huella
                        digital</strong
                      >
                    </li>
                  </ul>
                  <p class="size">He leído y acepto:</p>
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckChecked"
                    :checked="checked2"
                    v-model="checked"
                  />
                  <label class="form-check-label" for="flexCheckChecked">
                    <a
                      class="margin1 unde"
                      target="_blank"
                      rel="noopener noreferrer"
                      @click="checked = check1"
                      href="Https://www.profuturo.mx/content/wps/portal/Grupo-Profuturo/Aviso-de-privacidad"
                      >Aviso de privacidad</a
                    >
                  </label>
                </div>
                <div class="col-md-12 text-center pt-5" v-if="checked === true">
                  <button
                    type="button"
                    class="btn btn-block mybtn btn-primary"
                    :enabled="check1"
                    @click="showmodal"
                  >
                    Continuar
                  </button>
                </div>
                <div
                  class="col-md-12 text-center pt-5"
                  v-else-if="checked === false"
                >
                  <button
                    type="button"
                    class="btn btn-block mybtn btn-primary"
                    :disabled="check1"
                    @click="showmodal"
                  >
                    Continuar
                  </button>
                </div>
                <b-modal
                  content-class="modal-container"
                  @ok="toggleCamera"
                  id="modal-1"
                  ok-only
                  ok-title="Continuar"
                >
                  <b-container fluid align="center">
                    <img
                    class="icon-warning"
                      src="@/assets/img/icn-70-px-general-alert.svg"
                      alt="img-general"
                    />
                    <b-row class="mb-1">
                      <b-col
                        ><p class="modal-text-color"></p>
                        Para continuar con tu trámite necesitamos que en las
                        siguientes pantallas permitas acceder a tu cámara y
                        lugar en el que te ubicas</b-col
                      >
                    </b-row>
                  </b-container>
                </b-modal>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </body>
</template>

<script>
import ModalError from "../genericos/ModalError.vue";
import Menu from "../genericos/Menu.vue";
import { mapGetters } from "vuex";
import ModalSesion from "../genericos/ModalSesion.vue";
import Cookies from "js-cookie";

export default {
  components: {
    Menu,
    ModalError,
    ModalSesion,
  },
  computed: {
    ...mapGetters(["obtenerPrestaPro"]),
  },
  data() {
    return {
      mostrarModalError: false,
      descripcionError: null,
      mostrarModalSesion: false,
      check1: true,
      isCameraOpen: false,
      isPhotoTaken: false,
      isShotPhoto: false,
      isLoading: false,
      checked2: false,
      link: "#",
      checked: false,
      location: null,
      gettingLocation: false,
      errorStr: null,
    };
  },
  methods: {
    GeneraPro() {
      this.$router.replace("/prestapro/sietePasos");
    },
    Aviso() {
      this.$router.replace("/prestapro/AvisoPrivacidad");
    },
    checkvalue() {
      this.checked = true;
      this.checked2 = true;
      this.check1 = false;
    },
    toggleCamera() {
      this.$root.$emit("bv::hide::modal", "modal-1");
      this.mostrarModalError = true;
      this.descripcionError = "loadspin";
      if (this.isCameraOpen) {
        this.isCameraOpen = false;
        this.isPhotoTaken = false;
        this.isShotPhoto = false;
        this.stopCameraStream();
      } else {
        this.isCameraOpen = true;
        this.createCameraElement();
      }
    },
    showmodal() {
      this.$root.$emit("bv::show::modal", "modal-1");
    },
    hideModal() {
      this.$root.$emit("bv::hide::modal", "modal-1");
    },
    createdGeolocation() {
      if (!("geolocation" in navigator)) {
        this.showModal();
        this.errorStr = "Geolocation is not available.";
        return;
      }
      this.gettingLocation = true;
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          this.gettingLocation = false;
          this.$store.commit("agregarValorPrestaPro", {
            gps: true,
            ubicacion: pos.coords,
          });
          this.$router.push("/prestapro/7pasos");
          this.location = pos;
        },
        (err) => {
          this.showModal();
          this.gettingLocation = false;
          this.errorStr = err.message;
        }
      );
    },

    createCameraElement() {
      this.isLoading = true;
      let paso = true;
      const constraints = (window.constraints = {
        audio: false,
        video: true,
      });

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          this.isLoading = false;
          this.$refs.camera.srcObject = stream;
        })
        .catch((error) => {
          this.isLoading = false;
          let erro = error.toString();
          if (erro.includes("Permission denied")) {
            paso = false;

            this.showModal();
          } else if (erro == "") {
            paso = false;
            alert("Explorador no soportado, servicio no soportado");
          }
        });
      if (paso) {
        this.$store.commit("agregarValorPrestaPro", { camara: true });
        this.createdGeolocation();
      }
    },
    stopCameraStream() {
      let tracks = this.$refs.camera.srcObject.getTracks();
      tracks.forEach((track) => {
        track.stop();
      });
    },
    takePhoto() {
      if (!this.isPhotoTaken) {
        this.isShotPhoto = true;
        const FLASH_TIMEOUT = 50;
        setTimeout(() => {
          this.isShotPhoto = false;
        }, FLASH_TIMEOUT);
      }
      this.isPhotoTaken = !this.isPhotoTaken;
      const context = this.$refs.canvas.getContext("2d");
      context.drawImage(this.$refs.camera, 0, 0, 450, 337.5);
    },

    showModal() {
      this.$root.$emit("bv::show::modal", "modal-1");
    },

    downloadImage() {
      const download = document.getElementById("downloadPhoto");
      const canvas = document
        .getElementById("photoTaken")
        .toDataURL("image/jpeg")
        .replace("image/jpeg", "image/octet-stream");
      download.setAttribute("href", canvas);
    },
  },
  beforeMount() {
    if (!Cookies.get("token")) {
      this.mostrarModalSesion = true;
    }
  },
};
</script>

<style scoped>
/deep/ .modal-dialog {
  justify-content: center;
  display: flex;
}
/deep/ .modal-mask {
  position: fixed;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(248, 248, 248, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
/deep/ .modal-backdrop {
  z-index: -1;
  background-color: #fff;
  opacity: 0.9;
  -webkit-filter: blur(8px);
  -moz-filter: blur(8px);
  -o-filter: blur(8px);
  -ms-filter: blur(8px);
  filter: blur(8px);
}
/deep/ .modal-container {
  width: auto;
  max-width: 350px;
  background-color: #ffffff8c;
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  border: none;
}
/deep/ .modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border: none;
}
/deep/ .modal-header .close {
  border: none;
  color: #0181c4;
  background: white;
  font-size: 44px;
}
/deep/ .modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  align-self: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border: none;
  margin-top: 80px;
}
.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}
.modal-body {
  margin: 20px 0 80px 0;
  padding: 0 30px;
}
.modal-default-button {
  float: right;
}

.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 0;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
/deep/ .modal-body {
  position: relative;
  font-size: 21px;
  color: #0181c4;
  margin: 0 0 62px 0;
  padding: 0 30px;
}
/deep/ .btn-primary:hover {
  color: #fff;
  background-color: #004b8d;
  border-color: #007bff;
  border-radius: 50px;
}
/deep/ .btn-primary {
  color: #fff;
  background-color: #004b8d;
  border-color: #007bff;
  border-radius: 50px;
}

.btn-primary:hover {
  color: #fff;
  background-color: #004b8d;
  border-color: #007bff;
  border-radius: 50px;
}
.btn-primary {
  color: #fff;
  background-color: #004b8d;
  border-color: #007bff;
  border-radius: 50px;
}

.icon-warning {
  margin: 3rem 0;
}
#puntos li {
  margin-bottom: 9px;
}
</style>
