<template>
  <body>
    <ModalSesion :mostrar="mostrarModalSesion" />
    <div class="container">
      <div class="row">
        <div class="col-md-5 mx-auto">
          <div id="first">
            <div class="myform form" style="margin-top: 1%">
              <div class="logo mb-3">
                <div class="col-md-12 text-center pl-0 pr-0">
                  <img class="img-responsive" src="@/assets/img/prouturo.jpg" alt="img-profuturo" />
                </div>
              </div>
              <div class="colorf">
                <label
                  >Bienvenido a PrestaPRO 73, para iniciar la captura de tu
                  préstamo presiona comenzar</label
                >
              </div>
              <form
                action=""
                method="post"
                name="login"
                @submit.prevent="BienvenidaComenzar"
              >
                <div class="col-md-12 text-center pt-5"></div>

                <div class="col-md-12 text-center pt-5">
                  <button type="submit" class="btn btn-block mybtn btn-primary">
                    Comenzar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </body>
</template>

<script>

import { mapGetters } from 'vuex'
import Cookies from "js-cookie"
import ModalSesion from '../genericos/ModalSesion.vue'
export default {
  components: {
    ModalSesion
  },
  computed: {
    ...mapGetters(['obtenerPrestaPro'])
  },
  data: () => ({
    mostrarModalSesion: false,
  }),
  methods: {
    BienvenidaComenzar() {
      this.$router.push("/prestapro/GeneraProceso");
    },
  },
  beforeMount(){
    if(!Cookies.get('token')){
      this.mostrarModalSesion = true
    }
  }
};
</script>
<style scoped>
  .btn-primary {
  color: #fff;
  background-color: #004b8d;
  border-color: #007bff;
  border-radius: 50px;
  height: 40px;
  width: 200px;
}
</style>
