import { render, staticRenderFns } from "./ResultadoOCR.vue?vue&type=template&id=201a4e46&scoped=true&"
import script from "./ResultadoOCR.vue?vue&type=script&lang=js&"
export * from "./ResultadoOCR.vue?vue&type=script&lang=js&"
import style0 from "./ResultadoOCR.vue?vue&type=style&index=0&id=201a4e46&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "201a4e46",
  null
  
)

export default component.exports