export const eliminarCaracteresExpeciales = ( texto ) => {
 return texto
  .replace('%', '')
  .replace('"', '')
  .replace("'", '')
  .replace('/', '')
  .replace('&', '')
  .replace('?', '')
  .replace('¿', '')
  .replace('\\', '')
  .replace('<', '')
  .replace('>', '')
  .replace('!', '')
  .replace('|', '')
  .replace('{', '')
  .replace('}', '')
  .replace('[', '')
  .replace(']', '')
  .replace('+', '')
  .replace(';', '')
  .replace(',', '')
}

export const validarSoloNumeros = ( texto ) => {
 const arrayNumeros = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
 let nuevoTexto = ""
 for( let i = 0; i < texto.length; i++ ){
  if( arrayNumeros.includes(texto.charAt(i))){
   nuevoTexto = nuevoTexto + texto.charAt(i)
  }
 }
 return nuevoTexto
}

export const validarSoloNumerosDecimal = ( texto ) => {
 const arrayNumeros = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.', ',']
 let nuevoTexto = ""
 for( let i = 0; i < texto.length; i++ ){
  if( arrayNumeros.includes(texto.charAt(i))){
   nuevoTexto = nuevoTexto + texto.charAt(i)
  }
 }
 return nuevoTexto
}

export const validarDecimales = ( texto ) => {
  const arrayNumeros = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.']
  let nuevoTexto = ""
  for( let i = 0; i < texto.length; i++ ){
    if( arrayNumeros.includes(texto.charAt(i))){
    nuevoTexto = nuevoTexto + texto.charAt(i)
    }
  }
  const arrayPunto = nuevoTexto.split('.');
  if(arrayPunto.length > 1){
    if(arrayPunto[1].length > 2){
      nuevoTexto = arrayPunto[0] + '.' + arrayPunto[1].substring(0,2)
    }
  }
  return nuevoTexto
}
